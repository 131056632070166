
export class UserNotFoundAWSError extends Error {
    constructor(message: string) {
      super(message);
    }
  };
  export class InvalidParameterException extends Error {
    constructor(message: string) {
      super(message);
    }
  };
  
  export class UserAwsError extends Error {
    constructor(message: string) {
      super(message);
    }
  };

  export class confirmErrorServer extends Error {
    constructor(message: string) {
      super(message);
    }
  };
  export class IncorrectUsernameOrPasswordAWSError extends Error {
    constructor(message: string) {
      super(message);
    }
  };
  
  export class UserAlreadyExistsError extends Error {
    constructor(message: string) {
      super(message);
    }
  }
  
  export class InvalidVerificationCodeError extends Error {
    constructor(message: string) {
      super(message);
    }
  };
  export class idontknow extends Error {
    constructor(message: string) {
      super(message);
    }
  };
