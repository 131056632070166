<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Bornes</h3>
            <div class="text-center">
              <div
                id=""
                *ngIf="isAlert"
                [ngClass]="{
                  'alert alert-success text-center py-3': isAlertSuccess,
                  'alert alert-danger text-center': !isAlertSuccess
                }"
                role="alert"
              >
                {{ alertMSG }}
                <button
                  (click)="closeAlert()"
                  type="button"
                  class="btn-close"
                  data-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <a
              class="button-Back d-flex justify-content-start"
              routerLink="/pages-parc"
            >
              <i id="iconBack" class="ri-arrow-drop-left-line"></i>
              <p class="btn-link button-Back">Retour</p>
            </a>
            <div class="row d-flex justify-content-between">
              <div class="col-lg-3 col-md-3">
                <!--<div class="input-group ">
                  <span
                    class="input-group-text bg-white col-lg-4"
                    id="basic-addon"
                    >Trier par</span
                  >
                  <select
                    class="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    id="basic-addon3"
                    [(ngModel)]="selectedStatus"
                    (ngModelChange)="applyStatusFilter()"
                  >
                    <option [ngValue]="null">Tout</option>
                    <option [ngValue]="ChargeStatus.Available">
                      Disponible
                    </option>
                    <option [ngValue]="ChargeStatus.Preparing">
                      Preparation
                    </option>
                    <option [ngValue]="ChargeStatus.Charging">Charge</option>
                    <option [ngValue]="ChargeStatus.Unavailable">
                      Indisponible
                    </option>
                  </select>
                </div>-->
                <!-- <button class="btn btn-primary" (click)="applyStatusFilter()">
                  Appliquer le filtre
                </button> -->
              </div>
              <div class="col-lg-6 col-md-5 ps-3" *ngIf="deleteOrEdite">
                <div class="flex-row bd-highlight mx-4">
                  <a
                    role="button"
                    type="submit"
                    id="btnValid2"
                    class="btn btn-outline-primary"
                    (click)="gotoEditBorne()"
                  >
                    <i class="ri-edit-box-fill"></i>
                  </a>
                  <a role="button"
                     type="submit"
                     id="btnValid2"
                     class="btn btn-outline-secondary"
                     (click)="gotoConfBorne()"
                  >
                    <i class="ri-list-settings-fill"></i>
                  </a>
                  <a
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteCar"
                    type="submit"
                    id="btnValid2"
                    class="btn btn-outline-danger"
                  >
                    <i class="ri-delete-bin-5-line"></i>
                  </a>
                </div>
              </div>
              <!-- popup delete abonnement -->
              <div class="modal fade" id="deleteCar" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content modelBody">
                    <div class="modal-body py-4">
                      <h4 class="text-center fw-bold text-dark py-3">
                        Voulez-vous vraiment supprimer la selection ?
                      </h4>
                      <p class="text-center card-title-two py-3">
                        Impossible d'annuler l'opération
                      </p>
                      <div class="row d-flex justify-content-center">
                        <div class="col-md-4">
                          <button
                            type="submit"
                            id="btnValid2"
                            class="btn buttonNormal text-light buttonGradient"
                            (click)="deleteBorne()"
                          >
                            supprimer la selection
                          </button>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-4 d-flex justify-content-center">
                          <a
                            type="submit"
                            id=""
                            class="btn btn-link voirePlus"
                            data-bs-dismiss="modal"
                            #closeModal
                            >Annuler</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end popup delete abonnement -->
              <div class="col-lg-3 col-md-3">
                <button
                  type="submit"
                  id="btnValid2"
                  class="buttonNormal btn text-light buttonGradient"
                  (click)="goToAddBorne()"
                >
                  <span> <i class="bi bi-battery-charging"></i></span>
                  Ajouter une nouvelle borne
                </button>
              </div>
            </div>

            <!-- start cards -->

            <div class="container mt-5">
              <div class="col-lg-3 col-md-3 ms-4">
                <button
                  type="submit"
                  id="btnValid2"
                  class="buttonNormal btn text-light buttonGradient"
                >
                  <span> <i class="bx bx-refresh"></i></span>
                  Rafraichir
                </button>
              </div>
              <ng-container
                class="cardData"
                *ngFor="let item of filteredDataBorns"
              >
                <div
                  class="row d-flex justify-content-start position-relative mt-3"
                >
                  <div
                    class="col-md-2"
                    *ngFor="let item of dataBorns"
                    (mouseover)="showBorneEditions(item.id)"
                    (mouseout)="hideBorneEditions(item.id)"
                  >
                    <div
                      class="card border border-0 text-center cardBorne py-4"
                    >
                      <span class="d-flex flex-column">
                        <span class="d-flex justify-content-center">
                          <img
                            class=" "
                            src="../../../../../assets/img/2.png"
                            id="logoInscreption"
                            alt=""
                          />
                        </span>

                        <h4 class="efusionColor">E-fusion</h4>

                        <a
                          role="button"
                          class="color-danger position-absolute top-0 end-0 deleteBorne"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteCar"
                          type="submit"
                          (click)="onCheckDeleteBorne(item.id, $event)"
                        >
                          <i class="ri-delete-bin-5-line"></i>
                        </a>
                        <a
                          role="button"
                          class="color-edit position-absolute top-0 start-0 editeBorne"
                          type="submit"
                          (click)="gotoQuickEditBorne(item.id)"
                        >
                          <i class="ri-edit-2-line editeIcone"></i>
                        </a>
                      </span>
                      <h5 class="card-title efusionColor">{{ item.name }}</h5>
                      <p class="card-text text-start ps-2 textEvId">
                        <span class="fw-bold evsId">Evse ID:</span
                        ><span class="">{{ item.evseId }}</span>
                      </p>
                      <p class="card-text text-start ps-2 textEvId">
                        <span class="fw-bold evsId">Prix:</span
                        ><span class="ps-5">{{ item.price }} €</span>
                      </p>

                      <div class="card-body d-flex justify-content-between">
                        <div class="row">
                          <div
                            class="screen d-flex align-items-center justify-content-center"
                            [ngClass]="getStatusClass(item.status)"
                          >
                            <h6 class="tableNormalTd mt-2" i>
                              {{ getStatusClass(item.status) }}
                            </h6>
                          </div>
                        </div>
                        <div class="custom-switch">
                          <label class="switch">
                            <input
                              type="checkbox"
                              (change)="toggleStatus(item)"
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <p class="card-text text-start ps-2 textEvId">
                        <span class="fw-bold evsId">Code postal:</span
                        ><span class="ps-2">{{ item.postalCode }}</span>
                      </p>
                      <p class="card-text text-start ps-2 textEvId">
                        <span class="fw-bold evsId">Pays:</span
                        ><span class="ps-2">{{ item.country }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-template #noDataMessage>
                <tr class="messageDisponibles">
                  <td colspan="6" class="text-center">
                    <p class="mt-4">Il n'y a pas des bornes disponibles.</p>
                  </td>
                </tr>
              </ng-template>
            </div>

            <!-- end cards -->

            <div class="table-responsive">
              <table class="table table-striped mt-5">
                <thead>
                  <tr>
                    <th scope="col">
                      <label class="checkbox-wrap checkbox-primary">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Type</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Evse ID</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Code Postal</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Pays</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Statut</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal"></p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngIf="dataBorns.length > 0; else noDataMessage"
                  >
                    <tr
                      *ngFor="let item of dataBorns"
                      class="list-item"
                      [ngClass]="{
                        'list-to-edit': getBorneEditions(item.id) === true
                      }" (click)="onCheckBoxChangeOnRow(item.id)"
                      (mouseover)="showBorneEditions(item.id)"
                      (mouseout)="hideBorneEditions(item.id)"
                    >
<!--                      (click)="onCheckBoxChangeOnRow(item.id)"
                      (mouseover)="showBorneEditions(item.id)"
                      (mouseout)="hideBorneEditions(item.id)"
                    >-->
                      <th scope="row">
                        <label class="checkbox-wrap checkbox-primary">
                          <input
                            type="checkbox"
                            (click)="onCheckboxChange(item.id, $event)"
                                                    [checked]="getBorneChecked(item.id)"
                                                    />
                          <!--                            (click)="onCheckboxChange(item.id, $event)"-->
<!--                            [checked]="getBorneChecked(item.id)"
                          />-->
                          <span class="checkmark"></span>
                        </label>
                      </th>
                      <td class="tableNormalTd fw-bold">{{ item.name }}</td>
                      <td class="tableNormalTd">{{ item.evseId }}</td>
                      <td class="tableNormalTd">{{ item.postalCode }}</td>
                      <td class="tableNormalTd">{{ item.country }}</td>
                      <td>
                        <span class="tableNormalTd" id="enCharge">{{
                          item.status
                        }}</span>
                      </td>
                      <td>
                        <div class="button-edit">
                          <a
                            role="button"
                            class="color-edit"
                            type="submit"
                            (click)="gotoQuickEditBorne(item.id)"
                          >
                            <i class="ri-edit-box-fill"></i>
                          </a>
                          <a role="button"
                             type="submit"
                             class="color-secondary"
                             (click)="gotoQuickConfBorne(item.id)"
                          >
                            <i class="ri-list-settings-fill"></i>
                          </a>
                          <a
                            role="button"
                            class="color-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteCar"
                            type="submit"
                            (click)="onCheckDeleteBorne(item.id, $event)"
                          >
                            <i class="ri-delete-bin-5-line"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #noDataMessage>
                    <tr class="messageDisponibles">
                      <td colspan="6" class="text-center">
                        <p class="mt-4">Il n'y a pas de données disponibles.</p>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
