import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { enterprise, contries } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-log-out-component',
  templateUrl: './log-out-component.component.html',
  styleUrls: ['./log-out-component.component.css']
})
export class LogOutComponentComponent implements OnInit {


  userPoolId : string = "eu-west-3_7RRotegEb";
  username: string = "0aac7f56-e0f0-4759-b79c-7b19bd1303ac";
  user: enterprise | undefined;

  isUserLoggedIn ?: boolean;
  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }
  enterprise: enterprise = {...this.enterpriseOriginal};

  constructor(private router:Router, private cognitoService: CognitoService, private userService: UserService, private dataSharingService : DataSharingService) {
    this.dataSharingService.isUserLoggedIn.subscribe( value => {
      this.isUserLoggedIn = value;
  });
   }


  //
  private getUserDetails( ) {
    this.dataSharingService.currentEnterpriseData.subscribe(enterpriseData => {
      if (enterpriseData && enterpriseData.username !== "") {
      this.enterprise = enterpriseData;
      } else {
        this.cognitoService.getCurrentUser().then(result =>{
          result.subscribe(user => {
            this.enterprise = user;
          })
        })
      }
    });
    console.log('get commercName')

   }



  //

   singOutWithCognito() {
    this.cognitoService.signOut().then(() => {
      this.dataSharingService.isUserLoggedIn.next(false);
      this.router.navigate(['/pages-login']);
      //location.reload()
    });
  }

  ngOnInit(): void {
   this.getUserDetails();
    console.log('Daniel')
  }

}
