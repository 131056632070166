<main id="main" class="main">
    <div class="pagetitle">
       <h1>List Group</h1>
       <nav>
          <ol class="breadcrumb">
             <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
             <li class="breadcrumb-item">Components</li>
             <li class="breadcrumb-item active">List Group</li>
          </ol>
       </nav>
    </div>
    <section class="section">
       <div class="row">
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Default List Group</h5>
                   <ul class="list-group">
                      <li class="list-group-item">An item</li>
                      <li class="list-group-item">A second item</li>
                      <li class="list-group-item">A third item</li>
                      <li class="list-group-item">A fourth item</li>
                      <li class="list-group-item">And a fifth one</li>
                   </ul>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Links and buttons</h5>
                   <div class="list-group"> <button type="button" class="list-group-item list-group-item-action active" aria-current="true"> The current button </button> <button type="button" class="list-group-item list-group-item-action">A second item</button> <button type="button" class="list-group-item list-group-item-action">A third button item</button> <button type="button" class="list-group-item list-group-item-action">A fourth button item</button> <button type="button" class="list-group-item list-group-item-action" disabled>A disabled button item</button></div>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With Icons</h5>
                   <ul class="list-group">
                      <li class="list-group-item"><i class="bi bi-star me-1 text-success"></i> An item</li>
                      <li class="list-group-item"><i class="bi bi-collection me-1 text-primary"></i> A second item</li>
                      <li class="list-group-item"><i class="bi bi-check-circle me-1 text-danger"></i> A third item</li>
                      <li class="list-group-item"><i class="bi bi-exclamation-octagon me-1 text-warning"></i> A fourth item</li>
                   </ul>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Numbered</h5>
                   <ol class="list-group list-group-numbered">
                      <li class="list-group-item">Cras justo odio</li>
                      <li class="list-group-item">Cras justo odio</li>
                      <li class="list-group-item">Cras justo odio</li>
                   </ol>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With badges</h5>
                   <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center"> A list item <span class="badge bg-primary rounded-pill">14</span></li>
                      <li class="list-group-item d-flex justify-content-between align-items-center"> A second list item <span class="badge bg-primary rounded-pill">2</span></li>
                      <li class="list-group-item d-flex justify-content-between align-items-center"> A third list item <span class="badge bg-primary rounded-pill">1</span></li>
                   </ul>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With Contextual Classes</h5>
                   <ul class="list-group">
                      <li class="list-group-item">A simple default list group item</li>
                      <li class="list-group-item list-group-item-primary">A simple primary list group item</li>
                      <li class="list-group-item list-group-item-secondary">A simple secondary list group item</li>
                      <li class="list-group-item list-group-item-success">A simple success list group item</li>
                      <li class="list-group-item list-group-item-danger">A simple danger list group item</li>
                      <li class="list-group-item list-group-item-warning">A simple warning list group item</li>
                      <li class="list-group-item list-group-item-info">A simple info list group item</li>
                      <li class="list-group-item list-group-item-light">A simple light list group item</li>
                      <li class="list-group-item list-group-item-dark">A simple dark list group item</li>
                   </ul>
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With active and disabled items</h5>
                   <ul class="list-group">
                      <li class="list-group-item active" aria-current="true">An active item</li>
                      <li class="list-group-item">A second item</li>
                      <li class="list-group-item">A third item</li>
                      <li class="list-group-item">A fourth item</li>
                      <li class="list-group-item disabled" aria-disabled="true">A disabled item</li>
                   </ul>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Clean list group</h5>
                   <p>Add <code>.list-group-flush</code> to remove some borders and rounded corners to render list group items edge-to-edge in a parent container (e.g., cards).</p>
                   <ul class="list-group list-group-flush">
                      <li class="list-group-item">An item</li>
                      <li class="list-group-item">A second item</li>
                      <li class="list-group-item">A third item</li>
                      <li class="list-group-item">A fourth item</li>
                      <li class="list-group-item disabled" aria-disabled="true">A disabled item</li>
                   </ul>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With custom content</h5>
                   <ol class="list-group list-group-numbered">
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                         <div class="ms-2 me-auto">
                            <div class="fw-bold">Subheading</div>
                            Cras justo odio
                         </div>
                         <span class="badge bg-primary rounded-pill">14</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                         <div class="ms-2 me-auto">
                            <div class="fw-bold">Subheading</div>
                            Cras justo odio
                         </div>
                         <span class="badge bg-primary rounded-pill">14</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                         <div class="ms-2 me-auto">
                            <div class="fw-bold">Subheading</div>
                            Cras justo odio
                         </div>
                         <span class="badge bg-primary rounded-pill">14</span>
                      </li>
                   </ol>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Advanced Content</h5>
                   <div class="list-group">
                      <a href="#" class="list-group-item list-group-item-action active" aria-current="true">
                         <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">List group item heading</h5>
                            <small>3 days ago</small>
                         </div>
                         <p class="mb-1">Some placeholder content in a paragraph.</p>
                         <small>And some small print.</small> 
                      </a>
                      <a href="#" class="list-group-item list-group-item-action">
                         <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">List group item heading</h5>
                            <small class="text-muted">3 days ago</small>
                         </div>
                         <p class="mb-1">Some placeholder content in a paragraph.</p>
                         <small class="text-muted">And some muted small print.</small> 
                      </a>
                      <a href="#" class="list-group-item list-group-item-action">
                         <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">List group item heading</h5>
                            <small class="text-muted">3 days ago</small>
                         </div>
                         <p class="mb-1">Some placeholder content in a paragraph.</p>
                         <small class="text-muted">And some muted small print.</small> 
                      </a>
                   </div>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With Checkboxes and radios</h5>
                   <ul class="list-group">
                      <li class="list-group-item"> <input class="form-check-input me-1" type="checkbox" value="" aria-label="..."> First checkbox</li>
                      <li class="list-group-item"> <input class="form-check-input me-1" type="checkbox" value="" aria-label="..."> Second checkbox</li>
                      <li class="list-group-item"> <input class="form-check-input me-1" type="checkbox" value="" aria-label="..."> Third checkbox</li>
                      <li class="list-group-item"> <input class="form-check-input me-1" type="checkbox" value="" aria-label="..."> Fourth checkbox</li>
                      <li class="list-group-item"> <input class="form-check-input me-1" type="checkbox" value="" aria-label="..."> Fifth checkbox</li>
                   </ul>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>