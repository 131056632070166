import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Amplify} from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, tap, filter } from 'rxjs/operators';
// import { Users } from '../models/users';
import { Charging, contries, enterprise, User } from '../models/user';
import { InvalidParameterException, UserAlreadyExistsError, IncorrectUsernameOrPasswordAWSError, UserNotFoundAWSError } from '../models/authentication-errors';

const API_URL = environment.API_URL;

const urlCreate = `${API_URL}v1/enterprises/registration/create`;
const urlEnterprise = `${API_URL}v1/enterprises`;
const urlChargePoint = `${API_URL}enterprise/v1/charging-points`;
const urlConsomation = `${API_URL}enterprise/v1/charging-cdrs`;
const httpOptions = {
  headers: new HttpHeaders({'Content-Type':  'application/json', 'Accept': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class CognitoService {



  constructor(private http: HttpClient) {
    Amplify.configure({
      Auth: environment.congito
    })
   }
  //  sinUp Cognito

  public signUp(body: any): Observable<enterprise> {
    return this.http.post<enterprise>(urlCreate, body, httpOptions).pipe(
        tap(result => console.log(result)), catchError(this.handleError))
  }

  // sinUp cognito
  public  signUpWithCognito(userMail: string, userPassword: string, autosign: boolean = false): Promise<any> {
    const autoConfirm = autosign === false ? "false" : "true";
    return Auth.signUp({
       username: userMail,
       password: userPassword,
       attributes: {
         email: userMail,
         'custom:autoConfirm': autoConfirm
       },
      //  siret: string;
      //  siren:string;
     },
     )

 }

//  confirm singUp
public confirmSignUp(enterprise: enterprise): Observable<enterprise> {
  const url = `${API_URL}v1/enterprises/${enterprise.userPoolId}/${enterprise.username}/confirm`;
  return this.http.get<enterprise>(url, httpOptions).pipe(
    tap(result => console.log(result)), catchError(this.handleError))
  }


public cognitoConfirmSignUp(userMail: string, userConfirmationCode: string): Promise<any> {
  return Auth.confirmSignUp(userMail, userConfirmationCode);
}
// get User

public getUser(): Promise<any> {
  return Auth.currentUserInfo();
}

public async getCurrentUser() : Promise<Observable<enterprise>>{
  const user = await Auth.currentAuthenticatedUser();
  const finalUser = this.signIn(user?.username, user?.pool.userPoolId)
  return finalUser;
}

// sinIn
public async cognitoSignIn(userMail: string, userPassword: string): Promise<any> {
  try {
    const signedInUserIdentifiers = await Auth.signIn(userMail, userPassword);
    // JSON.stringify(signedInUserIdentifiers);
    // console.log("hhhhhaaaaa",signedInUserIdentifiers.username)
    sessionStorage.setItem("userName", signedInUserIdentifiers.username);
    sessionStorage.setItem("userPoolId", signedInUserIdentifiers.pool.userPoolId);
    return signedInUserIdentifiers;
  } catch (err: any) {
    if (err.code === 'NotAuthorizedException ') {

      throw new IncorrectUsernameOrPasswordAWSError(`AWS: ${err}`)
    }
    throw new UserNotFoundAWSError(`AWS: ${err}`);
  }
}

public signIn(userMail: string, userPoolId: string): Observable<enterprise> {
  const urlGet = `${urlEnterprise}/${userPoolId}/${userMail}`;
  return this.http.get<enterprise>(urlGet).pipe(
    tap(result => console.log(result)), catchError(this.handleError))

}
// update enterptrise

public updateEnterprise(enterprise: enterprise) : Observable<enterprise> {
  const url = `${API_URL}v1/enterprises/update-info/${enterprise.id}`;
  return this.http.put<enterprise>(url, enterprise, httpOptions).pipe(
    tap(result => console.log(result)), catchError(this.handleError));
}

// sinOut
public signOut(): Promise<any> {
  return Auth.signOut();
}

// forget Password
public forgotPassword(userMail: string): Promise<any> {
  return Auth.forgotPassword(userMail);
}

// forget Password submit
public forgotPasswordSubmit(userMail: string, userCode: string, new_password: string): Promise<any> {
  return Auth.forgotPasswordSubmit(userMail, userCode, new_password);
}

// change password

public async changePassword( oldPassword: string, newPassword: string): Promise<any> {
  const currentUser = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(currentUser, oldPassword, newPassword);
}

private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // Erreur coté client
    console.error('An error occurred:', error.error.message);
  } else {
    // Le backend retourne une erreur.
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // Retourne un  observable avec une message d'erreur;
  return throwError(() =>
    'Une erreur est survenue. Veuillez réessayer plus tard.');
}
}
