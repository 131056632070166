import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { observable } from 'rxjs';
import { contries, enterprise, energyProvider } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';


@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css']
})
export class UsersProfileComponent implements OnInit {
  isAlertSuccess: boolean = false;
  isAlert: boolean = false;
  alertMSG: string = '';
  showPassword: boolean=  false;
  oldPassword: string  = '';
  newPassword: string = '';
  receivedInfo?:energyProvider 

  energyProviderData: energyProvider = {
    id: null,
    name: '',
    contractNumber: '',
    meterPoint: '',
    price: 0,
    enterprise: {
      id:0
    }
  };
  

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  contrys = Object.values(contries);
  showNewPassword: boolean = false;
  showNextButton: boolean = true; 


  constructor(private fb: FormBuilder,  private userService: UserService, private cognitoService: CognitoService, private dataSharingService: DataSharingService) { }


  async changePassword() {
    try {
      await this.cognitoService.changePassword(this.oldPassword, this.newPassword);
      // this.isAlertSuccess = true;
      this.alertMSGf('Password changed successfully.');
      this.showNextButton = false; 

      console.log('Password changed successfully.');
    } catch (error) {
      console.error('Password change error:', error);
      // this.isAlertSuccess = false;
      this.alertMSGf('Failed to change password. Please try again.');
            console.log('Password changed nooooooooot.');
            this.resetForm();
    }
  }
  resetForm() {
    this.oldPassword = '';
    this.newPassword = '';
    this.showNewPassword = false;
    this.showNextButton = true;
  }

  showNewPasswordInput() {
    this.showNewPassword = true;
    this.showNextButton = false;
  }
  
  private getUserDetails( ) {
    this.dataSharingService.currentEnterpriseData.subscribe(enterpriseData => {
      if (enterpriseData && enterpriseData.username !== "") {
      this.enterprise = enterpriseData;
      this.energyProviderData.enterprise.id = enterpriseData.id;
      this.getEnergyProvaiderData(enterpriseData.id)
      // this.postFournisseur(this.energyProviderData, enterpriseData.id);
      } else {
        this.cognitoService.getCurrentUser().then(result =>{
          result.subscribe(user => {
            this.enterprise = user;
            this.energyProviderData.enterprise.id = user.id;
            this.getEnergyProvaiderData(user.id)
            // this.postFournisseur(this.energyProviderData, user.id);
          })
        })
      }
    });
    console.log('get commercName')

   }
   getEnergyProvaiderData(id:number){
    this.userService.getEnergyProvaider(id)
    .subscribe((data: energyProvider) => {
      // this.oldEnergyProviderData = data;
      // this.energyProviderData = { ...this.oldEnergyProviderData };
      if(!data){
        return;
      }
      this.energyProviderData = data
      if (!this.energyProviderData.enterprise) {
        // If `enterprise` property is not present, initialize it as an object
        this.energyProviderData.enterprise = {id};
        this.energyProviderData.id = null
      }
      this.energyProviderData.enterprise.id = id
       const raw = {...this.energyProviderData}
       this.receivedInfo = raw
      console.log(data ,"benefec  ")
    })
  }

  updateEnergyProvider() {

    this.userService.updateFurnissuer(this.energyProviderData).subscribe(
      (result) => {
        console.log('Energy Provider updated successfully', result);
        this.getEnergyProvaiderData(this.energyProviderData.enterprise.id)
      },
      (error) => {
        console.error('Error updating Energy Provider', error);
      }
    );
  }
  postFournisseur(energyProviderData: energyProvider, enterpriseId: number) {
    energyProviderData.enterprise.id = enterpriseId;

    this.userService.updateFurnissuer(energyProviderData).subscribe(
      (response) => {
        console.log('Data updated successfully:', response);
      },
      (error) => {
        console.error('Error updating data:', error);
      }
    );
  }

  checkBeforeUpdate(){
    if( this.receivedInfo?.contractNumber !== this.energyProviderData.contractNumber ||
      this.receivedInfo.meterPoint !== this.energyProviderData.meterPoint || 
      this.receivedInfo.name !== this.energyProviderData.name || 
      this.receivedInfo.price !== this.energyProviderData.price){
        console.log(this.receivedInfo)
        console.log("go")
        console.log(this.energyProviderData);

       this.getEnergyProvaiderData(this.enterprise.id)
        
      this.updateEnergyProvider()
    }
  }

addOrUpdateEnergyProvider() {
  this.postFournisseur(this.energyProviderData, this.enterprise.id);
}


  updateUser(){
    console.log("update work");
    this.cognitoService.updateEnterprise(this.enterprise)
    .subscribe((data: enterprise) => {
      this.enterprise = data;
      console.log('data update =>',data)
      this.isAlertSuccess = true;
      this.alertMSGf("Enregister les modifications");
    },
    (error) => {
     console.error(error);
     this.alertMSGf('Essayez plus tard'); 
     this.isAlertSuccess = false;
   })
  }
  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
    
  }

  ngOnInit(): void {
    this.dataSharingService.currentEnterpriseData.subscribe(data => {
      this.enterprise = data;
      // this.getUserDetails(this.enterprise.userPoolId, this.enterprise.username);
      this.getUserDetails();

    })
  }

}
