<main id="main" class="main">
    <app-log-out-component></app-log-out-component>
    <section class="section profile">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body pt-3">
                        <form (ngSubmit)="updateCar()">
                            <h5 class="card-title">Éditer la voiture</h5>
                            <a class="button-Back d-flex justify-content-start" routerLink="/pages-car">
                                <span class="btn-link button-Back text-decoration-none"> <i id="iconBack" class="ri-arrow-drop-left-line"></i>Revenir aux voitures</span>
                            </a>
                            <div class="container mt-4">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <div class="input-group">
                                            <span class="input-group-text bg-white col-lg-4" id="basic-addon3">Titulaire</span>
                                            <div class="form-control col-md-8 disabled" id="basic-url">{{getFullNameProprietary()}}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="input-group">
                                            <span class="input-group-text bg-white col-lg-4" id="basic-addon3">Marque</span>
                                            <select class="form-select form-select-lg" [(ngModel)]="carToEdit.brandName" name="brandName" aria-label=".form-select-lg example" id="basic-addon3">
                      <option *ngFor="let brand of listCarBrands" [value]="brand">{{brand}}</option>
                    </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="input-group">
                                            <span class="input-group-text bg-white col-lg-4" id="basic-addon3">Modèle</span>
                                            <input type="text" [(ngModel)]="carToEdit.model" name="model" class="form-control col-md-8" id="basic-url" placeholder="Modèle S" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 mt-4">
                                        <div class="input-group">
                                            <span class="input-group-text bg-white col-lg-4" id="basic-addon3">Puissance en Kw</span
                    >
                    <input
                      type="number"
                      [(ngModel)]="carToEdit.power"
                      name="power"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="200"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Num. d'immatr.</span
                    >
                    <input
                      type="text"
                      [(ngModel)]="carToEdit.registrationNumber"
                      name="registrationNumber"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="ABC123DEF"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 mt-4">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <span class="breadcrumb-item active text-dark fw-bold">
                      Statut :
                      <span class="ms-4" name="status"id="enCharge">{{getChargeSessionStatus()}}</span>
                                            </span>
                                            </li>
                                            </ol>
                                        </div>
                                        <div class="row mt-5">
                                            <div class="col-lg-3 col-md-4">
                                                <button type="submit" id="btnValid2" class="btn buttonNormal text-light buttonGradient">
                    Enregister les modifications
                  </button>
                                            </div>
                                            <div class="col-lg-2 col-md-2 d-flex justify-content-start d-flex align-items-center">
                                                <a type="submit" routerLink="/pages-car" class="btn btn-link voirePlus">Annuler</a>
                                            </div>
                                        </div>
                                    </div>
                        </form>
                        </div>
                        </div>
                    </div>
                </div>
    </section>
</main>
