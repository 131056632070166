<main id="main" class="main">
  <app-log-out-component></app-log-out-component>

  <section class="section profile">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body pt-3">
            <h1 class="card-title">Ajouter un salarié</h1>
            <div
              *ngIf="isAlert"
              [ngClass]="{
                'alert alert-success alert-dismissible text-center py-3':
                  isAlertSuccess,
                'alert alert-danger alert-dismissible': !isAlertSuccess
              }"
              role="alert"
            >
              {{ alertMSG }}
              <button
                (click)="closeAlert()"
                type="button"
                class="btn-close"
                data-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>

            <div class="container mt-4">
              <form
                [formGroup]="addUserForm"
                (ngSubmit)="addUser()"
                #localForm="ngForm"
              >
                <h5 class="card-title mt-3">Salarié</h5>
                <div class="row">
                  <div
                    class="col-md-4"
                    [ngClass]="{ active: isCurrentStep === 1 }"
                  >
                    <div class="circle"><span class="step-number">1</span></div>
                  </div>
                  <div
                    class="col-md-4"
                    [ngClass]="{ active: isCurrentStep === 2 }"
                  >
                    <div class="circle"><span class="step-number">2</span></div>
                  </div>
                  <div
                    class="col-md-4"
                    [ngClass]="{ active: isCurrentStep === 3 }"
                  >
                    <div class="circle"><span class="step-number">3</span></div>
                  </div>
                </div>
                <div *ngIf="isCurrentStep === 1" class="step1">
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="email">email</label>
                      <input
                        type="email"
                        class="form-control p-3"
                        ngModel
                        formControlName="email"
                        name="email"
                        placeholder="example@efusion.com"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('email')?.invalid
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="lastName">Nom</label>
                      <input
                        class="form-control p-3"
                        ngModel
                        placeholder="Nom*"
                        formControlName="lastName"
                        name="lastName"
                        placeholder="Nom"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('lastName')?.invalid
                        "
                      />
                    </div>
                    <div class="col">
                      <label class="ps-2 label-input" for="firstName"
                        >Prénom</label
                      >
                      <input
                        class="form-control p-3"
                        placeholder="Prénom*"
                        name="firstName"
                        formControlName="firstName"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('firstName')?.invalid
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="password"
                        >Mot de passe</label
                      >
                      <input
                        type="password"
                        class="form-control p-3"
                        placeholder="Mot de passe*"
                        name="password"
                        formControlName="password"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('password')?.invalid
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="confirmPassword"
                        >Confirmer mot de passe</label
                      >
                      <input
                        type="password"
                        class="form-control p-3"
                        placeholder="Mot de passe*"
                        name="confirmPassword"
                        formControlName="confirmPassword"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('confirmPassword')?.invalid
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mt-2 py-2 label-input">
                        <input
                          type="checkbox"
                          [checked]="autoSignup"
                          (change)="autoSignup = !autoSignup"
                        />
                        Inscription automatique
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isCurrentStep === 2" class="step2">
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="confirmationCode"
                        >Code de confirmation</label
                      >
                      <input
                        class="form-control p-3"
                        ngModel
                        formControlName="confirmationCode"
                        name="confirmationCode"
                        placeholder="Code de confirmation*"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('confirmationCode')?.invalid
                        "
                      />
                    </div>
                  </div>
                </div>
                <div *ngIf="isCurrentStep === 3" class="step3">
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="telephone"
                        >Téléphone</label
                      >
                      <input
                        class="form-control p-3"
                        ngModel
                        formControlName="telephone"
                        name="telephone"
                        placeholder="Téléphone*"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('telephone')?.invalid
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="streetNumber"
                        >Numéro d'adresse</label
                      >
                      <input
                        type="number"
                        class="form-control p-3"
                        ngModel
                        formControlName="streetNumber"
                        name="streetNumber"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('streetNumber')?.invalid
                        "
                      />
                    </div>
                    <div class="col">
                      <label class="ps-2 label-input" for="street"
                        >Adresse</label
                      >
                      <input
                        class="form-control p-3"
                        ngModel
                        formControlName="street"
                        name="street"
                        placeholder="Adresse*"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('street')?.invalid
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="ps-2 label-input" for="postalCode"
                        >Code postal</label
                      >
                      <input
                        class="form-control p-3"
                        ngModel
                        formControlName="postalCode"
                        name="postalCode"
                        placeholder="Code postal*"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('postalCode')?.invalid
                        "
                      />
                    </div>
                    <div class="col">
                      <label class="ps-2 label-input" for="city">Ville</label>
                      <input
                        class="form-control p-3"
                        ngModel
                        formControlName="city"
                        name="city"
                        placeholder="Ville*"
                        [class.is-invalid]="
                          localForm.submitted &&
                          addUserForm.get('city')?.invalid
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 col-md-4 mt-4">
                    <button
                      type="button"
                      class="buttonNormal btn text-light buttonGradient"
                      (click)="gotoNextStep()"
                    >
                      Continuer
                    </button>
                  </div>
                </div>
              </form>
              <!-- <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Lieu</span
                    >
                    <input
                      formControlName="address"
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="231 rue Pierre et Marie"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Ville</span
                    >
                    <input
                      formControlName="city"
                      name="city"
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="Toulouse"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Code postal</span
                    >
                    <input
                      formControlName="city"
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="31670"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Carte RFID</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="62E783BA"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Type</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="EFUSION"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Prix kW/h</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="0,35 € "
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 mt-4">
                <button
                  type="submit"
                  id="btnValid2"
                  class="buttonNormal btn text-light buttonGradient"
                  routerLink="/pages-abonnement"
                >
                  Ajouter le salarié
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
