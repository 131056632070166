import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { contries, enterprise, User } from 'src/app/models/user';
import { UserAlreadyExistsError, UserNotFoundAWSError, IncorrectUsernameOrPasswordAWSError,InvalidVerificationCodeError  } from 'src/app/models/authentication-errors';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';



@Component({
  selector: 'app-pages-login',
  templateUrl: './pages-login.component.html',
  styleUrls: ['./pages-login.component.css']
})
export class PagesLoginComponent implements OnInit {
  formSingIn!: FormGroup;
  submitted: boolean = false;
  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }
  public form!: FormGroup;

  enterprise: enterprise = {...this.enterpriseOriginal};
  showPassword: boolean=  false;
  // TODO
  // FAIRE LE FORGOT PASSWORD
  
  isForgotPassword: boolean = false;
  newPassword: string = '';
  code: string = '';
  isAlert: boolean = false;
  alertMSG: string = '';
  formEmail!: string;
  formPassword!: string;

  
  constructor(private router: Router, private cognitoService: CognitoService, private dataSharingService: DataSharingService, private fb: FormBuilder, public _location: Location) { }

  ngOnInit(): void {
  this.formSingIn = this.fb.group({
    email: this.fb.control('', [Validators.required, Validators.email]),
    password: this.fb.control('', [
      Validators.required,
      Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/),
    ]),
  }, { updateOn: 'submit' })

  this.form = this.fb.group({
    code: ['', Validators.required],
    newPassword: ['', Validators.required],
  });
  }
  // sing in cognito

  singInWithCognito() {
    if (this.formEmail && this.formPassword) {
      this.cognitoService.cognitoSignIn(this.formEmail, this.formPassword).then((signedInUserIdentifiers) => {
        this.dataSharingService.isUserLoggedIn.next(true);
        this.cognitoService.signIn(signedInUserIdentifiers?.username, signedInUserIdentifiers.pool.userPoolId).subscribe({
          next: (result) => {
            this.dataSharingService.updateCurrentEnterpriseData(result);
            this.router.navigate(['/pages-validation']);
          },
          error: (e) => console.error(e)
        })

      }).catch((err) => {
        if (err instanceof IncorrectUsernameOrPasswordAWSError) {
          this.alertMSGf("Le compte spécifié n'existe pas");

        } else if (err instanceof UserNotFoundAWSError) {
    
          this.alertMSGf("Email ou mot de passe incorrect ");


          console.log("error email ou mot d passe");

        } else if (err instanceof InvalidVerificationCodeError) {

          this.alertMSGf("essayez plus tard");
          console.log("error de server");

        } else {
        }
      })

    }

}


forgotPasswordClicked() {
  console.log("before forget password")
  if (this.formEmail) {
    this.cognitoService.forgotPassword(this.formEmail)
      .then(() => {
        this.isForgotPassword = true;
        console.log("forget password")
      })
      .catch((error: any) => {
        this.alertMSGf("Essayer plus tard");
      })
  } else {


  }
}
// newPasswordSubmit() {
//   if (this.code && this.newPassword.trim().length != 0) {
//     this.cognitoService.forgotPasswordSubmit(this.formEmail, this.code, this.newPassword.trim())
//       .then(() => {
//         this.isForgotPassword = false;
//       })
//       .catch((error: any) => {
//         this.alertMSGf("Essayer plus tard");
//       })
//   } else {
//     this.alertMSGf("veuillez mettre une bonne email")
//   }
// }


newPasswordSubmit() {
  if (this.code && this.newPassword.trim().length !== 0) {
    this.cognitoService.forgotPasswordSubmit(this.formEmail, this.code, this.newPassword.trim())
      .then(() => {
        this.isForgotPassword = false;
        console.log("code write");
        
      })
      .catch((error: any) => {
        // Handle specific errors returned by the Cognito service
        if (error.code === 'InvalidParameterException') {
          this.alertMSGf("Le code de vérification est incorrect. Veuillez réessayer.");
        } else if (error.code === 'LimitExceededException') {
          this.alertMSGf("Vous avez dépassé le nombre de tentatives autorisées. Veuillez réessayer plus tard.");
        } else {
          // General error message for other cases
          this.alertMSGf("Une erreur s'est produite. Veuillez réessayer plus tard.");
        }
      });
  } else {
    this.alertMSGf("Veuillez remplir tous les champs correctement.");
  }
}

// refrer mot de passe qand recu le chifre

// message alert
private alertMSGf(msg: string) {
  this.alertMSG = msg;
  this.isAlert = true;

}

closeAlert() {
  this.alertMSG = '';
  this.isAlert = false;
}


}
