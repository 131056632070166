import { Component, OnInit } from '@angular/core';
import { Bornes, Charging, contries, enterprise, EnterpriseConsummationHistory, GrandTotale } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


@Component({
  selector: 'app-pages-consommation',
  templateUrl: './pages-consommation.component.html',
  styleUrls: ['./pages-consommation.component.css']
})
export class PagesConsommationComponent implements OnInit {
  currentPage: number = 0;
   page: number = 0;
   datatablePdf: EnterpriseConsummationHistory[] = [];
   ConsoMounthProduction: number | undefined;
   public consoMounthProduction: EnterpriseConsummationHistory[] = [];
   globaleTotalProduction: GrandTotale | any;
  chartData : any;
  totalDataForMonths: any[] = []; 
  monthsOfYear = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  
  dataForAllMonths: any[] = [];
  
  totalDataForMonth: Charging | undefined;
  chargings: Charging[] = [];
  EnterpriseConsummationHistory : EnterpriseConsummationHistory[] = [];
  enterpriseId : enterprise | any;
  powerData : Charging[] = [];
  pointChargin : Charging[] = [];
  dataDate : Charging[] = [];

    eleConso : Charging[] = [];
   chargingData: Charging[] = [];
   chargingDataAll: Charging[] = [];
   filteredData: Charging[] = [];
   filteredDataProduction : Charging[] = [];
   filterType: string = '';
   byDay: boolean = false;
   byMonth: boolean = false;
   byYear: boolean = false;
   id!: number;

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterpriseData: enterprise = {...this.enterpriseOriginal};

  minyear = 2023

  shownyear = new Date().getFullYear()

  currentyear = new Date().getFullYear()



  constructor( private cognitoService: CognitoService,private data_sh:DataSharingService, private userService: UserService,) { }

  ngOnInit(): void {
    setInterval(() => {
      // this.chartMois(this.powerData, this.pointChargin, this.dataDate);
    }, 3000);

    this.getIdEnterprise();
    this.byDay = false;
    this.byMonth = false;
    this.byYear = false;

    this.calculateTotalDataForMonth('Janvier');
    this.calculateTotalDataForMonth('Février');
    this.calculateTotalDataForMonth('Mars');
    this.calculateTotalDataForMonth('Avril');
    this.calculateTotalDataForMonth('Mai');
    this.calculateTotalDataForMonth('Juin');
    this.calculateTotalDataForMonth('Juillet');
    this.calculateTotalDataForMonth('Août');
    this.calculateTotalDataForMonth('Septembre');
    this.calculateTotalDataForMonth('Octobre');
    this.calculateTotalDataForMonth('Novembre');
    this.calculateTotalDataForMonth('Décembre');
     this.totalDataForMonth = this.userService.getTotalDataForMonth(this.chargings, 'Janvier');

  }

  calculateTotalDataForMonth(month: string) {
  
    this.totalDataForMonth = this.userService.getTotalDataForMonth(this.chargings, month);
  }


   getTestConsomation(id: number) {
    this.userService.getAllFacture(id)
      .subscribe((data: EnterpriseConsummationHistory[]) => {
        console.log(data, "scrpdf get all work");
        this.datatablePdf = data;

        this.dataForAllMonths = this.calculateDataForAllMonths(data);
        console.log(this.dataForAllMonths, "data total data for months");
        
      });
  }

  calculateMonthlyData(data: EnterpriseConsummationHistory[]): any[] {
    const monthlyData: any[] = [];
  
    const groupedData: { [key: string]: EnterpriseConsummationHistory[] } = {};
  
    for (const item of data) {
      const yearMonth = item.yearAndMonth;
      if (!groupedData[yearMonth]) {
        groupedData[yearMonth] = [];
      }
      groupedData[yearMonth].push(item);
    }
  
    for (const yearMonth of Object.keys(groupedData)) {
      const items = groupedData[yearMonth];
      const [year, month] = yearMonth.split('-'); 
  
      const totalAmount = items.reduce((total, item) => total + item.amount, 0);
      const totalPower = items.reduce((total, item) => total + item.power, 0);
  
      monthlyData.push({
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        totalAmount,
        totalPower,
        monthName: this.getMonthName(parseInt(month, 10)),
      });
    }
  
    return monthlyData;
  }

  
  calculateDataForAllMonths(data: EnterpriseConsummationHistory[]): any[] {
    const monthlyData: any[] = [];
      const monthDataMap: { [month: string]: any } = {};
  
    for (const item of data) {
      const [year, month] = item.yearAndMonth.split('_');
      const monthName = this.getMonthName(parseInt(month, 10));
  
      if (monthDataMap[monthName]) {
        monthDataMap[monthName].totalCost += item.amount;
        monthDataMap[monthName].totalEnergy += item.power;
      }
  
      monthDataMap[monthName].totalCost += item.amount;
      monthDataMap[monthName].totalEnergy += item.power;
    }
  
    for (const month of this.monthsOfYear) {
      if (!monthDataMap[month]) {
        monthlyData.push({
          monthName: month,
          totalCost: 0,
          totalEnergy: 0,
        });
      } else {
        monthlyData.push(monthDataMap[month]);
      }
    }
  
    return monthlyData;
  }

  getMonthName(month: number): string {
    const monthNames = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
  
    return monthNames[month - 1];
  }
  
  

  getConsoToChart(id : number) {
    this.userService.getAllFacture(id)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
      console.log('get all pagination =>',data)
      this.chartData = data;

      if (this.chartData!=null) {
        for(let i=0; i<this.chartData.length ; i++){
           console.log("chart" , this.chartData[i]);
          this.powerData.push(this.chartData[i].totalCost);
          this.pointChargin.push(this.chartData[i].chargingPoint);
          this.dataDate.push(this.chartData[i].stopDateTime);
        }
        console.log("", this.powerData, this.pointChargin, this.dataDate);

       }
    });
  }

  getConsomation_All_Test(id : number) {
    console.log("get all before");
      /*try {
        const { headers, data } = await this.userService.getAllConsomationTest(id, this.currentPage);
        // Now you have access to both headers and data
        console.log('Headers:', headers);
        console.log('Data:', data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors here (optional)
      }*/

    this.userService.getAllConsomationTest(id, this.currentPage)
    .subscribe((response: any)=> {

      const data = response.body;  // Access the data
     // const headers = response.headers.has('x-total-pagecount');         // Access the headers
      const headers = response.headers.keys();         // Access the headers
      console.log(headers, 'hillio')
      console.log(data)
      console.log('get all pagination =>',data)
      this.chargingData = data;
      this.filteredData = data;
         const groupedData = this.groupDataByMonth(data);
         const monthLabels = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

         const labels = this.generateLabels(groupedData, monthLabels);

         const powerData = groupedData.map(item => item.totalPower);

         this.chartMois(labels, powerData);
    });
  }


groupDataByMonth(data: Charging[]): { label: string, totalPower: number }[] {
  const groupedData = [];
  for (const item of data) {
    const monthYear = item.stopDateTimeTech.split('-').slice(0, 2).join('-');
    const existingMonthIndex = groupedData.findIndex(entry => entry.label === monthYear);

    if (existingMonthIndex !== -1) {
      groupedData[existingMonthIndex].totalPower += item.totalEnergy;
    } else {
      groupedData.push({
        label: monthYear,
        totalPower: item.totalEnergy
      });
    }
  }
    groupedData.sort((a, b) => {
      return new Date(a.label).getTime() - new Date(b.label).getTime();
    });

  return groupedData;
}

generateLabels(groupedData: { label: string, totalPower: number }[], monthLabels: string[]): string[] {
  const labels = [];
  const firstMonthIndex = monthLabels.indexOf('août');
  if (firstMonthIndex !== -1) {
    for (let i = 0; i < 12; i++) {
      const index = (firstMonthIndex + i) % 12;
      labels.push(monthLabels[index]);
    }
  }
  return labels;
}



  goToNextPage(): void {
    this.currentPage++;
     this.getConsomation_All_Test(this.enterpriseData.id);
  }

  goToPreviousPage(): void {
    if (this.currentPage >= 0) {
      this.currentPage--;
       this.getConsomation_All_Test(this.enterpriseData.id);
    }
  }

  goToNextYear(): void {
    if (this.shownyear < this.currentyear){
      this.shownyear++;
      this.getConsomation_All_ProductionChart(this.enterpriseData.id);
    }
  }

  goToPreviousYear(): void {
    if (this.shownyear > this.minyear) {
      this.shownyear--;
       this.getConsomation_All_ProductionChart(this.enterpriseData.id);
    }
  }

  goToNextPageProduction(): void {
    this.currentPage++;
     //this.getConsomation_All_Test(this.enterpriseData.id);
    this.getConsomation_All_Production(this.enterpriseData.id)
  }

  goToPreviousPageProduction(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
       //this.getConsomation_All_Test(this.enterpriseData.id);
      this.getConsomation_All_Production(this.enterpriseData.id)
    }
  }

  resetFilter() {
    this.filteredData = this.chargingData;
    this.filterType = 'Tous';
  }
  getIdEnterprise(){
    this.data_sh.currentEnterpriseData.subscribe(data => {
      if (data && data.username !== "") {
        this.enterpriseData = data
          this.getConsomation_All_Test(data.id)
          this.getConsomation_All_Production(data.id)
          this.getConsomation_All_ProductionChart(data.id)
          this.getTestConsomation(data.id)

      } else {
          this.cognitoService.getCurrentUser().then(result =>{
            result.subscribe(user => {
              this.enterpriseData = user
              this.getConsomation_All_Test(user.id)
              this.getConsomation_All_Production(user.id)
              this.getConsomation_All_ProductionChart(user.id)
              this.getTestConsomation(user.id)
            })
          })
      }
    })
  }


  

  filterByDay() {
    console.log("jour")
    const today = new Date();
    const filtered = this.chargingData.filter((item) => {
      const startDate = new Date(item.startDateTimeTech);
      return (
        startDate.getDate() === today.getDate() &&
        startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear()
      );
    });
    this.filteredData = filtered;
    this.filterType = 'ce jour';
  }

  
  filterByMonth() {
    console.log("mounth")
    const today = new Date();
    const filtered = this.chargingData.filter((item) => {
      const startDate = new Date(item.startDateTimeTech);
      return (
        startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear()
      );
    });
    this.filteredData = filtered;
    this.filterType = 'ce mois-ci';
  }


  filterByYear() {
    console.log("year")
    const today = new Date();
    const filtered = this.chargingData.filter((item) => {
      const startDate = new Date(item.startDateTimeTech);
      return startDate.getFullYear() === today.getFullYear();
    });
    this.filteredData = filtered;
    this.filterType = 'cette année';
  }

  getConsomation_All_Production(id : number) {
    console.log("get all production ");
    this.userService.getAllEvse(id, this.currentPage)
    .subscribe((data: Charging[])=> {
      console.log('get all production data =>',data)
      this.filteredDataProduction = data;

    });
  }
  getConsomation_All_ProductionChart(id : number) {
    console.log("get all before");
    this.userService.getAllFactureProduction(id)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
      console.log('get all pagination =>',data)
      let myMap: Map<number, EnterpriseConsummationHistory[]> = new Map()

      data.forEach(pam => {
        const parts = pam.yearAndMonth.split("_");
        const year : number = parseInt(parts[0])

        // If the year is not already a key in the map, add it with an empty array
        if (!myMap.has(year)) {
          myMap.set(year, []);
        }

        // Push the current element into the array associated with the year
        myMap.get(year)?.push(pam);
      })


      let yearly = myMap.get(this.shownyear)
         const monthLabels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        let powerData : number[] = [];
      yearly?.forEach(element => {
          const parts = element.yearAndMonth.split("_");
        const month = parts[1];
        const getIndex = monthLabels.indexOf(month);
        powerData[getIndex] = element.power;
        });
         this.chartMoisProduction(monthLabels, powerData);
    });
  }
  private chartMoisProduction(labels: string[], powerData: number[]) {
    // Check if a chart already exists on the canvas
    var existingChart = Chart.getChart('myChartConsoElectricite');

    // If a chart exists, destroy it
    if (existingChart) {
      existingChart.destroy();
    }
    var myChart = new Chart("myChartConsoElectricite", {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'kwh / '+ this.shownyear,
          data: powerData,
          backgroundColor: 'rgba(54, 162, 235, 0.9)',
          borderColor: 'rgba(54, 162, 235, 0.9)',
          borderWidth: 4
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
        }
      }
    });
  }
private chartMois(labels: string[], powerData: number[]) {
  var myChart = new Chart("myChartMois", {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'kWh',
        data: powerData,
        backgroundColor: 'rgba(54, 162, 235, 0.9)',
        borderColor: 'rgba(54, 162, 235, 0.9)',
        borderWidth: 4
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        },
      }
    }
  });
}




// getMoisEnCoursFactursProd(factursIdEnCoursProd:number){
//   this.userService.getFacturMoisEnCours(factursIdEnCoursProd)
//   .subscribe((data: EnterpriseConsummationHistory[])=> {
//     console.log(data, "consomation proood");
    
//   })

// }
}


