<main>
    <!-- End Backgrounds -->

    <div class="container">
        <div class="row d-flex justify-content-center" id="onetwotree">
            <div class="col-sm-8 rounded-4 mt-5 py-5">
                <form *ngIf="!isConfirm" [formGroup]="form" (ngSubmit)="singUpWithCognito()" #localForm="ngForm">
                    <div class="d-flex justify-content-center">
                        <img class="divimg border border-0 rounded p-2" src="../../../../../assets/img/2.png" id="logoInscreption" alt="" />
                    </div>
                    <div class="d-flex justify-content-center">
                        <h2>Inscription</h2>
                    </div>

                    <div *ngIf="isAlert" class="alert alert-danger alert-dismissible fade show label-input" role="alert">
                        <strong>Error</strong> {{ alertMSG }}
                        <button (click)="closeAlert()" type="button" class="btn-close" data-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <!-- Email input -->
                    <div class="container d-flex justify-content-center mt-3">
                        <div class="row">
                            <div class="col-md-6">
                                <h5 class="font-weight-bold">1. Identifant</h5>
                            </div>
                            <div class="col-md-6">
                                <h5 class="text-secondary">2.Information</h5>
                            </div>
                        </div>
                    </div>
                    <div class="container mb-3 mt-3">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-sm-6 form-outline py-2">
                                <span *ngIf="form.get('email')?.invalid && form.get('email')?.['touched']" class="text-danger label-input">* Email invalid</span
                >
                <label class="ps-2 label-input" for="email">email</label>
                <input
                  type="email"
                  class="form-control p-3"
                  ngModel
                  placeholder="email*"
                  formControlName="email"
                  id="emailss"
                  name="email"
                  [(ngModel)]="formEmail"
                  ngModel
                  placeholder="example@efusion.com"
                  [class.is-invalid]="
                    localForm.submitted && form.get('email')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-outline py-2">
                <span
                  *ngIf="
                    form.get('password')?.invalid &&
                    form.get('password')?.touched
                  "
                  class="text-danger label-input"
                  >*mot de passe invalid</span
                >
                <label class="ps-2 label-input" for="password"
                  >mot de passe:</label
                >
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  class="form-control p-3"
                  placeholder="Mot de passe*"
                  ngModel
                  [(ngModel)]="formPassword"
                  id="passwordss"
                  name="password"
                  formControlName="password"
                  [class.is-invalid]="
                    localForm.submitted && form.get('password')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-outline py-2">
                <span
                  *ngIf="
                    form.get('password')?.invalid &&
                    form.get('password')?.touched
                  "
                  class="text-danger label-input"
                  >*Password invalid</span
                >
                <label class="ps-2" for="confirmPassword"
                  >confirmer le mot de passe</label
                >
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  id="passwordss"
                  class="form-control p-3"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  ngModel
                  [(ngModel)]="formConfirmPassword"
                  placeholder="confirmer le mot de passe*"
                  [class.is-invalid]="
                    localForm.submitted && form.get('confirmPassword')?.invalid
                  "
                />
                <div class="mt-2 py-2 label-input">
                  <input
                    type="checkbox"
                    [checked]="showPassword"
                    (change)="showPassword = !showPassword"
                  />
                  afficher mot de passe
                </div>
                <div class="mt-2 py-2 label-input">
                  <input
                    type="checkbox"
                    [checked]="autoSignup"
                    (change)="autoSignup = !autoSignup"
                  />
                  Inscription automatique
                </div>
              </div>
              <div class="col-sm-6 py-4">
                <button
                  type="submit"
                  id="btnValid"
                  class="btn text-light buttonGradient"
                  (click)="singUpWithCognito()"
                >
                  <!-- routerLink="/pages-address" -->

                  Valider mes identifant
                </button>
              </div>
              <div
                class="col-md-12 d-flex justify-content-center champs-requis"
              >
                <p>* Champs requis</p>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <a
                  class="active btn-link text-end ps-2 voirePlus"
                  routerLink="/pages-login"
                  >Revenir à l'ecran de connexion</a
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="isConfirm">
      <div class="container">
        <div class="text-center">
          <h2>Entrez code</h2>
          <h6 style="color: green">verification code à {{ enterprise.email }}</h6>
        </div>
        <div class="d-flex justify-content-center">
          <div
            *ngIf="isAlert"
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            {{ alertMSG }}
            <button
              (click)="closeAlert()"
              type="button"
              class="btn-close"
              data-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="form col-sm-12 d-flex justify-content-center">
          <div class="col-sm-8 d-flex justify-content-center">
            <input
              type="text"
              class="form-control px-3 py-2 rounded-4"
              name="verificationCode"
              [(ngModel)]="formVerificationCode"
              placeholder="Entrez le code de verification"
            />
          </div>
        </div>
        <div class="mt-3 col-sm-12 d-flex justify-content-center">
          <div class="py-2 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary"
              (click)="confirmSingUp()"
            >
              CONFIRM
            </button>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</main>

<div class="container">
  <div class="row">
    <div class="col-md-0"></div>
    <div class="col-md-0"></div>
  </div>
</div>
