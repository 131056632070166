export interface User {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    email:string;
    birthDate?: Date;
    phoneNumber:string;
    code:string;
    userPoolId:string,
    currentChargeSessionSessionId: string;
    stripeCustomerId: string;
    hasPaymentMethod: string;
    subscriptions: any[];
    addresses: any[];
    vehicules: Vehicle[];
    chargeSessions: any[];
    enabled: boolean;
    clientNumber: string;
    rfididentification: RFIDIdentificationDto | null;
  }

export enum contries {
  France = 'France',
  GERMANY = 'Allemagne',
  Other = 'Autre'
}


export enum ChargeStatus {
  // Available = 'Disponible',
  // Preparing = 'Preparing',
  // Charging = 'Charging',
  // Suspendedev = 'Suspendedev',
  // Suspendedevse = 'Suspendedevse',
  // Finishing = 'Finishing',
  // Reserved = 'Reserved',
  // Unavailable = 'Unavailable',
  Available = "Available",
  Preparing = 'Preparing',
  Charging = 'Charging',
  Finishing = 'Finishing',
  Unavailable = 'Unavailable',
}
// export enum ChargeStatus{
//   Available = 'Disponible',
//   Preparing = 'Préparation',
//   Charging = 'Charge',
//    Suspendedev = 'Suspendedev',
//    Suspendedevse = 'Suspendedevse',
//   Finishing = 'Finition',
//    Reserved = 'Reserved',
//    Unavailable = 'Indisponible',
//   Faulted = 'Faulted'
// }
export interface beneficeAmount{
 income: number,
 lost: number,
 total:number,
 date: string
}

export interface SimplifiedEnergyProvider {
  name: string;
  contractNumber: string;
  metroPoint: string;
  price: number;
  enterprise: {
    id: number; 
  };
}

export interface energyProvider{
  id?: number | null
  name: string,
  contractNumber: string,
  meterPoint: string,
  price: number,
  enterprise:{
    id:number
  }
  ;
}

export interface enterprise{
    username: string,
    userPoolId: string,
    email:string,
    commercialName: string,
    siren:string,
    siret:string,
    intercomVAT: string,
    phoneNumber: string,
    city:string,
    streetNumber:number,
    street: string,
    complement: string,
    zipCode: string,
    region: string,
    country: contries,
    employees: [],
    id:number
}

export interface RFIDIdentificationDto {
  id: number;
  printedNumber: string;
  evcoID: string ;
  expiryDate: string;
  rfid: string;
  uid: string;
}

export interface MonthlyConsumption {
  [month: number]: number;
}



export interface Charging {
    uid:string;
    chargingPoint:string;
    startDateTime:string;
    stopDateTime:string;
    startDateTimeTech:string;
    stopDateTimeTech:string;
    totalCost:number;
    totalEnergy:number;
    unitPrix: number;
    
}

export interface GrandTotale{
  reduce(arg0: (total: any, item: any) => any, arg1: number): number;
  globalEnergy:number;
  globalCost: number;
}

export interface EnterpriseConsummationHistory{
  date: string;
  yearAndMonth:string;
  amount:number;
  pdfLink:string;
  power: number
}

export interface MoisEnCoursFacture{
  date: string;
  yearAndMonth:string;
  amount:number;
  pdfLink:string;
  power: number
}

// cange

export interface Bornes{
    id: number;
    evseId:string;
    name:string;
    ownerId:number;
    price: number;
    status: ChargeStatus;
    priceDetails:string;
    model:string;
    totalEnergy:number;
    address:string;
    postalCode: string;
    city: string;
    country: string;
    latitude: string;
    longitude:string;
    timeslots: TimeSlot[];
    creation_date: Date;
}


export interface TimeSlot {
  days: number[];
  startTime: Date;
  endTime: Date;
}

// export interface UserFirstNameDashboard {
//     firstName:string;
//     lastName:string;
//     userPoolId:string,
//     username:string;
// }

export interface Vehicle {
  id: number;
  userId: number;
  brandName: string;
  image: string;
  model: string;
  power: number;
  offer: Offer | null;
  registrationNumber: string;
}
export interface VehicleToCreate {
  userId: number;
  brandName: string;
  image: string;
  model: string;
  power: number;
  offer: Offer | null;
  registrationNumber: string;
}

export enum BrandName {

    TESLA = "TESLA",
    PEUGEOT = "PEUGEOT",
    RENAULT = "RENAULT",
    FIAT = "FIAT",
    KIA = "KIA",
    HYUNDAI ="HYUNDAI",
    VOLVO = "VOLVO",
    FORD = "FORD",
    AUDI = "AUDI",
    BMW = "BMW",
    HONDA = "HONDA",
    JAGUAR = "JAGUAR",
    MAZDA = "MAZDA",
    NISSAN = "NISSAN",
    OPEL = "OPEL",
    PORSCHE = "PORSCHE",
    SKODA = "SKODA",
    VW = "VW",
    DACIA = "DACIA",
    SEAT = "SEAT",
    CUPRA = "CUPRA",
    MG = "MG",
    TOYOTA = "TOYOTA",
    LEXUS = "LEXUS",
    MINI = "MINI",
    DS = "DS",
    CITROEN = "CITROEN"
}

export interface Offer{
  id: number;
	name: string;
	price: number;
	description: string;
}

// export interface OffrePrice{
//     id:string;
//     name:string;
//     prce:number;
//     description:string;
// }

// export interface UserVoitureInfo {
//      id: string;
//     brandName:BrandName;
//     image:string;
//     model:string;
//     power:number;
//     registrationNumber:string;
//     userPoolId:string,
//     username:string;
// }

// export interface UnifiedChargingSession {
//     id: string;
//     cardID : string;
//     chargingPointId : string;
//     start_datetime : string;
//     end_datetime : string;
//     userPoolId:string,
//     username:string;
//     kwh : number;
//     status : string;
//     source : string;
// }


// export interface SessionOne {
//    id: string;
//    cardID : string;
//    chargingPointId : string;
//    start_datetime : string;
//    end_datetime : string;
//    userPoolId:string,
//    username:string;
//    kwh : number;
//    status : string;
//    source : string;
// }
