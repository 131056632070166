import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { contries, enterprise, User } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';


@Component({
  selector: 'app-pages-abonnement',
  templateUrl: './pages-abonnement.component.html',
  styleUrls: ['./pages-abonnement.component.css']
})
export class PagesAbonnementComponent implements OnInit {
  deleteOrEdite: boolean = false;
  counter = 0;
  // usersAll: UserContactInfo[] = [];

  dataUsers : User[] = [];
  usersCard : User | any;
  user : enterprise | any;

  // users : User | any;
  id :number | any



  usersEnterpriseUsername! : string

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  userOrginal : User = {
    "id": 0,
    "firstName": "",
    "lastName": "",
    "username": "",
    "email": "",
    "birthDate": undefined,
    "phoneNumber": "",
    "code": "",
    "userPoolId": "",
    "currentChargeSessionSessionId": "",
    "stripeCustomerId": "",
    "hasPaymentMethod": '',
    "subscriptions": [],
    "addresses": [],
    "vehicules": [],
    "chargeSessions": [],
    "enabled": false,
    "clientNumber": '',
    "rfididentification": null
  }

  userEnterprise: User = {...this.userOrginal};

  listUsersEditions: { idUser: any; isEdition: boolean; isChecked: boolean }[];
  bornToDeleteQuickId!: number;
  constructor(private userService: UserService,private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router, element: ElementRef) {
    this.listUsersEditions = []
   }

  getAllUsers(id:number){
    this.userService.getAllUsersFromEnterprise(id)
    .subscribe((data: User[]) => {
      this.dataUsers = data;
      console.log(data ,"all data user ")
    })
  }


  ngOnInit(): void {
    this.dataSharingService.currentEnterpriseData.subscribe(data=> {
      if(data && data.username !== "") {
        this.enterprise = data;
        this.getAllUsers(data.id);
      } else {
        this.congitoService.getCurrentUser().then(result => {
          result.subscribe( user => {
            this.enterprise = user;
            this.getAllUsers(user.id);
          })
        });
      }
    })

    this.dataSharingService.currentUserEnterprise.subscribe(dataUser=>{
      if (dataUser && dataUser.username !== "")
      {
        this.userEnterprise = dataUser;
        this.getAllUsers(dataUser.id);
        console.log(dataUser , "dataUser work");
        
      }
    })
  }

  goToAddUser() {
    this.dataSharingService.updateCurrentEnterpriseData(this.enterprise);
    this.router.navigate(['/pages-add-abonnement']);
  }
    deleteAndEdite() {
    this.deleteOrEdite = true
    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
    }
  }

  
  deleteUser(idUser: number) {
    this.userService.deleteUserFromEnterprise(this.enterprise.userPoolId, this.enterprise.username, this.userEnterprise.username)
      .subscribe(
        () => {
          this.getAllUsers(this.enterprise.id);
        },
        (error) => {
          console.error('Error deleting user:', error);
        }
      );
  }
  
  
// this.router.navigate(['/pages-edit-abonnement']);
}
