import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-bourne',
  templateUrl: './page-bourne.component.html',
  styleUrls: ['./page-bourne.component.css'],
})
export class PageBourneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    //other the other page this.router.navigate(['/target-page'], { state: { id:id }})
    const navigation = window.history.state;
    const id = navigation.id;
    console.log(id); // Logs the passed ID
    // /*in case p don't work don't forget to use router in constructor  activate the logic too in the constructor*/
    /*const navigatioN = this.router.getCurrentNavigation();
    if (navigatioN?.extras.state) {
      const id = navigatioN.extras.state.id;
      // Use the id as needed
    }*/
  }
}
