<main id="main" class="main">
    <div class="pagetitle">
       <h1>ApexCharts</h1>
       <nav>
          <ol class="breadcrumb">
             <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
             <li class="breadcrumb-item">Charts</li>
             <li class="breadcrumb-item active">ApexCharts</li>
          </ol>
       </nav>
    </div>
    <p>ApexCharts Examples. You can check the <a href="https://apexcharts.com/javascript-chart-demos/" target="_blank">official website</a> for more examples.</p>
    <section class="section">
       <div class="row">
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Line Chart</h5>
                   <div id="AlineChart"></div>
                   
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Area Chart</h5>
                   <div id="AareaChart"></div> 
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>