import { Component, OnInit } from '@angular/core';
import { Bornes, ChargeStatus, User, Vehicle } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-pages-parc',
  templateUrl: './pages-parc.component.html',
  styleUrls: ['./pages-parc.component.css']
})
export class PagesParcComponent implements OnInit {

  totalUsers: number | undefined;
  bornsList : Bornes[] = [];
  carsList: Vehicle[] = [];

  constructor(private cognitoService: CognitoService,private data_sh:DataSharingService, private userService: UserService,) { }


  getToutalBornes(id: number){
    this.userService.getEnterpriseChargingPoints(id)
    .subscribe((data: Bornes[]) => {
      this.bornsList = data;
    })
  }
  getIdEnterprise(){
    this.data_sh.currentEnterpriseData.subscribe(data => {
      if (data && data.username !== "") {
          this.getToutalBornes(data.id);
          this.getAllCars(data.id);
      } else {
          this.cognitoService.getCurrentUser().then(result =>{
            result.subscribe(user => {
              this.getToutalBornes(user.id)
              this.getAllCars(user.id);
            })
          })
      }
    })
  }

  getBornesAvailable() {
    return this.bornsList.filter(born => born.status === ChargeStatus.Available).length;
  }

  getAllCars(enterpriseId: number){
    this.userService.getAllUsersFromEnterprise(enterpriseId).subscribe((users: User[]) => {
      users.forEach(user => {
        user.vehicules.forEach((car: Vehicle) => {
          this.carsList.push(car);
        })
      })
    })
  }
  getCarsInCharge() {

  }
  ngOnInit(): void {
    this.getIdEnterprise();
  }

}
