<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <div class="card">
    <div class="card-body">
      <div class="pagetitle py-4">
        <h1 class="card-title">Mes utilisateurs</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"></li>
          </ol>
        </nav>
      </div>
      <div class="row d-flex justify-content-between">
        <form class="col-lg-3 col-md-4">
          <input
            id="searchAboon"
            class="form-control"
            type="search"
            placeholder="Recherche"
            aria-label="Search"
          />
        </form>
        <div class="col-lg-6 col-md-5 ps-3" *ngIf="deleteOrEdite">
          <div class="flex-row bd-highlight d-flex justify-content-around">
            <a
              role="button"
              type="submit"
              id="btnValid2"
              class="btn btn-outline-primary"
              routerLink="/pages-edit-abonnement"
            >
              <i class="ri-edit-box-fill"></i>
            </a>
            <a
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#deleteAbonnement"
              type="submit"
              id="btnValid2"
              class="btn btn-outline-danger"
            >
              <i class="ri-delete-bin-5-line"></i>
            </a>
          </div>
        </div>
        <!-- popup delete abonnement -->
        <div class="modal fade" id="deleteAbonnement" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content modelBody">
              <div class="modal-body py-4">
                <h4 class="text-center fw-bold text-dark py-3">
                  Voulez-vous vraiment supprimer la sélection?
                </h4>
                <p class="text-center card-title-two py-3">
                  Impossible d'annuler l'opération
                </p>
                <div class="row d-flex justify-content-center">
                  <div class="col-md-4">
                    <button
                      type="submit"
                      id="btnValid2"
                      class="btn buttonNormal text-light buttonGradient"
                    >
                      supprimer la sélection
                    </button>
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                  <div class="col-4 d-flex justify-content-center">
                    <a
                      type="submit"
                      id=""
                      class="btn btn-link voirePlus"
                      data-bs-dismiss="modal"
                      >Annuler</a
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> <button type="button" class="btn btn-primary">Save changes</button></div> -->
            </div>
          </div>
        </div>
        <!-- end popup delete abonnement -->
        <div class="col-lg-4 col-md-3">
          <button
            type="submit"
            id="btnValid22"
            class="buttonNormal btn text-light buttonGradient"
            (click)="goToAddUser()"
          >
            <span> <i class="bi bi-file-person-fill"></i></span>
            Ajouter un nouvel utilisateur
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped mt-5">
          <thead>
            <tr>
              <th scope="col">
                <label class="checkbox-wrap checkbox-primary">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th scope="col">
                <p class="tableNormal">
                  <i class="bx bx-id-card ps-4"></i> Identifiant
                </p>
              </th>
              <th scope="col">
                <p class="tableNormal">
                  <i class="bi bi-person-badge ps-4"></i> Titulaire
                </p>
              </th>
              <th scope="col">
                <p class="tableNormal">
                  <i class="bx bx-mobile-vibration ps-4"></i> Téléphone
                </p>
              </th>
              <th scope="col">
                <p class="tableNormal">
                  <i class="bi bi-card-checklist ps-4"></i> Carte RFID
                </p>
              </th>
              <th scope="col">
                <p class="tableNormal">
                  <i class="bi bi-card-checklist ps-4"></i> Action
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let items of dataUsers">
              <th scope="row "></th>
              <td class="tableNormalTdConso text-center">
                {{ items.clientNumber }}
              </td>
              <td class="tableNormalTd text-center">
                {{ items.firstName }} {{ items.lastName }}
              </td>
              <td class="tableNormalTd text-center">{{ items.phoneNumber }}</td>
              <td class="tableNormalTd text-center">
                {{ items.rfididentification?.uid }}
              </td>
              <!-- <td class="tableNormalTd text-center">
                <button (click)="deleteUser(userEnterprise.id)">
                  Delete User
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
