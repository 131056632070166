<main id="main" class="main">
    <section id="mainConsommation">
        <div class="container bg-white p-4" id="backgroundConsommation">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-12 " >
                    <div class="pagetitle">
                        <h1>Abonnements</h1>
                        <nav>
                           <ol class="breadcrumb">
                              <li class="breadcrumb-item"> <p>This is an examle page with no contrnt. You can use it as a starter for your custom pages.</p>
                              </li>
                           </ol>
                        </nav>
                     </div>
                     <section class="section mt-5">
                     </section>
                </div>
            </div>
        </div>
    </section>

 </main>