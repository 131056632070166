import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ElementRef } from '@angular/core';
import { data } from 'jquery';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';
import { map } from 'rxjs/operators';
import { Charging, EnterpriseConsummationHistory } from 'src/app/models/user';

@Component({
  selector: 'app-pages-facture',
  templateUrl: './pages-facture.component.html',
  styleUrls: ['./pages-facture.component.css']
})
export class PagesFactureComponent implements OnInit {

  totalPrice: number = 0;
  totalPriceProd: number = 0
  datatablePdf: EnterpriseConsummationHistory[] = [];
  datatablePdfProd: EnterpriseConsummationHistory[] = [];
  pdfAll: any | undefined
  moisEnCoursFacture :number | undefined;

  constructor(private elementRef: ElementRef,private cognitoService: CognitoService,private data_sh:DataSharingService, private userService: UserService,) { }

  ngOnInit(): void {

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/main.js";
    this.elementRef.nativeElement.appendChild(s);

    this.getIdEnterprise();
  }


    // get info in inputs
    getFactureAll(id: number, dateyear: number, dateMonth: number) {
      this.userService.getAllMoisFacture(id, dateyear, dateMonth)
      .subscribe((pdfBlob: Blob)=> {
        const fileURL = URL.createObjectURL(pdfBlob);
        window.open(fileURL, '_blank');
       console.log("pdf");
       
      });
    }

    getMonthlyPdf(id: number, pdfLink:string) {
      this.userService.getMonthlyFacture(id, pdfLink)
      .subscribe((pdfBlob: Blob)=> {
        const fileURL = URL.createObjectURL(pdfBlob);
        window.open(fileURL, '_blank');
       
      });
    }

    getFacturForMonth(idOwner: number, year: number, month: number) {
      console.log("get factur for month before");
      this.userService.getConsomatioFacture(idOwner, year, month)
        .subscribe((data: EnterpriseConsummationHistory[]) => {
          console.log('get factur for month ', data);
          this.datatablePdfProd = data
          this.totalPrice = data.reduce((total, item) => total + item.amount, 0);
        });
        console.log("", this.datatablePdfProd);
    }

    getMoisEnCoursFacturs(factursIdEnCours:number){
      this.userService.getFacturMoisEnCours(factursIdEnCours)
      .subscribe((data: EnterpriseConsummationHistory[])=> {
          if (data && data.length > 0 && data[0].amount !== null && data[0].amount !== undefined && data[0].amount !== 0) {
          this.moisEnCoursFacture = data[0].amount;
        } else {
          this.moisEnCoursFacture = 0;
        }
        console.log("", this.moisEnCoursFacture);
      })
    
    }

    getTestPdf(id: number) {
      this.userService.getAllFacture(id)
      .subscribe((data: EnterpriseConsummationHistory[])=> {
        console.log(data, "scrpdf get all work");
        this.datatablePdf = data;

        this.totalPrice = data.reduce((total, item) => total + item.amount, 0);

      });
    }


    


  getIdEnterprise(){
    this.data_sh.currentEnterpriseData.subscribe(data => {
      const currentDate = new Date(); 
      const year = currentDate.getFullYear(); 
      const month = currentDate.getMonth() + 1;
      this.pdfAll = data.id;
      if (data && data.username !== "") {
          this.getTestPdf(data.id)
          this.getMoisEnCoursFacturs(data.id)
          this.getFacturForMonth(data.id, year, month)
          
      } else {
          this.cognitoService.getCurrentUser().then(result =>{
            result.subscribe(user => {
              this.getTestPdf(user.id)
              this.getMoisEnCoursFacturs(user.id)
              this.getFacturForMonth(user.id, year, month)

            })
          })
      }
    })
  }
}
