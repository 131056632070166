<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Configurations des bornes</h3>
            <a
              class="button-Back d-flex justify-content-start"
              routerLink="/pages-allBornes"

            >
              <i id="iconBack" class="ri-arrow-drop-left-line"></i>
              <p class="btn-link button-Back">listes des bornes</p>
            </a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
           {{this.conf}}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
