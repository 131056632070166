<div class="pagetitle">
  <nav class="d-flex justify-content-between">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span>identifiant client</span>
      </li>
      <li class="breadcrumb-item active">
        <span class="commercialName">{{ enterprise?.commercialName }}</span>
      </li>
    </ol>
    <!-- logout -->
    <ol class="breadcrumb pe-4">
      <li class="breadcrumb-item active">
        <a (click)="singOutWithCognito()" class="btn btn-link" role="button">
          Déconnexion</a
        >
      </li>
    </ol>
    <!-- end lgout -->
  </nav>
</div>
