<main>
  <!-- End Backgrounds -->
  <div class="container">
    <div class="row d-flex justify-content-center" id="onetwotree">
      <div class="col-sm-8 rounded-4 mt-5 py-5">
        <form
          [formGroup]="formSingIn"
          (ngSubmit)="singInWithCognito()"
          #localForm="ngForm"
          *ngIf="!isForgotPassword"
        >
          <div class="d-flex justify-content-center">
            <img
              class="divimg border border-0 rounded p-2"
              src="../../../../../assets/img/2.png"
              id="logoInscreption"
              alt=""
            />
          </div>
          <div class="d-flex justify-content-center">
            <h2>Connexion</h2>
          </div>
          <div class="d-flex justify-content-center row">
            <div
              *ngIf="isAlert"
              class="alert alert-danger text-light text-center alert-dismissible fade show alertMessage col-md-8"
              role="alert"
            >
              <span>
                <div class="icon">
                  <i class="bx bxs-error"></i></div
              ></span>
              <strong class="fs-6">Error</strong> {{ alertMSG }}
              <button
                (click)="closeAlert()"
                type="button"
                class="btn-close buttonClose"
                data-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <!-- Email input -->
          <div class="container mb-3 mt-3">
            <div class="row d-flex flex-column align-items-center">
              <div class="col-sm-6 form-outline py-2">
                <span
                  *ngIf="formSingIn.get('email')?.invalid && formSingIn.get('email')?.['touched']"
                  class="text-danger px-2"
                  >Saisissez une adresse email valide</span
                >
                <input
                  type="email"
                  class="form-control input-placeholder p-3"
                  placeholder="email*"
                  formControlName="email"
                  id="email"
                  name="email"
                  ngModel
                  [(ngModel)]="formEmail"
                  [class.is-invalid]="
                    localForm.submitted && formSingIn.get('email')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-outline py-2">
                <span
                  *ngIf="
                    formSingIn.get('password')?.invalid &&
                    formSingIn.get('password')?.touched
                  "
                  class="text-danger px-2"
                  >Saisissez un mot de passe valide</span
                >

                <input
                  class="form-control p-3"
                  placeholder="Mot de passe*"
                  [type]="showPassword ? 'text' : 'password'"
                  id="password"
                  formControlName="password"
                  name="password"
                  ngModel
                  [(ngModel)]="formPassword"
                  [class.is-invalid]="
                    localForm.submitted && formSingIn.get('password')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-check py-2">
                <input
                  class="form-check-input ms-1"
                  type="checkbox"
                  [checked]="showPassword"
                  (change)="showPassword = !showPassword"
                />
                afficher mot de passe
              </div>
              <div class="col-sm-6 mt-3 form-check form-switch" id="formCheck">
                <div class="row">
                  <div class="col-sm-1">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                  </div>
                  <div class="col-sm-11">
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckChecked"
                    >
                      <p class="text-start ps-2 text-dark-Mois">
                        Enregistrer mes identifiants pour une prochaine
                        connexion
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 py-4">
                <!-- routerLink="/pages-validation" -->
                <button
                  type="submit"
                  id="btnValid"
                  class="btn text-light buttonGradient"
                  (click)="singInWithCognito()"
                >
                  Se connecter
                </button>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <p class="champs-requis">* Champs requis</p>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <a
                  (click)="forgotPasswordClicked()"
                  class="active btn-link voirePlus text-end ps-2"
                  role="button"
                  >Mot de passe oublié ?</a
                >
              </div>
            </div>
          </div>
        </form>
        <form *ngIf="isForgotPassword" #localForm="ngForm">
          <div class="d-flex justify-content-center mb-3">
            <img
              class="divimg border border-0 rounded p-2"
              src="../../../../../assets/img/2.png"
              alt=""
            />
          </div>
          <div class="d-flex justify-content-center">
            <div
              *ngIf="isAlert"
              class="alert alert-danger text-light alert-dismissible fade show alertMessage"
              role="alert"
            >
              <span>
                <div class="icon">
                  <i class="bx bxs-error"></i>
                  <div class="label">bxs-error</div>
                </div></span
              >
              <strong class="fs-6">Error</strong> {{ alertMSG }}
              <button
                (click)="closeAlert()"
                type="button"
                class="btn-close"
                data-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="py-4">
            <h2 class="d-flex justify-content-center">Account recovery</h2>
            <h4 class="d-flex justify-content-center">
              Verification code envoyé à {{ formEmail }}
            </h4>
          </div>
          <div class="container mb-3">
            <div class="row justify-content-center">
              <div class="col-sm-8 py-2">
                <input
                  id="code"
                  formControlName="code"
                  type="text"
                  placeholder="code de validation"
                  name="code"
                  class="form-control px-3 py-2 rounded-4"
                />
              </div>
              <div class="col-sm-8 e py-2">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  class="form-control px-3 py-2 rounded-4"
                  placeholder="Entrez nevue password"
                  id="newPassword"
                  formControlName="newPassword"
                  name="newPassword"
                />
              </div>
              <div class="col-sm-9 form-check py-2">
                <input
                  class="form-check-input ms-3"
                  type="checkbox"
                  [checked]="showPassword"
                  (change)="showPassword = !showPassword"
                />
                afficher mot de passe
              </div>
              <div class="col-md-12 py-4 px-4 d-flex justify-content-center">
                <!-- routerLink="/dashboard" -->
                <button
                  (click)="newPasswordSubmit()"
                  type="button"
                  class="btn btn-primary"
                >
                  Verification nevue mot de passe
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- </div> -->

  <!-- Footer -->
  <footer class="bg-dark text-center text-white">
    <!-- Grid container -->
    <div class="container p-4 mt-5">
      <!-- Section: Form -->
      <section class="mt-3 py-4">
        <div class="row d-flex justify-content-center">
          <h3>Première connexion ?</h3>
        </div>
      </section>
      <section>
        <div class="row d-flex justify-content-center"></div>
      </section>
      <section>
        <div class="col-sm-12 py-4">
          <button
            id="btnValid"
            routerLink="/pages-register"
            type="submit"
            class="btn text-light buttonGradient"
          >
            Créer un compte
          </button>
        </div>
      </section>
    </div>
    <!-- Grid container -->
  </footer>
  <!-- Footer -->
</main>
