import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forms-elements',
  templateUrl: './forms-elements.component.html',
  styleUrls: ['./forms-elements.component.css']
})
export class FormsElementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
