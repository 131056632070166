import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icons-remix',
  templateUrl: './icons-remix.component.html',
  styleUrls: ['./icons-remix.component.css']
})
export class IconsRemixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
