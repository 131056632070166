import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { contries, enterprise } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';

@Component({
  selector: 'app-pages-address',
  templateUrl: './pages-address.component.html',
  styleUrls: ['./pages-address.component.css']
})
export class PagesAddressComponent implements OnInit {

  isAlert: boolean = false;
  isAlertSuccess: boolean = false;
  alertMSG: string = '';


  formAdress!: FormGroup;
  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  constructor( private router: Router, private congitoService: CognitoService, private fb: FormBuilder, private dataSharingService: DataSharingService) { }

  ngOnInit(): void {
    this.formAdress = this.fb.group({
      commercialName: this.fb.control('', [Validators.required]),
      siren: this.fb.control('', [Validators.required]),
      siret: this.fb.control('', [Validators.required]),
      address: this.fb.control('', [Validators.required]),
      codePostal: this.fb.control('', [Validators.required]),
      ville: this.fb.control('', [Validators.required]),

    }, { updateOn: 'submit' })
    this.dataSharingService.currentEnterpriseData.subscribe(enterpriseData => this.enterprise = enterpriseData);
  }

  public updateInscription() {
    this.enterprise.commercialName = this.formAdress.get('commercialName')?.value;
    this.enterprise.siren = this.formAdress.get('siren')?.value;
    this.enterprise.siret = this.formAdress.get('siret')?.value;
    this.enterprise.street = this.formAdress.get('address')?.value;
    this.enterprise.zipCode = this.formAdress.get('codePostal')?.value;
    this.enterprise.city = this.formAdress.get('ville')?.value;
    this.congitoService.updateEnterprise(this.enterprise).subscribe({
      next: (result) => {
        this.enterprise = result;
       
        this.alertMSGf('Informations ajouté avec succès.'); 
        this.isAlertSuccess = true;
        setTimeout(() => {
          this.router.navigate(['/pages-validation']);
        }, 3000); 
      },
      error: (e) => {
        console.error(e);
        const errorMessage = "Veuillez verifiez votre siret et ou siren";
      this.alertMSGf(errorMessage);
      this.isAlertSuccess = false;
      }
    })
  }



  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }
}
