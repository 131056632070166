<main>
  <div class="container">
    <section
      class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center"
    >
      <h1>404</h1>
      <h2>La page que vous recherchez n'existe pas.</h2>
      <a
        id="btnValid2"
        class="btn btn text-light buttonGradient"
        routerLink="/dashboard"
        >De retour à la tableau de bord</a
      >
      <!-- <img
        src="assets/img/not-found.jpg"
        class="img-fluid py-5"
        alt="Page Not Found"
      /> -->
    </section>
  </div>
</main>
