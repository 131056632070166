import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ParamMap, Router } from '@angular/router';
import { Bornes, ChargeStatus, contries, enterprise } from 'src/app/models/user';
import { UserService } from 'src/app/services/users/user.service';
import { ActivatedRoute } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';


@Component({
  selector: 'app-page-edite-born',
  templateUrl: './page-edite-born.component.html',
  styleUrls: ['./page-edite-born.component.css']
})



export class PageEditeBornComponent implements OnInit {

  isAlert: boolean = false;
  isAlertSuccess: boolean = false;
  alertMSG: string = '';

  dataBorns : any;
  bornes: Bornes[] | undefined;

  idBorne :number | any;
  bornToEdit : Bornes = {
    "id": 0,
    "evseId": '',
    "name": '',
    "ownerId": 0,
    "priceDetails": '',
    "price": 0,
    "status": ChargeStatus.Preparing,
    "model": '',
    "address": '',
    "postalCode": '',
    "city": '',
    "country": '',
    "totalEnergy": 0,
    "latitude": '',
    "longitude": '',
    "timeslots": [],
    "creation_date": new Date(),
  }

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  constructor(private fb: FormBuilder,private router: Router,  private userService: UserService,  private route: ActivatedRoute, private congitoService: CognitoService, private dataSharingService: DataSharingService
    ) { }


    // get info in inputs
  getUserDetails(enterpriseId: number) {
    this.userService.getInfoBornForEdite(enterpriseId).subscribe((data: Bornes[]) => {
      this.bornToEdit = data.filter(borne => borne.id === this.idBorne)[0];
    })
  }

  updateBorn(){
  this.userService.updateEnterpriseChargingPoint(this.enterprise.id, this.idBorne, this.bornToEdit ).subscribe((data:Bornes)=> {
    console.log("update", data);
      this.bornToEdit = data;
      this.alertMSGf('Modification prise en compte'); 
      this.isAlertSuccess = true;
      setTimeout(() => {
        this.router.navigate(['/pages-allBornes']);
      }, 5000); 
  },
  (error) => {
    console.log('error =>', error);
    const errorMessage = "Une erreur, Veuillez réessayer.";
    this.alertMSGf(errorMessage);
    this.isAlertSuccess = false;
  })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idBorne = Number(params.get('id'));
      if(this.idBorne != null) {
        this.dataSharingService.currentEnterpriseData.subscribe(data=> {
          if(data && data.username !== "") {
            this.enterprise = data;
            this.getUserDetails(this.enterprise.id);
          } else {
            this.congitoService.getCurrentUser().then(result => {
              result.subscribe( enterprise => {
                this.enterprise = enterprise;
                this.getUserDetails(this.enterprise.id);
              })
            });
          }
        })
      }
    })
  }


  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }

}
