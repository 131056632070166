import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BadgesComponent } from './components/badges/badges.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { CardsComponent } from './components/cards/cards.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ListGroupComponent } from './components/list-group/list-group.component';
import { ModalComponent } from './components/modal/modal.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ProgressComponent } from './components/progress/progress.component';
import { SpinnersComponent } from './components/spinners/spinners.component';
import { TooltipsComponent } from './components/tooltips/tooltips.component';
import { FormsElementsComponent } from './components/forms-elements/forms-elements.component';
import { FormsLayoutsComponent } from './components/forms-layouts/forms-layouts.component';
import { FormsEditorsComponent } from './components/forms-editors/forms-editors.component';
import { TablesGeneralComponent } from './components/tables-general/tables-general.component';
import { TablesDataComponent } from './components/tables-data/tables-data.component';
import { ChartsChartjsComponent } from './components/charts-chartjs/charts-chartjs.component';
import { ChartsApexchartsComponent } from './components/charts-apexcharts/charts-apexcharts.component';
import { IconsBootstrapComponent } from './components/icons-bootstrap/icons-bootstrap.component';
import { IconsRemixComponent } from './components/icons-remix/icons-remix.component';
import { IconsBoxiconsComponent } from './components/icons-boxicons/icons-boxicons.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { PagesFaqComponent } from './pages/pages-faq/pages-faq.component';
import { PagesContactComponent } from './pages/pages-contact/pages-contact.component';
import { PagesRegisterComponent } from './pages/pages-register/pages-register.component';
import { PagesLoginComponent } from './pages/pages-login/pages-login.component';
import { PagesError404Component } from './pages/pages-error404/pages-error404.component';
import { PagesBlankComponent } from './pages/pages-blank/pages-blank.component';
import { PagesConsommationComponent } from './pages/pages-consommation/pages-consommation.component';
import { PagesAbonnementComponent } from './pages/pages-abonnement/pages-abonnement.component';
import { PagesAddressComponent } from './pages/pages-address/pages-address.component';
import { PagesValidationComponent } from './pages/pages-validation/pages-validation.component';
import { PagesParcComponent } from './pages/pages-parc/pages-parc.component';
import { PagesCarComponent } from './pages/pages-car/pages-car.component';
import { PagesSubscriptionComponent } from './pages/pages-subscription/pages-subscription.component';
import { PagesFactureComponent } from './pages/pages-facture/pages-facture.component';
import { PagesAllBornsComponent } from './pages/pages-all-borns/pages-all-borns.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { DataSharingService } from './services/DataSharingService.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { PagesAddBornsComponent } from './pages/pages-add-borns/pages-add-borns.component';
import { LogOutComponentComponent } from './components/log-out-component/log-out-component.component';
import { PageEditeAbonnementComponent } from './pages/page-edite-abonnement/page-edite-abonnement.component';
import { PageEditeCarsComponent } from './pages/page-edite-cars/page-edite-cars.component';
import { PageEditeBornComponent } from './pages/page-edite-born/page-edite-born.component';
import { PageAddAbonnementsComponent } from './pages/page-add-abonnements/page-add-abonnements.component';
import { PageAddCarComponent } from './pages/page-add-car/page-add-car.component';
import { FormPaymentComponent } from './components/form-payment/form-payment.component';
import { PageBourneComponent } from './pages/page-bourne/page-bourne.component';
import { PageConfBorneComponent } from './pages/page-conf-borne/page-conf-borne.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    AlertsComponent,
    AccordionComponent,
    BadgesComponent,
    BreadcrumbsComponent,
    ButtonsComponent,
    CardsComponent,
    CarouselComponent,
    ListGroupComponent,
    ModalComponent,
    TabsComponent,
    PaginationComponent,
    ProgressComponent,
    SpinnersComponent,
    TooltipsComponent,
    FormsElementsComponent,
    FormsLayoutsComponent,
    FormsEditorsComponent,
    TablesGeneralComponent,
    TablesDataComponent,
    ChartsChartjsComponent,
    ChartsApexchartsComponent,
    IconsBootstrapComponent,
    IconsRemixComponent,
    IconsBoxiconsComponent,
    UsersProfileComponent,
    PagesFaqComponent,
    PagesContactComponent,
    PagesRegisterComponent,
    PagesLoginComponent,
    PagesError404Component,
    PagesBlankComponent,
    PagesConsommationComponent,
    PagesAbonnementComponent,
    PagesAddressComponent,
    PagesValidationComponent,
    PagesParcComponent,
    PagesCarComponent,
    PagesSubscriptionComponent,
    PagesFactureComponent,
    PagesAllBornsComponent,
    MessageModalComponent,
    PagesAddBornsComponent,
    LogOutComponentComponent,
    PageEditeAbonnementComponent,
    PageEditeCarsComponent,
    PageEditeBornComponent,
    PageAddAbonnementsComponent,
    PageAddCarComponent,
    FormPaymentComponent,
    PageBourneComponent,
    PageConfBorneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule

  ],
  providers: [DataSharingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
