<main>
  <!-- End Backgrounds -->

  <div class="container">
    <div class="row d-flex justify-content-center" id="onetwotree">
      <div class="col-sm-8 rounded-4 mt-5 py-5">
        <form
          [formGroup]="formAdress"
          (ngSubmit)="updateInscription()"
          #localForm="ngForm"
        >
          <div class="d-flex justify-content-center">
            <img
              class="divimg border border-0 rounded p-2"
              src="../../../../../assets/img/2.png"
              id="logoInscreption"
              alt=""
            />
          </div>
          <div class="text-center">
            <div
              id=""
              *ngIf="isAlert"
              [ngClass]="{
                'alert alert-success text-center py-3': isAlertSuccess,
                'alert alert-danger text-center': !isAlertSuccess
              }"
              role="alert"
            >
              {{ alertMSG }}
              <button
                (click)="closeAlert()"
                type="button"
                class="btn-close"
                data-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <h2>Inscription</h2>
          </div>
          <!-- Email input -->
          <div class="container d-flex justify-content-center mt-3">
            <div class="row">
              <div class="col-md-6">
                <h5 class="text-secondary">1. Identifant</h5>
              </div>
              <div class="col-md-6">
                <h5 class="font-weight-bold">2.Information</h5>
              </div>
            </div>
          </div>
          <div class="container mb-3 mt-3">
            <div class="row d-flex flex-column align-items-center">
              <div class="col-sm-6 form-outline py-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom Commerciale*"
                  formControlName="commercialName"
                  id="commercialName"
                  name="commercialName"
                  ngModel
                  placeholder="Nom Commerciale"
                  [class.is-invalid]="
                    localForm.submitted &&
                    formAdress.get('commercialName')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-outline py-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="siren*"
                  formControlName="siren"
                  id="siren"
                  name="siren"
                  ngModel
                  placeholder="siren"
                  [class.is-invalid]="
                    localForm.submitted && formAdress.get('siren')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-outline py-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="siret*"
                  formControlName="siret"
                  id="siret"
                  name="siret"
                  ngModel
                  [class.is-invalid]="
                    localForm.submitted && formAdress.get('siret')?.invalid
                  "
                />
              </div>
              <div class="col-sm-6 form-outline py-2">
                <input
                  type="text"
                  id="address"
                  class="form-control"
                  ngModel
                  formControlName="address"
                  placeholder="Address*"
                />
                <!-- <div class="invalid-feedback">
                    username is required
                  </div> -->
              </div>

              <div class="col-sm-6 form-outline py-2">
                <input
                  type="text"
                  id="codePostal"
                  class="form-control"
                  ngModel
                  formControlName="codePostal"
                  placeholder="code postal*"
                />

                <!-- <div class="invalid-feedback">
                    password is required
                  </div> -->
                <!-- <div>
                      <input type="checkbox" >
                      show password
                    </div> -->
              </div>
              <div class="col-sm-6 form-outline py-2">
                <input
                  type="text"
                  id="ville"
                  class="form-control"
                  ngModel
                  formControlName="ville"
                  placeholder="ville*"
                />
                <!-- <div class="invalid-feedback">
                  email is required
                </div> -->
              </div>
              <div class="col-sm-6 py-4">
                <button type="submit" id="btnValid" class="btn btn-primary">
                  Valider mes identifant
                </button>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <p>* Champs requis</p>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <a
                  class="active text-primary text-end ps-2"
                  id="linkToConnexion"
                  routerLink="/pages-login"
                  >Revenir à l'ecran de connexion</a
                >
              </div>
            </div>
          </div>
        </form>

        <!-- <div *ngIf="isConfirm">
          <div class="">
            <h2>Entrez code </h2>
          <h6 style="color: green;">verification code à {{user.email}}</h6>
          </div>
          <div class="form">
            <input type="text" class="form-control px-3 py-2 rounded-4" name="verificationCode" [(ngModel)]="user.code"  placeholder="Enterz verification code">
            <button (click)="confirmSingUp()">CONFIRM</button>

          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- </div> -->
</main>
