import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { beneficeAmount, Bornes, Charging, energyProvider, enterprise, EnterpriseConsummationHistory, GrandTotale, SimplifiedEnergyProvider, User, Vehicle, VehicleToCreate } from 'src/app/models/user';
import { map, Observable} from 'rxjs';
import { CognitoService } from '../cognito.service';
import { BorneResponseType } from "../../models/borneResponseType";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type':  'application/json', 'Accept': 'application/json'})
};
const httpOptionsPdf = {
  headers: new HttpHeaders({'Content-Type':  'application/pdf', 'Accept': 'application/pdf'})
};
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private API_URL = environment.API_URL;

  constructor(private http: HttpClient, private cognitoService: CognitoService) { }

  // get nom et prenom pour page validation
  getUserContactInfo(userPoolId:string, username:string): Observable<enterprise>{

    return this.http.get<enterprise>(`${this.API_URL}v1/users/${userPoolId}/${username}`);
  }

  getEnterpriseByUserPoolIdAndUsername(userPoolId:string, username:string):Observable<enterprise>{
    return  this.http.get<enterprise>(`${this.API_URL}v1/enterprises/${userPoolId}/${username}`)
  }

  // update enterprise //
  updateEnterpriseInfo(ownerId: number, enterpriseDto: enterprise):Observable<enterprise>{
    return this.http.put<enterprise>(`${this.API_URL}v1/enterprises/update-info/${ownerId}`, enterpriseDto)
  }

  // update test work
  updateUser(iduer:number, enterprises:enterprise): Observable<enterprise> {
    const url = `${this.API_URL}v1/enterprises/update-info/${iduer}`; // Assuming the user object has an 'id' property
    return this.http.put<enterprise>(url,enterprises);
  }



  //users all
  getAllUsersFromEnterprise(enterpriseId: number):Observable<User[]>{
    return this.http.get<User[]>(`${this.API_URL}v1/enterprises/${enterpriseId}/users`)
  }

  // delete user from enterprise
  deleteUserFromEnterprise(userPoolId: string, enterpriseUsername: string, userUsername: string): Observable<any> {
    const urlDeleteUser = (`${this.API_URL}v1/enterprises/${userPoolId}/${enterpriseUsername}/user/${userUsername}`);
    return this.http.delete(urlDeleteUser);
  }
  

  /**
   * Create User for An Enterprise
   * @param enterprisePoolId
   * @param enterpriseUsername
   * @param userToCreate
   * @returns UserCreated
   */
  createUserForEnterprise( enterprisePoolId: string, enterpriseUsername: string,  userToCreate: any): Observable<User> {
    const url = `${this.API_URL}v1/enterprises/registration/${enterprisePoolId}/${enterpriseUsername}/create-user`;
    return this.http.post<User>(url, userToCreate, httpOptions);
  }

  /**
   * Confirm User From Enterprise
   * @param enterprisePoolId
   * @param enterpriseUsername
   * @param userUsername
   * @returns
   */
  confirmUserForEnterprise(enterprisePoolId: string, enterpriseUsername: string, userUsername: string): Observable<User> {
    const url = `${this.API_URL}v1/enterprises/${enterprisePoolId}/${enterpriseUsername}/user/${userUsername}/confirm`;
    return this.http.get<User>(url, httpOptions);
  }

  updateUserForEnterprise(enterpriseId: number, userId: number, userToUpdate: User) :Observable<User>{
    const url = `${this.API_URL}v1/enterprises/${enterpriseId}/update-user-info/${userId}`; // Assuming the user object has an 'id' property
    return this.http.put<User>(url, userToUpdate, httpOptions);
  }

  getInfoBornForEdite(bornId:number):Observable<Bornes[]>{
    return this.http.get<Bornes[]>(`${this.API_URL}enterprise/v1/charging-points/${bornId}`)

  }

  getAllastChargerFromBorns(enterpriseId:number):Observable<Charging>{
    return this.http.get<Charging>(`${this.API_URL}/enterprise/v1/charging-cdrs/${enterpriseId}`)
  }

  createNewEnterpriseChargingPoint(ownerId: number, bornes:Bornes):Observable<Bornes>{
    return this.http.post<Bornes>(`${this.API_URL}enterprise/v1/charging-points/${ownerId}`, bornes);
  }

// edite charge point
updateEnterpriseChargingPoint(enterprId:number, bornIdOne:number, borne: Bornes): Observable<Bornes> {
  const url = `${this.API_URL}enterprise/v1/charging-points/${enterprId}/${bornIdOne}`;
  return this.http.put<Bornes>(url, borne);
}

//delete charge popint
deleteEnterpriseChargingPoint(enterprisesId:number, idBorn: number): Observable<any> {
  const url = `${this.API_URL}enterprise/v1/charging-points/${enterprisesId}/${idBorn}`;
  return this.http.delete(url);
}
  //call all Enterprise Charging Points
  getEnterpriseChargingPoints(charchingFinshed:number){
    return this.http.get<Bornes[]>(`${this.API_URL}enterprise/v1/charging-points/${charchingFinshed}`)

  }

// consomation get in tapleu
public getAllConsomation(enterpriseId: number):Observable<Charging[]> {
  const urlConso = `${this.API_URL}enterprise/v1/charging-cdrs/${enterpriseId}?page=0`;
  return this.http.get<Charging[]>(urlConso);
}
public getAllEvse(idOwner: number,  page: number):Observable<Charging[]>{
  const allEvse = `${this.API_URL}enterprise/v1/charger-cdrs/${idOwner}?page=${page}`;
  return this.http.get<Charging[]>(allEvse)
}
// /enterprise/v1/evse/facturation/{ownerId}/{year}-{month}
public getConsomatioFacture(idOwner: number, year: number, month: number):Observable<EnterpriseConsummationHistory[]>{
  const allEvse = `${this.API_URL}enterprise/v1/evse/facturation/${idOwner}/${year}-${month}`;
  return this.http.get<EnterpriseConsummationHistory[]>(allEvse);
}

// consomation get test
/*public getAllConsomationTest(enterpriseId: number, page: number):Observable<any> {
  const urlConso = `${this.API_URL}enterprise/v1/charger-cdrs/${enterpriseId}?page=${page}`;
  return this.http.get<any>(urlConso,{observe: 'response'});
}*/
  public getAllConsomationTest(enterpriseId: number, page: number):Observable<Charging[]> {
  const urlConso = `${this.API_URL}enterprise/v1/charger-cdrs/${enterpriseId}?page=${page}`;
  return this.http.get<Charging[]>(urlConso);
}
  /*public async getAllConsomationTest(enterpriseId: number, page: number): Promise<any> {
    const urlConso = `${this.API_URL}enterprise/v1/charger-cdrs/${enterpriseId}?page=${page}`;

    try {
      const response = await fetch(urlConso);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const headers = response.headers;
      const data = await response.json();

      return { headers, data }; // Return both headers and data

    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Re-throw the error to allow for further handling
    }
  }*/
// consomation get in tapleu
public getAllConsomationPagination(enterpriseId: number, page: number = 0, pageSize: number = 15): Observable<Charging[]> {
  const urlConso = `${this.API_URL}enterprise/v1/charging-cdrs/${enterpriseId}?page=${page}&pageSize=${pageSize}`;
  return this.http.get<Charging[]>(urlConso);
}

createVehicleForUser(carToCreate: VehicleToCreate) {
  const url = `${this.API_URL}v1/vehicles/create`;
  return this.http.post<Vehicle>(url, carToCreate, httpOptions);
}
// updateFurnissuer(energyProviderData: any): Observable<energyProvider>{
//   const postBenefice = `${this.API_URL}enterprise/v1/energy/provider/store`;
//   return this.http.post<energyProvider>(energyProviderData ,postBenefice, httpOptions)
// }

updateFurnissuer(energyProviderData: any): Observable<energyProvider> {
  const postBenefice = `${this.API_URL}enterprise/v1/energy/provider/store`;
  console.log('postBenefice:', postBenefice);
  return this.http.post<energyProvider>(postBenefice, energyProviderData, httpOptions);
}


getbeneficeAndDepenses(beneficeAndDepensesId: number): Observable<beneficeAmount> {
  const getbeneficeAndDepensesId = `${this.API_URL}enterprise/v1/evse/facturation/${beneficeAndDepensesId}/depenses`;
  return this.http.get<beneficeAmount>(getbeneficeAndDepensesId, httpOptions);
}

getbeneficeAndDepensesHistory(beneficeAndDepensesIdHistory: number): Observable<beneficeAmount[]> {
  const getbeneficeAndDepensesIdHistory = `${this.API_URL}enterprise/v1/evse/facturation/${beneficeAndDepensesIdHistory}/depenses/history`;
  return this.http.get<beneficeAmount[]>(getbeneficeAndDepensesIdHistory, httpOptions);
}

getEnergyProvaider(energyId: number): Observable<energyProvider> {
  const urlGetEnergyProvaider = `${this.API_URL}enterprise/v1/energy/provider/get/${energyId}`;
  return this.http.get<energyProvider>(urlGetEnergyProvaider, httpOptions);
}



getVehicleFromUserId(userId: number): Observable<Vehicle> {
  const urlVehicle = `${this.API_URL}v1/vehicles/${userId}`;
  return this.http.get<Vehicle>(urlVehicle, httpOptions);
}

updateVehicle(vehicleToUpdate: Vehicle) {
  const urlVehicle = `${this.API_URL}v1/vehicles/update`;
  return this.http.put<Vehicle>(urlVehicle, vehicleToUpdate, httpOptions);
}

deleteVehicle(vehicleId: number) {
  const urlVehicle = `${this.API_URL}v1/vehicles/${vehicleId}`;
  return this.http.delete<Vehicle>(urlVehicle, httpOptions);
}

// //////////// get PDF Facture by month
getAllMoisFacture(enterId: number, dateYear: number, dateMonth: number){
  const urlPdfFacture = `${this.API_URL}enterprise/v1/facturation/${enterId}/${dateYear}-${dateMonth}`;
  return this.http.get(urlPdfFacture , { ...httpOptionsPdf, responseType:'arraybuffer'}).pipe(
    map((response: ArrayBuffer) => new Blob([response], { type: 'application/pdf' }))
  )
}
// get factur mois en cours

getFacturMoisEnCours(facturId:number): Observable<EnterpriseConsummationHistory[]>{
  const urlPdfFactureEnCours = `${this.API_URL}enterprise/v1/facturation/${facturId}/now`;
  return this.http.get<EnterpriseConsummationHistory[]>(urlPdfFactureEnCours )
}
// get factur mois en cours
getFacturMoisEnCoursProd(facturProdId:number): Observable<EnterpriseConsummationHistory[]>{
  const urlPdfFactureEnCours = `${this.API_URL}enterprise/v1/evse/facturation/${facturProdId}/now`;
  return this.http.get<EnterpriseConsummationHistory[]>(urlPdfFactureEnCours )
}

getMonthlyFacture(enterId: number, pdfLink:string){
  const urlPdfFacture = `${this.API_URL}enterprise/v1/facturation/${enterId}${pdfLink}`;
  return this.http.get(urlPdfFacture , { ...httpOptionsPdf, responseType:'arraybuffer'}).pipe(
    map((response: ArrayBuffer) => new Blob([response], { type: 'application/pdf' }))
  )
}

getAllFacture(enterId: number){
  const urlAllFacturePaginated = `${this.API_URL}enterprise/v1/facturation/${enterId}/history`;
  return this.http.get<EnterpriseConsummationHistory[]>(urlAllFacturePaginated, httpOptions)
}

getAllFactureProduction(enterProId: number){
  const urlAllFacturePaginatedProduction = `${this.API_URL}enterprise/v1/evse/facturation/${enterProId}/history`;
  return this.http.get<EnterpriseConsummationHistory[]>(urlAllFacturePaginatedProduction, httpOptions)
}
// consomation totale chiffre
public getChiffreConsomationTotale(enterpriseId: number){
  const constconsomationChiffre = `${this.API_URL}enterprise/v1/facturation/${enterpriseId}/history/overall-total`;
  return this.http.get<GrandTotale>(constconsomationChiffre, httpOptions)
 }
// consomation totale chiffre production
public getChiffreConsomationTotaleProduction(enterpriseIdProd: number){
  const constconsomationChiffreProduction = `${this.API_URL}enterprise/v1/evse/facturation/${enterpriseIdProd}/history/overall-totalEvse`;
  return this.http.get<GrandTotale>(constconsomationChiffreProduction, httpOptions)
 }
// 
getTotalDataForMonth(chargings: Charging[], month: string): Charging {

  const chargingsForMonth = chargings.filter(
    (charging) => new Date(charging.startDateTime).getMonth() + 1 === this.getMonthNumber(month)
  );

  const totalCost = chargingsForMonth.reduce((sum, charging) => sum + charging.totalCost, 0);
  const totalEnergy = chargingsForMonth.reduce((sum, charging) => sum + charging.totalEnergy, 0);

  return {
    uid: '',
    chargingPoint: '',
    startDateTime: '',
    startDateTimeTech:'',
    stopDateTimeTech:'',
    stopDateTime: '',
    totalCost,
    totalEnergy,
    unitPrix: 0,
    
  };
}

private getMonthNumber(month: string): number {
  const monthMap: { [key: string]: number } = {
    janvier: 1,
    février: 2,
    mars: 3,
    avril: 4,
    mai: 5,
    juin: 6,
    juillet: 7,
    août: 8,
    septembre: 9,
    octobre: 10,
    novembre: 11,
    décembre: 12,
  };

  return monthMap[month.toLowerCase()];
}


getBenefice(beneficeId: number){
  const benefice = `${this.API_URL}enterprise/v1/facturation/${beneficeId}/history/overall-total`;
  return this.http.get<GrandTotale>(benefice, httpOptions)
}


  getBorneConf(entId:number, borneId:number) : Observable<string> {
    const url = `${this.API_URL}enterprise/v1/charging-points/${entId}/${borneId}/configurations`;
    return this.http.get(url, {responseType: 'text'});

  }

}


