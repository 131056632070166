<main id="main" class="main">
    <div class="pagetitle">
       <h1>Pagination</h1>
       <nav>
          <ol class="breadcrumb">
             <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
             <li class="breadcrumb-item">Components</li>
             <li class="breadcrumb-item active">Pagination</li>
          </ol>
       </nav>
    </div>
    <section class="section">
       <div class="row">
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Basic Pagination</h5>
                   <nav aria-label="Page navigation example">
                      <ul class="pagination">
                         <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item"><a class="page-link" href="#">Next</a></li>
                      </ul>
                   </nav>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Disabled and active states</h5>
                   <nav aria-label="...">
                      <ul class="pagination">
                         <li class="page-item disabled"> <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a></li>
                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                         <li class="page-item active" aria-current="page"> <a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item"> <a class="page-link" href="#">Next</a></li>
                      </ul>
                   </nav>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Alignment</h5>
                   <nav aria-label="Page navigation example">
                      <ul class="pagination">
                         <li class="page-item disabled"> <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a></li>
                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item"> <a class="page-link" href="#">Next</a></li>
                      </ul>
                   </nav>
                   <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-center">
                         <li class="page-item disabled"> <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a></li>
                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item"> <a class="page-link" href="#">Next</a></li>
                      </ul>
                   </nav>
                   <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-end">
                         <li class="page-item disabled"> <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a></li>
                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item"> <a class="page-link" href="#">Next</a></li>
                      </ul>
                   </nav>
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Pagination with icon</h5>
                   <nav aria-label="Page navigation example">
                      <ul class="pagination">
                         <li class="page-item"> <a class="page-link" href="#" aria-label="Previous"> <span aria-hidden="true">&laquo;</span> </a></li>
                         <li class="page-item"><a class="page-link" href="#">1</a></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item"> <a class="page-link" href="#" aria-label="Next"> <span aria-hidden="true">&raquo;</span> </a></li>
                      </ul>
                   </nav>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Sizing</h5>
                   <nav aria-label="...">
                      <ul class="pagination pagination-sm">
                         <li class="page-item active" aria-current="page"> <span class="page-link">1</span></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                      </ul>
                   </nav>
                   <nav aria-label="...">
                      <ul class="pagination">
                         <li class="page-item active" aria-current="page"> <span class="page-link">1</span></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                      </ul>
                   </nav>
                   <nav aria-label="...">
                      <ul class="pagination pagination-lg">
                         <li class="page-item active" aria-current="page"> <span class="page-link">1</span></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                      </ul>
                   </nav>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>