<main id="main" class="main">
    <div class="pagetitle">
       <h1>Chart.js</h1>
       <nav>
          <ol class="breadcrumb">
             <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
             <li class="breadcrumb-item">Charts</li>
             <li class="breadcrumb-item active">Chart.js</li>
          </ol>
       </nav>
    </div>
    <p>Chart.JS Examples. You can check the <a href="https://www.chartjs.org/docs/latest/samples/" target="_blank">official website</a> for more examples.</p>
    <section class="section">
       <div class="row">
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Line Chart</h5>
                   <canvas id="lineChart" style="max-height: 400px;"></canvas>
                   
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Bar CHart</h5>
                   <canvas id="barChart" style="max-height: 400px;"></canvas>
                   
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Pie Chart</h5>
                   <canvas id="pieChart" style="max-height: 400px;"></canvas>
                  
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Doughnut Chart</h5>
                   <canvas id="doughnutChart" style="max-height: 400px;"></canvas>
                  
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Radar Chart</h5>
                   <canvas id="radarChart" style="max-height: 400px;"></canvas>
                  
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Polar Area Chart</h5>
                   <canvas id="polarAreaChart" style="max-height: 400px;"></canvas>
                  
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Stacked Bar Chart</h5>
                   <canvas id="stakedBarChart" style="max-height: 400px;"></canvas>
                   
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Bubble Chart</h5>
                   <canvas id="bubbleChart" style="max-height: 400px;"></canvas>
                   
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>