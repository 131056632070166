import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Bornes, Charging, enterprise, EnterpriseConsummationHistory, GrandTotale, MonthlyConsumption, User, Vehicle,ChargeStatus, energyProvider, beneficeAmount } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  
  monthlyTotals: Map<string, number> = new Map<string, number>();
 
  
  page: number = 0;
  priceTotalDisplay : string | undefined;
  lastConsomation: number | undefined;
  lastConsomationProduction: number | undefined;

  totalMoisConsomation: string | undefined;
  totalPriceDashbord: number | undefined;
  totalPrice: number | undefined;
  // globalEnergy: number | undefined;

  public dataEvse: Charging[] = [];
  public conso: Charging[] = [];
  public consoMonth: EnterpriseConsummationHistory[] = [];
  public consoMounthProduction: EnterpriseConsummationHistory[] = [];

  datatablePdf: EnterpriseConsummationHistory[] = [];
  ConsoMounth : number | undefined;
  ConsoMounthProduction: number | undefined;

  testConso : number | undefined;
  testConsoProd : number | undefined;

  BeneficTotal : beneficeAmount[] = [];
  DepensesTotal :beneficeAmount[] = [];
  TotalAmount :beneficeAmount[] = [];
  beneficeTotal :number | undefined;
  depensesTotal :number | undefined;
  totalAmount :number | undefined;
  // moisEnCoursFacture :EnterpriseConsummationHistory[] = [];
  moisEnCoursFacture :number | undefined;
  moisEnCoursFactureProd :number | undefined;

  globaleTotal: GrandTotale | any;
  globaleTotalProduction: GrandTotale | any;

  priceTotal: number = 0
  //
  chartData : any;
  chartDataProduction : any;
  totalDataForMonth: Charging | undefined;
  chargings: Charging[] = [];
  chargingData: Charging[] = [];
  enterpriseId : enterprise | any;
  powerData : Charging[] = [];
  pointChargin : Charging[] = [];
  dataDate : Charging[] = [];
  filteredData: Charging[] = [];
  //
  isUserLoggedIn ?: boolean;
  id : number | undefined;
  totalBorns: Bornes [] = [];
  
  statusCounts: { [key in ChargeStatus]: number } = {
    Available: 0,
    Preparing: 0,
    Charging: 0,
    Finishing: 0,
    Unavailable:0,
  };
  statusList: ChargeStatus[] = Object.values(ChargeStatus);
  carsList: Vehicle[] = [];
  bornsList : Bornes[] = [];
  dataUsers : User[] = [];
  benefice : energyProvider [] = [];
    totalCars: number = 0;
    totalAmountForLastMonth: number = 0; 
    monthlyTotalAmounts: number[] = [];


    chartDataAficheAffaire: number[] = [];

  constructor(private elementRef: ElementRef, private router:Router, private cognitoService: CognitoService, private dataSharingService : DataSharingService, private  userService: UserService,) {
    this.dataSharingService.isUserLoggedIn.subscribe( value => {
      this.isUserLoggedIn = value;
  });
   }


   

  ngOnInit(): void {
    this.getIdEnterprise();
    this.filterByLastMonth();
    for (let month = 1; month <= 12; month++) {
      this.getBusinessAmount(month);
    }

}

   getPdfDashboard(id: number) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; 

    this.userService.getAllFacture(id)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
      console.log(data, "Consomation chart est march");

      this.consoMonth = data.filter(item => {
        const itemDate = new Date(item.date); 
        return itemDate.getMonth() + 1 === currentMonth; 
      });



        this.datatablePdf = this.consoMonth; 
      
      this.priceTotal = this.consoMonth.reduce((total, item) => total + item.amount, 0);

      if (this.consoMonth.length > 0) {
        this.ConsoMounth = this.consoMonth[this.consoMonth.length - 1].power;
      } else {
        this.ConsoMounth = 0;
      }
    });
  }

  lastConsommationMonthProduction(id: number) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    this.userService.getAllFactureProduction(id)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
      console.log(data, "consomation production ");


      if (this.consoMounthProduction.length > 0) {
        this.testConsoProd = this.consoMounthProduction[this.consoMounthProduction.length - 1].power;
      } else {
        this.testConsoProd = 0;
      }
      
      this.consoMounthProduction = data.filter(item => {
        const itemDate = new Date(item.date); 
        return itemDate.getMonth() + 1 === currentMonth;
      });
         this.datatablePdf = this.consoMounthProduction;
      
      this.priceTotal = this.consoMounthProduction.reduce((total, item) => total + item.amount, 0);

      if (this.consoMounthProduction.length > 0) {
        this.ConsoMounthProduction = this.consoMounthProduction[this.consoMounthProduction.length - 1].power;
      } else {
        this.ConsoMounthProduction = 0;
      }
    });
  }

  getMoisEnCoursFacturs(factursIdEnCours:number){
    this.userService.getFacturMoisEnCours(factursIdEnCours)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
  
      if (data && data.length > 0 && data[0].power !== null && data[0].power !== undefined && data[0].power !== 0) {
        this.testConso = data[0].power;
      } else {
        this.testConso = 0;
      }
        if (data && data.length > 0 && data[0].amount !== null && data[0].amount !== undefined && data[0].amount !== 0) {
        this.moisEnCoursFacture = data[0].amount;
      } else {
        this.moisEnCoursFacture = 0;
      }
      // this.moisEnCoursFacture = data
      console.log("dani est malad", this.moisEnCoursFacture);
    })
  
  }

  getMoisEnCoursFactursProd(factursIdEnCoursProd:number){
    this.userService.getFacturMoisEnCours(factursIdEnCoursProd)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
  
      if (data && data.length > 0 && data[0].power !== null && data[0].power !== undefined && data[0].power !== 0) {
        this.moisEnCoursFactureProd = data[0].power;
      } else {
        this.moisEnCoursFactureProd = 0;
      }
      console.log("Mois en cours Production consomation", this.moisEnCoursFactureProd);
    })
  
  }
getbusniesAmount(amountId:number){
  this.userService.getbeneficeAndDepenses(amountId)
  .subscribe((data: beneficeAmount) => {
    console.log(data, "all bbbbbbbbb user ");
      this.beneficeTotal = data.income;
      this.depensesTotal = data.lost;
      this.totalAmount = data.total;
  })

  
}

getBusinessAmount(month: number) {
  this.userService.getbeneficeAndDepenses(month).subscribe((data: beneficeAmount) => {
    const totalAmount = data.income - data.lost;
    this.monthlyTotalAmounts[month - 1] = totalAmount;
  });
}
  getAllUsers(id:number){
    this.userService.getAllUsersFromEnterprise(id)
    .subscribe((data: User[]) => {
      
      this.dataUsers = data;
      console.log(data ,"all data user ")
    })
  }

  getToutalBornes(id: number){
    this.userService.getEnterpriseChargingPoints(id)
    .subscribe((data: Bornes[]) => {

      this.bornsList = data;
      this.statusCounts = this.getBornesStatusCounts(data);
      
    })
  }

  getAllCars(enterpriseId: number) {
    this.userService.getAllUsersFromEnterprise(enterpriseId).subscribe((users: User[]) => {
      this.totalCars = 0;
      users.forEach(user => {
        this.totalCars += user.vehicules.length;
      });
    });
  }

getBornesStatusCounts(data: Bornes[]): { [key in ChargeStatus]: number } {
  const statusCounts: { [key in ChargeStatus]: number } = {
    [ChargeStatus.Available]: 0 ,
    [ChargeStatus.Preparing]: 0,
    [ChargeStatus.Charging]: 0,
    [ChargeStatus.Finishing]: 0,
    [ChargeStatus.Unavailable]: 0,
  };

  data.forEach((bornes: Bornes) => {
    statusCounts[bornes.status]++;
  });

  return statusCounts;

}

getStatusLabel(status: ChargeStatus): string {
  const statusLabels: { [key in ChargeStatus]: string } = {
    Available: 'Disponible',
    Preparing: 'Préparation',
    Charging: 'Charge',
    Finishing: 'Finition',
    Unavailable: 'Indisponible',
  };

  return statusLabels[status];
}
  getIdEnterprise(){
 
    this.dataSharingService.currentEnterpriseData.subscribe(data => {
      
      if (data && data.username !== "") {
          this.getAllCars(data.id);
          this.getToutalBornes(data.id);
          this.getConsoTotalPriceByMonth(data.id);
          this.getConsomation_All(data.id);
          this.getPdfDashboard(data.id);
          this.getConsoChiffre(data.id);
          this.getAllUsers(data.id);
          this.getConsomation_All_Test(data.id)
          this.getConsomation_All_Production(data.id)
          this.getAllEvseAll(data.id)
          this.lastConsommationMonthProduction(data.id)
          this.getConsoChiffreProduction(data.id)
          this.getConsommationFacturation(data.id)
          this.getTotalAmountForLastMonth(data.id)
         this.getbusniesAmount(data.id)
         this.getMoisEnCoursFacturs(data.id)
         this.getMoisEnCoursFactursProd(data.id)
         this.getBeneficeInTheChart(data.id)
         this.getBeneficeInTheChartDepanses(data.id)
         this.getBeneficeInTheChartBenefices(data.id)
         
      } else {
        this.cognitoService.getCurrentUser().then(result =>{
          result.subscribe(user => {
            this.getAllCars(user.id)
            this.getToutalBornes(user.id)
            this.getConsoTotalPriceByMonth(user.id)
            this.getConsomation_All(user.id);
            this.getPdfDashboard(user.id);
            this.getConsoChiffre(user.id);
            this.getAllUsers(user.id);
            this.getConsomation_All_Test(user.id)
            this.getConsomation_All_Production(user.id)
            this.getAllEvseAll(user.id)
            this.lastConsommationMonthProduction(user.id)
            this.getConsoChiffreProduction(user.id)
            this.getConsommationFacturation(user.id)
            this.getTotalAmountForLastMonth(user.id)
            this.getbusniesAmount(user.id)
            this.getMoisEnCoursFacturs(user.id)
            this.getMoisEnCoursFactursProd(user.id)
            this.getBeneficeInTheChart(user.id)
            this.getBeneficeInTheChartDepanses(user.id)
            this.getBeneficeInTheChartBenefices(user.id)
          })
        })
      }
    })
  }


  getConsomation_All(id: number) {
    console.log("dans dashboard");
    this.userService.getAllConsomation(id)
    .subscribe((data: Charging[])=> {
      console.log('get data =>',data)
      this.chargingData = data;
      this.filteredData = data;
      
      if (this.chartData!=null) { 
        const monthlyConsumption: MonthlyConsumption = {};

        for (let i = 0; i < this.chartData.length; i++) {
            const consumption = this.chartData[i].totalCost;
            const stopDate = new Date(this.chartData[i].stopDateTime);
            const month = stopDate.getMonth();

            if (!monthlyConsumption.hasOwnProperty(month)) {
                monthlyConsumption[month] = 0;
            }
            monthlyConsumption[month] += consumption;
        }
        const months = Object.keys(monthlyConsumption);
        const consumptionData = months.map(month => monthlyConsumption[Number(month)]);

        this.chartTest(months, consumptionData); 
        for(let i=0; i<this.chartData.length ; i++){
           console.log("chart data" , this.chartData[i]);
          this.powerData.push(this.chartData[i].totalCost);
          this.pointChargin.push(this.chartData[i].chargingPoint);
          this.dataDate.push(this.chartData[i].stopDateTime);
        }
      

       }
    });
  }

 
    
  

  private getConsoChiffre(id: number){
    this.userService.getChiffreConsomationTotale(id).subscribe((dataGlobale: GrandTotale)=> {
      console.log('get total cost =>',dataGlobale)
      this.globaleTotal = dataGlobale;
    });
  }

  private getConsoChiffreProduction(id: number){
    this.userService.getChiffreConsomationTotaleProduction(id).subscribe((dataGlobaleProd: GrandTotale)=> {
      console.log('get total production =>',dataGlobaleProd)
      this.globaleTotalProduction = dataGlobaleProd;
    });
  }


  private getAllEvseAll(evseId: number) {
    this.userService.getAllEvse(evseId, 0).subscribe(
      (response: Charging[]) => {
        console.log(response,'Hussam Ahtash');
        this.dataEvse = response;
        if (this.dataEvse.length > 0) {
          this.lastConsomationProduction = this.dataEvse[this.dataEvse.length - 1].totalEnergy;
        } else {
          this.lastConsomationProduction = 0;
        }
        
              console.log(this.lastConsomationProduction, "last consommation production")
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }
  private getConsoTotalPriceByMonth(id: number){
    console.log("totalprice dashboard");
    this.userService.getAllConsomation(id)
    .subscribe((data: Charging[])=> {
      console.log('get total price =>',data)

      this.conso = data;

if (this.conso.length > 0) {
  this.lastConsomation = this.conso[this.conso.length - 1].totalEnergy;
} else {
  this.lastConsomation = 0;
}

      console.log(this.lastConsomation, "last conso")
    });
  }

  private chartMois(labels: string[], powerData: number[]) {
    var myChart = new Chart("myChartMoisDashboard", {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'kwh',
          data: powerData,
          backgroundColor: 'rgba(54, 162, 235, 0.9)',
          borderColor: 'rgba(54, 162, 235, 0.9)',
          borderWidth: 4
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
        }
      }
    });
  }

  // chart production
  private chartMoisProduction(labels: string[], powerData: number[]) {
    var myChart = new Chart("myChartConsoElectricite", {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'kwh',
          data: powerData,
          backgroundColor: 'rgba(54, 162, 235, 0.9)',
          borderColor: 'rgba(54, 162, 235, 0.9)',
          borderWidth: 4
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
        }
      }
    });
  }        
                
  filterByLastMonth() {
    console.log("mounth")
    const today = new Date();
    const filtered = this.chargingData.filter((item) => {
      const startDate = new Date(item.startDateTime);
      return (
        startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear()
      );
    });
    this.filteredData = filtered;
  this.totalPrice = filtered.reduce((accumulator, item) => {
    return accumulator + item.totalEnergy;
  }, 0);

  console.log('Total Price:', this.totalPrice);
  }




  private chartTest(months: string[], consumptionData: number[]) {
    var myChart = new Chart("myChart", {
      type: 'bar',
      data: {
           labels: months,
          // labels: ['août', 'septembre', 'octobre', 'novembre', 'décembre','janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet'],

          datasets: [{

              label: 'kwh',
              data: consumptionData,
              backgroundColor: [
                  'rgba(54, 162, 235, 0.9)',
              ],
           borderColor: [
            'rgba(54, 162, 235, 0.9)',
        ],
              borderWidth: 4
          }]
      },
      options: {
          scales: {
              y: {
                  beginAtZero: true
              }
          }
      }
  });

  }



  // consomation chart ppour Exploitation

  getConsomation_All_Test(id : number) {
    console.log("get all before");
    this.userService.getAllConsomation(id)
    .subscribe((data: Charging[])=> {
      console.log('get all pagination =>',data)
         const groupedData = this.groupDataByMonth(data);
         const monthLabels = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
   
         const labels = this.generateLabels(groupedData, monthLabels);
         const powerData = groupedData.map(item => item.totalPower);
   
         this.chartMois(labels, powerData);
     
    });
  }

  groupDataByMonth(data: Charging[]): { label: string, totalPower: number }[] {
    const groupedData = [];
    for (const item of data) {
      const monthYear = item.stopDateTimeTech.split('-').slice(0, 2).join('-');
      const existingMonthIndex = groupedData.findIndex(entry => entry.label === monthYear);
  
      if (existingMonthIndex !== -1) {
        groupedData[existingMonthIndex].totalPower += item.totalEnergy;
      } else {
        groupedData.push({
          label: monthYear,
          totalPower: item.totalEnergy
        });
      }
    }
      groupedData.sort((a, b) => {
        return new Date(a.label).getTime() - new Date(b.label).getTime();
      });
  
    return groupedData;
  }

  generateLabels(groupedData: { label: string, totalPower: number }[], monthLabels: string[]): string[] {
    const labels = [];
    const firstMonthIndex = monthLabels.indexOf('août');
    if (firstMonthIndex !== -1) {
      for (let i = 0; i < 12; i++) {
        const index = (firstMonthIndex + i) % 12;
        labels.push(monthLabels[index]);
      }
    }
    return labels;
  }


  // consomation chart ppour Production

   // consomation chart ppour Production

   getConsomation_All_Production(id : number) {
    console.log("get all before");
    this.userService.getAllFactureProduction(id)
    .subscribe((data: EnterpriseConsummationHistory[])=> {
      console.log('get all pagination =>',data)
         const monthLabels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        let powerData : number[] = [];
        data.forEach(element => {
          const parts = element.yearAndMonth.split("_");
        const month = parts[1];
        const getIndex = monthLabels.indexOf(month);
        powerData[getIndex] = element.power;
        });
         this.chartMoisProduction(monthLabels, powerData);
    });
  }


  // facturation consomation

  getConsommationFacturation(id: number){
    this.userService.getAllFacture(id).subscribe((data: EnterpriseConsummationHistory[]) => {
      this.calculateMonthlyTotals(data);
      const months = Array.from(this.monthlyTotals.keys());
      const totalAmounts = Array.from(this.monthlyTotals.values());
      this.chartFacturs(months, totalAmounts);
      console.log(data, 'Conso facture hussam');
      
    });
  }

  calculateMonthlyTotals(data: EnterpriseConsummationHistory[]) {
    data.forEach((item) => {
      const { yearAndMonth, amount } = item;
      if (!this.monthlyTotals.has(yearAndMonth)) {
        this.monthlyTotals.set(yearAndMonth, 0); // Initialize to 0 if not present
      }
      const currentTotal = this.monthlyTotals.get(yearAndMonth);
      if (currentTotal !== undefined) {
        this.monthlyTotals.set(yearAndMonth, currentTotal + amount);
      }
    });
  }

  chartFacturs(months: string[], totalAmounts: number[]) {
    const customLabels = ['août', 'septembre', 'octobre', 'novembre', 'décembre', 'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet'];
    var myChart = new Chart("myChartMoisDashboardFacturation", {
      type: 'line',
      data: {
        labels: customLabels,
        datasets: [{
          label: 'Total Amount',
          data: totalAmounts,
          backgroundColor: [
            'rgba(54, 162, 235, 0.9)',
          ],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
    });
  }



  // get history for ficher a faire total
  getBeneficeInTheChart(beneficeId: number){
    this.userService.getbeneficeAndDepensesHistory(beneficeId)
    .subscribe((data: beneficeAmount[]) => {
      console.log(data, "History for benefice ");
      this.calculateMonthlyTotalsFicheAffaire(data);
      const months = Array.from(this.monthlyTotals.keys());
      const totalAmounts = Array.from(this.monthlyTotals.values());
      this.chartChiffre(months, totalAmounts);
      console.log(data, ' affaiche affaire total test');
    })
  }


  calculateMonthlyTotalsFicheAffaire(data: beneficeAmount[]) {
    data.forEach((item) => {
      const { date, total } = item;
      if (!this.monthlyTotals.has(date)) {
        this.monthlyTotals.set(date, 0); 
      }
      const currentTotal = this.monthlyTotals.get(date);
      if (currentTotal !== undefined) {
        this.monthlyTotals.set(date, currentTotal + total);
      }
    });
  }
    private chartChiffre(months: string[], totalAmounts: number[]) {
      const customLabels = ['août', 'septembre', 'octobre', 'novembre', 'décembre', 'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet'];

      var myChart = new Chart("myChartChiffreAfaireHistoriChiffre", {
        type: 'line',
        data: {
            // labels: dateData,
            labels: customLabels,
  
            datasets: [{
  
                label: '€',
                data: totalAmounts,
                backgroundColor: [
                    'rgba(54, 162, 235, 0.9)',
                ],
                fill: false,
                pointBackgroundColor:'red',
                // borderColor: 'red',
                borderDash: [5, 5],
                borderWidth: 1,
                spanGaps: true,
                tension: 0.1
            }]
        },
    });
  
    }
// 

// get history for ficher a faire debenses
 getBeneficeInTheChartDepanses(beneficeId: number){
  this.userService.getbeneficeAndDepensesHistory(beneficeId)
  .subscribe((data: beneficeAmount[]) => {
    console.log(data, "History for benefice ");
    this.calculateMonthlyTotalsDebanses(data);
    const months = Array.from(this.monthlyTotals.keys());
    const totalAmounts = Array.from(this.monthlyTotals.values());
    this.chartDepanses(months, totalAmounts);
    console.log(data, ' affaiche affaire depanses test');
  })
}
calculateMonthlyTotalsDebanses(data: beneficeAmount[]) {
  data.forEach((item) => {
    console.log(data, "data depanses");
    
    const { date, lost } = item;
    if (!this.monthlyTotals.has(date)) {
      this.monthlyTotals.set(date, 0); 
    }
    const currentTotal = this.monthlyTotals.get(date);
    if (currentTotal !== undefined) {
      this.monthlyTotals.set(date,lost);
    }
  });
}
  private chartDepanses(months: string[], totalAmounts: number[]) {
    const customLabels = ['août', 'septembre', 'octobre', 'novembre', 'décembre', 'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet'];

    var myChart = new Chart("myChartDepanses", {
      type: 'line',
      data: {
          // labels: dateData,
          labels: customLabels,

          datasets: [{

              label: '€',
              data: totalAmounts,
              backgroundColor: [
                  'rgba(54, 162, 235, 0.9)',
              ],
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
          }]
      },
  });

  }
// 

// get history for ficher a faire Bénéfices
getBeneficeInTheChartBenefices(beneficeId: number){
  this.userService.getbeneficeAndDepensesHistory(beneficeId)
  .subscribe((data: beneficeAmount[]) => {
    console.log(data, "History for benefice ");
    this.calculateMonthlyTotalsBenefices(data);
    const months = Array.from(this.monthlyTotals.keys());
    const totalAmounts = Array.from(this.monthlyTotals.values());
    this.chartBenefices(months, totalAmounts);
    console.log(data, ' affaiche affaire Benefices test');
  })
}


// calculateMonthlyTotalsBenefices(data: beneficeAmount[]) {
  
//   data.forEach((item) => {
//     console.log(data, "data depanses");
    
//     const { date, income } = item;
//     if (!this.monthlyTotals.has(date)) {
//       this.monthlyTotals.set(date, 0); 
//     }
//     const currentTotal = this.monthlyTotals.get(date);
//     if (currentTotal !== undefined) {
//       this.monthlyTotals.set(date,income);
//     }
//   });
// }

calculateMonthlyTotalsBenefices(data: beneficeAmount[]) {
  const monthLabels = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];

  // Initialize the monthlyTotals map with 0 values for all months
  monthLabels.forEach(month => {
    this.monthlyTotals.set(month, 0);
  });

  data.forEach((item) => {
    const { date, income } = item;
    const month = date.split('_')[1]; // Extract the month from the date string
    if (this.monthlyTotals.has(month)) {
      let currentTotal = this.monthlyTotals.get(month) || 0; // Initialize to 0 if undefined
      this.monthlyTotals.set(month, currentTotal + income);
    }
  });
  
}

  private chartBenefices(months: string[], totalAmounts: number[]) {
    const customLabels = ['août', 'septembre', 'octobre', 'novembre', 'décembre', 'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet'];

    var myChart = new Chart("myChartBenefices", {
      type: 'line',
      data: {
          // labels: dateData,
          labels: customLabels,

          datasets: [{

              label: '€',
              data: totalAmounts,
              backgroundColor: [
                  'rgba(54, 162, 235, 0.9)',
              ],
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
          }]
      },
  });

  }
// 

  getTotalAmountForLastMonth(id: number) {
    this.userService.getAllFacture(id).subscribe((data: EnterpriseConsummationHistory[]) => {
      const lastMonth = this.calculateLastMonth(data);
      const totalAmountForLastMonth = this.calculateTotalAmount(lastMonth);
      console.log(totalAmountForLastMonth, 'Total Amount for Last Month');
    });
  }
  
  calculateLastMonth(data: EnterpriseConsummationHistory[]): EnterpriseConsummationHistory[] {
    const sortedData = data.slice().sort((a, b) => (a.yearAndMonth < b.yearAndMonth ? 1 : -1));
      const lastMonthData = sortedData[0];
  
    return lastMonthData ? [lastMonthData] : [];
  }
  
  calculateTotalAmount(data: EnterpriseConsummationHistory[]): number {
    return data.reduce((total, item) => total + item.amount, 0);
  }
  

}

