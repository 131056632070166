<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section profile">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body pt-3">
            <h5 class="card-title">Modifier la borne</h5>
            <a
              class="button-Back d-flex justify-content-start"
              routerLink="/pages-parc"
            >
              <i id="iconBack" class="ri-arrow-drop-left-line"></i>
              <p class="btn-link button-Back">Retour</p>
            </a>
            <div class="text-center">
              <div
                id=""
                *ngIf="isAlert"
                [ngClass]="{
                  'alert alert-success text-center py-3': isAlertSuccess,
                  'alert alert-danger text-center': !isAlertSuccess
                }"
                role="alert"
              >
                {{ alertMSG }}
                <button
                  (click)="closeAlert()"
                  type="button"
                  class="btn-close"
                  data-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <div class="container mt-4">
              <form (ngSubmit)="updateBorn()">
                <h5 class="card-title mt-3">Borne</h5>
                <!-- card edite -->
                <!-- <div class="row d-flex justify-content-center">
                  <div class="col-md-4">
                    <div
                      class="card border border-0 text-center cardBorne py-4"
                    >
                      <span class="d-flex flex-column">
                        <span class="d-flex justify-content-center">
                          <img
                            class=" "
                            src="../../../../../assets/img/2.png"
                            id="logoInscreption"
                            alt=""
                          />
                        </span>

                        <h4 class="efusionColor">E-fusion</h4>
                      </span>
                      <h5 class="card-title efusionColor">
                        {{ bornToEdit.name }}
                      </h5>
                      <p class="card-text text-start ps-2 textEvId">
                        <span class="fw-bold evsId">Evse ID:-</span
                        ><span class="">{{ bornToEdit.evseId }}</span>
                      </p>
                      <p class="card-text text-start ps-2 textEvId">
                        <span class="fw-bold evsId">Price:</span
                        ><span class="ps-5">{{ bornToEdit.price }} €</span>
                      </p>

                      <div class="card-body">
                        <div class="row d-flex justify-content-center">
                          <div
                            class="screen d-flex align-items-center justify-content-center"
                          >
                            <h6 class="tableNormalTd mt-2">
                              {{ bornToEdit.status }}
                            </h6>
                          </div>
                          <div class="card-body d-flex justify-content-between">
                            <p class="card-text cardText">
                              {{ bornToEdit.postalCode }}
                            </p>
                            <p class="card-text cardText">
                              {{ bornToEdit.country }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- end card edite -->
                <div class="row">
                  <!-- <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >model</span
                      >
                      <input
                        id="model"
                        [(ngModel)]="bornToEdit.model"
                        name="model"
                        type="text"
                        class="form-control col-md-8"
                        placeholder="Iot"
                      />
                    </div>
                  </div> -->
                  <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >Lieu</span
                      >
                      <input
                        id="address"
                        [(ngModel)]="bornToEdit.address"
                        name="address"
                        type="text"
                        class="form-control col-md-8"
                        placeholder="231 rue Pierre et Marie"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >Ville</span
                      >
                      <input
                        id="city"
                        [(ngModel)]="bornToEdit.city"
                        name="city"
                        type="text"
                        class="form-control col-md-8"
                        placeholder="Toulouse"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >Code postal</span
                      >
                      <input
                        id="postalCode"
                        [(ngModel)]="bornToEdit.postalCode"
                        name="postalCode"
                        type="text"
                        class="form-control col-md-8"
                        placeholder="31670"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 mt-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-6"
                        id="basic-addon3"
                        >Evse Id</span
                      >
                      <input
                        id="evseId"
                        [(ngModel)]="bornToEdit.evseId"
                        name="evseId"
                        type="text"
                        class="form-control col-md-6"
                        placeholder="62E783BA"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 mt-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >Nom</span
                      >
                      <input
                        id="name"
                        [(ngModel)]="bornToEdit.name"
                        name="name"
                        type="text"
                        class="form-control col-md-8"
                        id="basic-url"
                        placeholder="EFUSION"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 mt-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-7"
                        id="basic-addon3"
                        >Prix du kW/h (en €)</span
                      >
                      <input
                        id="priceDetails"
                        [(ngModel)]="bornToEdit.price"
                        name="priceDetails"
                        type="number"
                        class="form-control col-md-5"
                        id="basic-url"
                        placeholder="0,35"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 mt-4">
                  <button
                    type="submit"
                    id="btnValid2"
                    class="btn buttonNormal text-light buttonGradient"
                  >
                    Enregister les modifications
                    <!-- routerLink="/pages-allBornes" -->
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
