<div class="container mt-5">
  <div class="row d-flex justify-content-between">
    <div class="col-md-6 p-3">
      <span>Paiement sécurisé par carte</span>

      <div class="card">
        <div class="card-header p-0">
          <h2 class="mb-0">
            <button
              class="btn btn-light btn-block text-left p-3 rounded-0"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <div class="d-flex align-items-center justify-content-between">
                <span>Cart bancaire</span>
                <div class="icons">
                  <img src="https://i.imgur.com/2ISgYja.png" width="30" />
                  <img src="https://i.imgur.com/W1vtnOV.png" width="30" />
                  <img src="https://i.imgur.com/35tC99g.png" width="30" />
                  <img src="https://i.imgur.com/2ISgYja.png" width="30" />
                </div>
              </div>
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <!-- <div class="card-body payment-card-body">
            <span class="font-weight-normal card-text"
              >Titulaire de la carte :
            </span>
            <div class="input">
              <i class="fa fa-credit-card"></i>
              <input
                type="text"
                class="form-control"
                placeholder="Alex Daniello"
                readonly
              />
            </div>
            <span class="font-weight-normal card-text">Numéro de carte : </span>
            <div class="input">
              <i class="fa fa-credit-card"></i>
              <input
                type="number"
                class="form-control"
                placeholder="0000 0000 0000 0000"
              />
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-md-6">
                <span class="font-weight-normal card-text"
                  >Date d'expiration :</span
                >
                <div class="input">
                  <i class="fa fa-calendar"></i>
                  <input type="text" class="form-control" placeholder="MM/YY" />
                </div>
              </div>

              <div class="col-md-6">
                <span class="font-weight-normal card-text"
                  >Cryptogramme visuel :</span
                >
                <div class="input">
                  <i class="fa fa-lock"></i>
                  <input type="text" class="form-control" placeholder="000" />
                </div>
              </div>
            </div>
          </div> -->
          <div id="card-element"></div>
        </div>
      </div>
    </div>

    <div class="col-md-6 p-3">
      <span>Récapitulatif</span>

      <div class="card">
        <div class="d-flex justify-content-between p-3">
          <div class="d-flex flex-column">
            <span>Abonnement mensuel <i class="bi bi-battery"></i></span>
          </div>

          <div class="mt-1">
            <sup class="super-price">€36.99</sup>
            <span class="super-month">/mois</span>
          </div>
        </div>

        <hr class="mt-0 line" />

        <div class="p-3">
          <div class="d-flex justify-content-between mb-2">
            <span>Réfferal</span>
            <span>-€2.00</span>
          </div>

          <div class="d-flex justify-content-between">
            <span>TVA <i class="fa fa-clock-o"></i></span>
            <span>-20%</span>
          </div>
        </div>

        <hr class="mt-0 line" />
        <div class="p-3">
          <button
            class="btn btn-primary btn-block free-button"
            (click)="handlePayment()"
          >
            Payer
          </button>

          <!-- <button class="btn btn-primary btn-block free-button">Payer</button> -->
        </div>
      </div>
    </div>
    <div class="container mt-4">
      <h5 class="card-title-tree mt-3">Facturation</h5>
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div class="input-group">
            <span class="input-group-text bg-white col-lg-4" id="basic-addon3"
              >Péridicité</span
            >
            <select
              class="form-select form-select-lg"
              aria-label=".form-select-lg example"
            >
              <option selected>
                <p>Facture Mensuelle</p>
              </option>
              <option value="1">Annuelle</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <!--  -->
        <div class="col-lg-4 col-md-4">
          <div class="input-group">
            <span class="input-group-text bg-white col-lg-4" id="basic-addon3"
              >IBAN</span
            >
            <input
              type="text"
              class="form-control col-md-8"
              id="basic-url"
              placeholder="FR76 1234 5678 90XX X65"
            />
          </div>
        </div>
        <!--  -->
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-4">
          <button
            type="submit"
            id="btnValid2"
            class="buttonNormal btn btn-secondary"
          >
            Enregistrer les modifications
          </button>
        </div>
        <div
          class="col-lg-2 col-md-2 d-flex justify-content-center d-flex align-items-center"
        >
          <a type="submit" id="" class="voirePlus btn btn-link">Annuler</a>
        </div>
      </div>
    </div>
  </div>
</div>
