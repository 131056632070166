<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Voitures</h3>
            <a
              class="button-Back d-flex justify-content-start"
              routerLink="/pages-parc"
            >
              <i id="iconBack" class="ri-arrow-drop-left-line"></i>
              <p class="btn-link button-Back">Revenir au parc automobile</p>
            </a>
            <!-- <div class="container mt-4">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <canvas
                        style="background-color: rgb(255, 255, 255)"
                        id="myChartCar"
                      >
                      </canvas>
                    </div>
                    <div class="col-md-12 canvadown">
                      <canvas id="myChartCarUser"> </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <canvas id="myChartCarRight"> </canvas>
                </div>
              </div>
            </div> -->
            <div class="row d-flex justify-content-between mt-5">
              <div class="col-lg-3 col-md-3">
                <div class="input-group">
                  <span
                    class="input-group-text bg-white col-lg-4"
                    id="basic-addon"
                    >Trier par</span
                  >
                  <select
                    class="form-select form-select-lg select-white"
                    aria-label=".form-select-lg example"
                    id="basic-addon3"
                  >
                    <option class="" selected><p>Tous</p></option>
                    <option value="1">En charge</option>
                    <option value="2">Sur batterie</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-5 ps-3" *ngIf="deleteOrEdite">
                <div class="flex-row bd-highlight mx-4">
                  <button
                    type="submit"
                    id="btnValid2"
                    class="btn btn-outline-primary mx-2"
                    (click)="gotoEditCar()"
                  >
                    Modifier
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#deleteCar"
                    type="submit"
                    id="btnValid2"
                    class="btn btn-outline-danger mx-2"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
              <!-- popup delete abonnement -->
              <div class="modal fade" id="deleteCar" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content modelBody">
                    <div class="modal-body py-4">
                      <h4 class="text-center fw-bold text-dark py-3">
                        Voulez-vous vraiment supprimer la sélection?
                      </h4>
                      <p class="text-center card-title-two py-3">
                        Impossible d'annuler l'opération
                      </p>
                      <div class="row d-flex justify-content-center">
                        <div class="col-md-4">
                          <button
                            type="submit"
                            id="btnValid2"
                            class="btn buttonNormal text-light buttonGradient"
                            (click)="deleteCar()"
                          >
                            supprimer la sélection
                          </button>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-4 d-flex justify-content-center">
                          <a
                            type="submit"
                            id=""
                            class="btn btn-link voirePlus"
                            data-bs-dismiss="modal"
                            #closeModal
                            >Annuler</a
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> <button type="button" class="btn btn-primary">Save changes</button></div> -->
                  </div>
                </div>
              </div>
              <!-- end popup delete abonnement -->
              <div class="col-lg-3 col-md-4">
                <button
                  type="submit"
                  id="btnValid2"
                  class="buttonNormal btn text-light buttonGradient"
                  routerLink="/pages-add-car"
                >
                  Ajouter une nouvelle voiture
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped mt-5">
                <thead>
                  <tr>
                    <th scope="col">
                      <label class="checkbox-wrap checkbox-primary">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Titulaire</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Marque</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Modèle</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal">Capacité de batterie</p>
                    </th>
                    <!-- <th scope="col">
                        <p class="tableNormal">Dernière charge</p>
                    </th> -->
                    <th scope="col">
                      <p class="tableNormal">Statut</p>
                    </th>
                    <th scope="col">
                      <p class="tableNormal"></p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="dataCars.length > 0; else noDataMessage">
                    <tr
                      *ngFor="let item of dataCars"
                      class="list-item"
                      [ngClass]="{
                        'list-to-edit': getCarEditions(item.id) === true
                      }"
                      (click)="onCheckBoxChangeOnRow(item.id)"
                      (mouseover)="showCarEditions(item.id)"
                      (mouseout)="hideCarEditions(item.id)"
                    >
                      <th scope="row">
                        <label class="checkbox-wrap checkbox-primary">
                          <input
                            type="checkbox"
                            (click)="onCheckboxChange(item.id, $event)"
                            [checked]="getBorneChecked(item.id)"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </th>
                      <td class="tableNormalTd">
                        {{ getFullNameProprietary(item.userId) }}
                      </td>
                      <td class="tableNormalTd">{{ item.brandName }}</td>
                      <td class="tableNormalTd">{{ item.model }}</td>
                      <td class="tableNormalTd">{{ item.power }} kW</td>
                      <!-- <td class="tableNormalTd">14/05/2023</td> -->
                      <td>
                        <span class="tableNormalTd" id="enCharge">{{
                          getChargeSessionStatus(item.id)
                        }}</span>
                      </td>
                      <td>
                        <div class="button-edit">
                          <a
                            role="button"
                            class="color-edit"
                            type="submit"
                            (click)="gotoQuickEditCar(item.id)"
                          >
                            <i class="ri-edit-box-fill"></i>
                          </a>
                          <a
                            role="button"
                            class="color-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteCar"
                            type="submit"
                            (click)="onCheckDeleteCar(item.id, $event)"
                          >
                            <i class="ri-delete-bin-5-line"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #noDataMessage>
                    <tr class="messageDisponibles">
                      <td colspan="6" class="text-center">
                        <p class="mt-4">Il n'y a pas de donnée disponible.</p>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
