<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section dashboard">
    <!-- nav -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="exploitation-tab"
          data-bs-toggle="tab"
          data-bs-target="#exploitation"
          type="button"
          role="tab"
          aria-controls="exploitation"
          aria-selected="true"
        >
          Exploitation
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="productions-tab"
          data-bs-toggle="tab"
          data-bs-target="#productions"
          type="button"
          role="tab"
          aria-controls="productions"
          aria-selected="false"
        >
          Productions
        </button>
      </li>
    </ul>
    <div class="tab-content pt-2" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="exploitation"
        role="tabpanel"
        aria-labelledby="exploitation-tab"
      >
        <div class="card border bordre-0">
          <div class="card-body pt-3">
            <h5 class="card-title">Exploitation</h5>
            <div class="row">
              <div class="col-lg-12">
                <div class="row d-flex justify-content-center">
                  <div class="col-xxl-4 col-lg-4">
                    <div class="card info-card sales-card" id="cardAll">
                      <div class="filter">
                        <a
                          routerLink="/pages-facture"
                          id="Consulter"
                          class="btn-link attribute p-3 voirePlus"
                          >Voir plus</a
                        >
                      </div>
                      <div class="card-body">
                        <h5 class="card-title textFactors" id="textFactor">
                          Factures
                        </h5>
                        <h5 class="card-title-two">Facture du mois en cours</h5>
                        <div class="d-flex align-items-center">
                          <div class="ps-3">
                            <h6 class="num-prix">
                              {{ moisEnCoursFacture }} €
                              <!-- {{ priceTotalDisplay }} € -->
                              <span class="spanTtc">TTC</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- stert -->
                  <div class="col-xxl-4 col-lg-4">
                    <div class="card info-card customers-card" id="cardAll">
                      <div class="filter">
                        <a
                          routerLink="/pages-consommation"
                          id="Consulter"
                          class="btn-link attribute p-3 voirePlus"
                          >Consulter</a
                        >
                      </div>
                      <div class="card-body">
                        <h5
                          class="card-title text-dark textFactors"
                          id="textFactor"
                        >
                          Consommation totale
                        </h5>
                        <div class="d-flex justify-content-start">
                          <h5 class="card-title-two mt-1">
                            Consommation totale
                          </h5>
                          <p class="num-prixs ms-2">
                            {{ globaleTotal.globalEnergy }} kw
                          </p>
                        </div>

                        <div class="row">
                          <div class="col-sm-6 derner px-1">
                            <div id="borderRight">
                              <h3 class="textBornText text-center">Dernier</h3>
                              <h6
                                class="text-center num-prix"
                                id="textActiveTextConso"
                              >
                                {{ lastConsomation
                                }}<span class="kwh-all"> kw/h</span>
                              </h6>
                            </div>
                          </div>
                          <div class="col-sm-6 derner">
                            <h3 class="textBornText text-center">Mensuel</h3>
                            <h6
                              id="textActiveTextConso"
                              class="text-start num-prix"
                            >
                              {{ testConso }}
                              <span class="kwh-all"> kw/h</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end -->
                  <div class="col-xxl-4 col-xl-4">
                    <div class="card info-card customers-card" id="cardAll">
                      <div class="filter">
                        <a
                          routerLink="/pages-parc"
                          id="Consulter"
                          class="btn-link attribute p-3 voirePlus"
                          >Consulter</a
                        >
                      </div>
                      <div class="card-body">
                        <h5
                          class="card-title text-dark textFactors"
                          id="textFactor"
                        >
                          Parc
                        </h5>
                        <div class="row py-1 mt-4">
                          <div class="col-sm-7 derner">
                            <div class="container" id="borderRight">
                              <div class="row">
                                <div class="col-12">
                                  <div>
                                    <h6 class="text-center num-prix">
                                      {{ dataUsers.length }}
                                    </h6>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <h3 class="textBornText text-center">
                                    Utilisateurs
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-5 derner">
                            <div class="container">
                              <div class="row">
                                <div class="col-12">
                                  <div>
                                    <h6 class="text-center num-prix">
                                      {{ totalCars }}
                                    </h6>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <h3 class="textBornText text-center">
                                    Voitures
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-5">
                    <div class="card">
                      <!-- <div class="filter">
                        <a
                          role="button"
                          class="btn-link voirePlus attribute p-3"
                          >Voir le détail</a
                        >
                      </div> -->
                      <div class="container">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="card-body">
                              <h5 class="card-title">Facturation</h5>
                              <p class="card-title-two">Année 2023</p>
                              <p class="text-dark-Mois">
                                Mois en cours
                                <span class="span-Mois">
                                  {{ moisEnCoursFacture }} €
                                  <!-- {{ priceTotalDisplay }} -->
                                </span>
                              </p>
                              <canvas
                                style="background-color: rgb(255, 255, 255)"
                                id="myChartMoisDashboardFacturation"
                              >
                              </canvas>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card-body">
                              <h5 class="card-title">Consommation</h5>
                              <p class="card-title-two">Année 2023</p>
                              <p class="text-dark-Mois">
                                Mois en cours
                                <span class="span-Mois">
                                  {{ testConso }} KW</span
                                >
                              </p>
                              <!-- <div id="reportsChart"></div> -->
                              <canvas
                                style="background-color: rgb(255, 255, 255)"
                                id="myChartMoisDashboard"
                              >
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="productions"
        role="tabpanel"
        aria-labelledby="productions-tab"
      >
        <div class="card border bordre-0">
          <div class="card-body pt-3">
            <h5 class="card-title">Productions</h5>
            <div class="row">
              <div class="col-xxl-4 col-xl-4">
                <div class="card info-card customers-card" id="cardAll">
                  <div class="filter">
                    <!-- <a
                      routerLink="/pages-car"
                      id="Consulter"
                      class="btn-link attribute p-3 voirePlus"
                      >Consulter</a
                    > -->
                  </div>
                  <div class="card-body">
                    <h5
                      class="card-title text-dark textFactors"
                      id="textFactor"
                    >
                      Chiffre d'affaires
                    </h5>
                    <div class="d-flex justify-content-start">
                      <h5 class="card-title-two mt-1">Total</h5>
                      <p class="num-prixs ms-2">{{ totalAmount }} €</p>
                    </div>
                    <div class="row py-1 mt-4">
                      <div class="col-sm-6 derner">
                        <div class="container" id="borderRight">
                          <div class="row">
                            <div class="col-12">
                              <div>
                                <h3 class="textBornText text-center">
                                  Bénéfices
                                </h3>
                                <p class="num-prixs ms-2 text-center">
                                  {{ beneficeTotal }} €
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 derner">
                        <div class="container">
                          <div class="row">
                            <div class="col-12">
                              <div>
                                <h3 class="textBornText text-center">
                                  Dépenses
                                </h3>
                                <p class="num-prixs ms-2 text-center">
                                  {{ depensesTotal }} €
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- stert -->
              <div class="col-xxl-4 col-lg-4">
                <div class="card info-card customers-card" id="cardAll">
                  <div class="filter">
                    <a
                      routerLink="/pages-consommation"
                      id="Consulter"
                      class="btn-link attribute p-3 voirePlus"
                      >Consulter</a
                    >
                  </div>
                  <div class="card-body">
                    <h5
                      class="card-title text-dark textFactors"
                      id="textFactor"
                    >
                      Consommation totale
                    </h5>
                    <div class="d-flex justify-content-start">
                      <h5 class="card-title-two mt-1">Consommation totale</h5>
                      <p class="num-prixs ms-2">
                        {{ globaleTotalProduction.globalEnergy }} kw
                      </p>
                    </div>

                    <div class="row">
                      <div class="col-sm-4 derner px-1">
                        <div id="borderRight">
                          <h3 class="textBornText text-center">Dernier</h3>
                          <h6
                            class="text-center num-prix"
                            id="textActiveTextConso"
                          >
                            {{ lastConsomationProduction
                            }}<span class="kwh-all"> kW/h</span>
                          </h6>
                        </div>
                      </div>
                      <div class="col-sm-8 derner">
                        <h3 class="textBornText text-center">Mensuel</h3>
                        <h6
                          id="textActiveTextConso"
                          class="text-start num-prix"
                        >
                          {{ moisEnCoursFactureProd }}
                          <span class="kwh-all"> kW/h</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end -->
              <!-- stert -->
              <div class="col-xxl-4 col-lg-4">
                <div class="card info-card customers-card" id="cardAll">
                  <div class="filter">
                    <a
                      routerLink="/pages-allBornes"
                      id="Consulter"
                      class="btn-link attribute p-3 voirePlus"
                      >Consulter</a
                    >
                  </div>
                  <div class="card-body">
                    <h5
                      class="card-title text-dark textFactors"
                      id="textFactor"
                    >
                      Parc des stations
                    </h5>
                    <div class="row">
                      <div class="col-sm-4 derner px-1">
                        <div id="borderRight">
                          <h3 class="textBornText text-center">Bornes</h3>
                          <h6
                            class="text-center num-prix"
                            id="textActiveTextConso"
                          >
                            {{ bornsList.length }}
                          </h6>
                        </div>
                      </div>

                      <div class="col-sm-8 derner">
                        <h3 class="textBornText text-center">Statut</h3>
                        <div
                          *ngFor="let status of statusList"
                          class="d-flex justify-content-between"
                        >
                          <!-- <span class="ms-1 statusBorn">{{
                            status.toLowerCase()
                          }}</span> -->
                          <span class="ms-1 statusBorn">{{
                            getStatusLabel(status)
                          }}</span>
                          <span class="ms-1 statusBorn">{{
                            statusCounts[status]
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end -->
              <div class="col-12">
                <div class="card">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">Chiffre d'affaires</h5>
                          <p class="card-title-two">Année 2023</p>
                          <p class="text-dark-Mois">
                            <span class="span-Mois">
                              Mois en cours {{ totalAmount }} €</span
                            >
                          </p>
                          <canvas
                            style="background-color: rgb(255, 255, 255)"
                            id="myChartChiffreAfaireHistoriChiffre"
                          >
                          </canvas>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">
                            Consommation de electricite
                          </h5>
                          <p class="card-title-two">Année 2023</p>
                          <p class="text-dark-Mois">
                            Mois en cours
                            <span class="span-Mois">
                              {{ moisEnCoursFactureProd }} kW/h</span
                            >
                          </p>
                          <!-- <div id="reportsChart"></div> -->
                          <canvas
                            style="background-color: rgb(255, 255, 255)"
                            id="myChartConsoElectricite"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                    <!-- ca start -->
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">Bénéfices</h5>
                          <p class="text-dark-Mois">
                            Mois en cours
                            <span class="span-Mois">
                              {{ beneficeTotal }}
                              €</span
                            >
                          </p>
                          <canvas
                            style="background-color: rgb(255, 255, 255)"
                            id="myChartBenefices"
                          >
                          </canvas>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">Dépenses</h5>
                          <p class="text-dark-Mois">
                            Mois en cours
                            <span class="span-Mois">{{ depensesTotal }} €</span>
                          </p>
                          <!-- <div id="reportsChart"></div> -->
                          <canvas
                            style="background-color: rgb(255, 255, 255)"
                            id="myChartDepanses"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                    <!-- ca end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end Paiement -->
      </div>
    </div>
    <!-- end nave -->
  </section>
</main>
