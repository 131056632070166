import { Component ,ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService }  from 'src/app/services/DataSharingService.service';
import { CognitoService } from './services/cognito.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'E-fusion';

  isUserLoggedIn!: boolean;

  constructor(private elementRef: ElementRef,  public  _router: Router, private dataSharingService : DataSharingService, private cognitoService : CognitoService) 
  { 
    this.dataSharingService.isUserLoggedIn.subscribe( value => {
      this.isUserLoggedIn = value;
  }); 
  }

  ngOnInit() {

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/main.js";
    this.elementRef.nativeElement.appendChild(s);
  }
}
