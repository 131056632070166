import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Charging, contries, enterprise, User, Vehicle } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


@Component({
  selector: 'app-pages-car',
  templateUrl: './pages-car.component.html',
  styleUrls: ['./pages-car.component.css']
})
export class PagesCarComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;


  chartData : any;
  powerData : Charging[] = [];
  pointChargin : Charging[] = [];
  dataDate : Charging[] = [];

  deleteOrEdite: boolean = false;
  counter = 0;
  dataCars: Vehicle[] = [];
  dataUsers: User[] = [];
  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  userOriginal: User = {
    "id": 0,
    "firstName": "",
    "lastName": "",
    "username": "",
    "email": "",
    "birthDate": new Date(),
    "phoneNumber": "",
    "code": "",
    "userPoolId": "",
    "currentChargeSessionSessionId": "",
    "stripeCustomerId": "",
    "hasPaymentMethod": "",
    "subscriptions": [],
    "addresses": [],
    "vehicules": [],
    "chargeSessions": [],
    "enabled": false,
    "clientNumber": "",
    "rfididentification": null,
  };

  enterprise: enterprise = {...this.enterpriseOriginal};

  listCarEditions: { idCar: any; isEdition: boolean; isChecked: boolean }[];
  bornToDeleteQuickId!: number;

  constructor(private userService: UserService, private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router, element: ElementRef) {
    this.listCarEditions = [];
    this.closeModal = element;
   }

  ngOnInit(): void {
    this.dataSharingService.currentEnterpriseData.subscribe(data=> {

      if(data && data.username !== "") {
        this.enterprise = data;
        this.getAllCars(data.id);
      } else {
        this.congitoService.getCurrentUser().then(result => {
          result.subscribe( user => {
            this.enterprise = user;
            this.getAllCars(user.id);
          })
        });
      }

    })

  }

  getAllCars(enterpriseId: number){
      this.userService.getAllUsersFromEnterprise(enterpriseId).subscribe((users: User[]) => {
        this.dataUsers = users;
        
        users.forEach(user => {
          this.dataCars = user.vehicules;
          this.listCarEditions = [];
          user.vehicules.forEach((car: Vehicle) => {
            const itemCarEdition = { idCar: car.id, isEdition: false, isChecked: false};
            this.listCarEditions.push(itemCarEdition);

            this.chartData = itemCarEdition;

      if (this.chartData!=null) {
        for(let i=0; i<this.chartData.length ; i++){
           console.log("chart" , this.chartData[i]);
          this.powerData.push(this.chartData[i].totalCost);
          this.pointChargin.push(this.chartData[i].chargingPoint);
          this.dataDate.push(this.chartData[i].stopDateTime);
        }

        // this.chartCarPrice(this.powerData, this.pointChargin, this.dataDate);
        // console.log(" chart car right", this.powerData, this.pointChargin, this.dataDate);
        
        // this.chartCar(this.powerData, this.pointChargin, this.dataDate );
        // console.log(" chart car", this.powerData, this.pointChargin, this.dataDate);

        // this.chartCarPowerUserCar(this.powerData, this.pointChargin, this.dataDate);
        // console.log(" chart car user down", this.powerData, this.pointChargin, this.dataDate);
        
       }
          })
        })
      })
  }

  showCarEditions(idCar: number) {
    this.listCarEditions.map(car => {if(car.idCar === idCar) car.isEdition = true});
  }
  hideCarEditions(idCar: number) {
    this.listCarEditions.map(car => {if(car.idCar === idCar) car.isEdition = false});
  }

  getCarEditions(idCar: number) {
    return this.listCarEditions.find(car => car.idCar === idCar)?.isEdition;
  }

  getFullNameProprietary(userId: number) {
    const user = this.dataUsers.find(user => user.id === userId);
    return user?.firstName + " " + user?.lastName;
  }

  getChargeSessionStatus(userId: number){
    const user = this.dataUsers.find(user => user.id === userId);
    if(user?.currentChargeSessionSessionId !== null && user?.currentChargeSessionSessionId !== undefined){
      return "En charge";
    } else {
      return "Sur batterie";
    }
  }

    deleteAndEdite() {
    this.deleteOrEdite = true
    console.log("true")
  }

    levedeleteAndEdite() {
    this.deleteOrEdite = false
    console.log("false")

  }

  onCheckboxChange(idBorne: number, e: MouseEvent) {
    e.stopPropagation();
    this.counter++;

    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
      this.levedeleteAndEdite();
    }
  }

  onCheckBoxChangeOnRow(idCar: number) {
    this.counter++;

    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
      this.levedeleteAndEdite();
    }
    this.listCarEditions.map(car => {if(car.idCar === idCar) car.isChecked = !car.isChecked});
  }
  getBorneChecked(idCar: number) {
    return this.listCarEditions.find(car => car.idCar === idCar)?.isChecked;
  }
  gotoQuickEditCar(idBorne: number) {
    this.router.navigate(['/pages-edit-car', idBorne]);
}
gotoEditCar() {
  const bornesChecked = this.listCarEditions.filter(car =>  car.isChecked == true);
  if(bornesChecked.length  === 1)
    this.router.navigate(['/pages-edit-car', bornesChecked[0].idCar]);
}
  onCheckDeleteCar(idCar: number, event: MouseEvent) {
    event.stopPropagation();
    this.bornToDeleteQuickId = idCar;
    this.listCarEditions.map(car => {if(car.idCar === idCar) car.isChecked = true});
    this.counter = this.listCarEditions.filter(car =>  car.isChecked == true).length;
    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
      this.levedeleteAndEdite();
    }
  }

  deleteCar() {
    if(this.bornToDeleteQuickId >= 0) {
      this.userService.deleteVehicle(this.bornToDeleteQuickId).subscribe(()=> {
        let userToDeleteVehicle = this.userOriginal;
        const vehicleToDelete = this.dataCars.find(vehicle => vehicle.id === this.bornToDeleteQuickId)
        this.dataUsers.forEach(user => {
          if(user.id === vehicleToDelete?.userId){
            userToDeleteVehicle = user;
          }
        });
        const vehicleToDeleteId = userToDeleteVehicle.vehicules.findIndex(vehicle => vehicle.id === this.bornToDeleteQuickId);
        userToDeleteVehicle.vehicules.splice(vehicleToDeleteId, 1);
        this.userService.updateUserForEnterprise(this.enterprise.id, userToDeleteVehicle?.id, userToDeleteVehicle).subscribe((userUpdated) => {
          if(userUpdated) {
            console.log("véhicule %d supprimé", this.bornToDeleteQuickId);
            this.getAllCars(this.enterprise.id);
            this.counter = this.listCarEditions.filter(car =>  car.isChecked == true).length;
            this.levedeleteAndEdite();
            this.closeModal.nativeElement.click();
          }
        });
      });
    } else {
      const carsChecked = this.listCarEditions.filter(car =>  car.isChecked == true);
      if(carsChecked.length > 0) {
        this.userService.deleteVehicle(carsChecked[0].idCar).subscribe(()=> {
          let userToDeleteVehicle = this.userOriginal;
          const vehicleToDelete = this.dataCars.find(vehicle => vehicle.id === carsChecked[0].idCar)
          this.dataUsers.forEach(user => {
            if(user.id === vehicleToDelete?.userId){
              userToDeleteVehicle = user;
            }
          });
          const vehicleToDeleteId = userToDeleteVehicle.vehicules.findIndex(vehicle => vehicle.id === carsChecked[0].idCar);
          userToDeleteVehicle.vehicules.splice(vehicleToDeleteId, 1);
          this.userService.updateUserForEnterprise(this.enterprise.id, userToDeleteVehicle?.id, userToDeleteVehicle).subscribe((userUpdated) => {
            if(userUpdated) {
              console.log("véhicule %d supprimé", carsChecked[0].idCar);
              this.getAllCars(this.enterprise.id);
              this.counter = this.listCarEditions.filter(car =>  car.isChecked == true).length;
              this.levedeleteAndEdite();
              this.closeModal.nativeElement.click();
            }
          });
        });
      }
    }
  }




  // 

  //   private chartCar(powerData:Charging[], pointChargin:Charging[], dataDate:Charging[]) {


  //   var myChart = new Chart("myChartCar", {
  //     type: 'bar',
  //     data: {
  //         labels: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],

  //         datasets: [
  //           {
              
  //             label: 'kwh',
  //             data: powerData,
  //             backgroundColor: [
  //                 'rgba(216, 54, 235, 0.9)',
  //             ],
  //          borderColor: [
  //           'rgba(54, 162, 235, 0.9)',
  //       ],
  //             borderWidth: 4
  //         },
  //         {
              
  //           label: 'price',
  //           data: pointChargin,
  //           backgroundColor: [
  //               'rgba(54, 162, 235, 0.9)',
  //           ],
  //        borderColor: [
  //         'rgba(54, 162, 235, 0.9)',
  //     ],
  //           borderWidth: 2
  //       },
  //       ]
  //     },
  //     options: {
  //         scales: {
  //             y: {
  //                 beginAtZero: true
  //             }
  //         }
  //     }
  // });



  // }



  // private chartCarPrice(powerData:Charging[], pointChargin:Charging[], dataDate:Charging[]){

  //   var myChart = new Chart("myChartCarRight", {
  //     type: 'pie',
  //     data: {
         
  //         labels: [
  //           'Red',
  //           'Blue',
  //           'Yellow'
  //         ],
  //         datasets: [{
  //           label: 'My First Dataset',
  //           data: [300, 50, 100],
  //           backgroundColor: [
  //             'rgb(255, 99, 132)',
  //             'rgb(54, 162, 235)',
  //             'rgb(255, 205, 86)'
  //           ],
  //            hoverOffset: 4
  //         }]
  //     },
  //     options: {
  //         scales: {
  //             y: {
  //                 beginAtZero: true
  //             }
              
  //         }
  //     }
  // });

  // }


  // private chartCarPowerUserCar(powerData:Charging[], pointChargin:Charging[], dataDate:Charging[]){

  //   var myChart = new Chart("myChartCarUser", {
  //     type: 'bar',
  //     data: {
     
        
  //         labels: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],

  //         datasets: [
  //           {
              
  //             label: 'kwh',
  //             data: powerData,
  //             backgroundColor: [
  //                 'rgba(216, 54, 235, 0.9)',
  //             ],
  //          borderColor: [
  //           'rgba(54, 162, 235, 0.9)',
  //       ],
  //             borderWidth: 4
  //         },
  //         {
              
  //           label: 'price',
  //           data: pointChargin,
  //           backgroundColor: [
  //               'rgba(54, 162, 235, 0.9)',
  //           ],
  //        borderColor: [
  //         'rgba(54, 162, 235, 0.9)',
  //     ],
  //           borderWidth: 2
  //       },
  //       ]
  //     },
  //     options: {
  //         scales: {
  //             y: {
  //                 beginAtZero: true
  //             }
  //         }
  //     }
  // });


  // }
}
