import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { contries, enterprise } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';


@Component({
  selector: 'app-pages-validation',
  templateUrl: './pages-validation.component.html',
  styleUrls: ['./pages-validation.component.css']
})
export class PagesValidationComponent implements OnInit {
  isUserLoggedIn ?: boolean;
  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  counter: number = 5;

  constructor(private router: Router, private userService: UserService, private cognitoService : CognitoService, private dataSharingService: DataSharingService) {}

  ngOnInit() {
    this.dataSharingService.currentEnterpriseData.subscribe(enterpriseData => this.enterprise = enterpriseData);
    console.log('get commercName')
      // do init at here for current route.
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
    }, 5000);  //5s

    // timer down from 5 to 1
    setInterval(() => {
      if(this.counter > 0) {
        this.counter--;
      }
    }, 1000);
      }

}
