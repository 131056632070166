/*You can use a BehaviorSubject for communicating between different
components throughout the app.
You can define a data sharing service containing the BehaviorSubject to
which you can subscribe and emit changes.

Define a data sharing service
*/

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { contries, enterprise, User } from '../models/user';

const enterpriseOriginal : enterprise = {
  "id": 0,
  "username": "",
  "userPoolId": "eu-west-3_7RRotegEb",
  "email": "",
  "commercialName": "",
  "siren": "",
  "siret": "",
  "intercomVAT": "",
  "phoneNumber": "",
  "city": "",
  "streetNumber": 0,
  "street": "",
  "complement": "",
  "zipCode": "",
  "region": "",
  "country": contries.France,
  "employees" :[]
}

const userOrginal : User = {
  "id": 0,
  "firstName": "",
  "lastName": "",
  "username": "",
  "email": "",
  "birthDate": undefined,
  "phoneNumber": "",
  "code": "",
  "userPoolId": "",
  "currentChargeSessionSessionId": "",
  "stripeCustomerId": "",
  "hasPaymentMethod": '',
  "subscriptions": [],
  "addresses": [],
  "vehicules": [],
  "chargeSessions": [],
  "enabled": false,
  "clientNumber": '',
  "rfididentification": null
}

@Injectable()
export class DataSharingService {
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentEnterprise: BehaviorSubject<enterprise> = new BehaviorSubject<enterprise>(enterpriseOriginal);
    public currentUserEnterprise: BehaviorSubject<User> = new BehaviorSubject<User>(userOrginal);
    public currentEnterpriseData = this.currentEnterprise.asObservable()
    public currentUserEnterpriseData = this.currentUserEnterprise.asObservable()

    constructor() { }

    updateCurrentEnterpriseData(value: enterprise) {
      this.currentEnterprise.next(value);
    }
}

/*Add the DataSharingService in your AppModule providers entry.

Next, import the DataSharingService in your <app-header> and in the
component where you perform the sign-in operation. In <app-header>
subscribe to the changes to isUserLoggedIn subject:
*/
