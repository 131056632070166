import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BadgesComponent } from './components/badges/badges.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { CardsComponent } from './components/cards/cards.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChartsApexchartsComponent } from './components/charts-apexcharts/charts-apexcharts.component';
import { ChartsChartjsComponent } from './components/charts-chartjs/charts-chartjs.component';
import { FormsEditorsComponent } from './components/forms-editors/forms-editors.component';
import { FormsElementsComponent } from './components/forms-elements/forms-elements.component';
import { FormsLayoutsComponent } from './components/forms-layouts/forms-layouts.component';
import { IconsBootstrapComponent } from './components/icons-bootstrap/icons-bootstrap.component';
import { IconsBoxiconsComponent } from './components/icons-boxicons/icons-boxicons.component';
import { IconsRemixComponent } from './components/icons-remix/icons-remix.component';
import { ListGroupComponent } from './components/list-group/list-group.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ProgressComponent } from './components/progress/progress.component';
import { SpinnersComponent } from './components/spinners/spinners.component';
import { TablesDataComponent } from './components/tables-data/tables-data.component';
import { TablesGeneralComponent } from './components/tables-general/tables-general.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TooltipsComponent } from './components/tooltips/tooltips.component';
import { PagesBlankComponent } from './pages/pages-blank/pages-blank.component';
import { PagesContactComponent } from './pages/pages-contact/pages-contact.component';
import { PagesError404Component } from './pages/pages-error404/pages-error404.component';
import { PagesFaqComponent } from './pages/pages-faq/pages-faq.component';
import { PagesLoginComponent } from './pages/pages-login/pages-login.component';
import { PagesRegisterComponent } from './pages/pages-register/pages-register.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { PagesConsommationComponent } from './pages/pages-consommation/pages-consommation.component';
import { PagesAbonnementComponent } from './pages/pages-abonnement/pages-abonnement.component';
import { PagesAddressComponent } from './pages/pages-address/pages-address.component';
import { PagesValidationComponent } from './pages/pages-validation/pages-validation.component';
import { PagesSubscriptionComponent } from './pages/pages-subscription/pages-subscription.component';
import { PagesCarComponent } from './pages/pages-car/pages-car.component';
import { PagesParcComponent } from './pages/pages-parc/pages-parc.component';
import { PagesFactureComponent } from './pages/pages-facture/pages-facture.component';
import { PagesAllBornsComponent } from './pages/pages-all-borns/pages-all-borns.component';
import { PagesAddBornsComponent } from './pages/pages-add-borns/pages-add-borns.component';
import { PageEditeAbonnementComponent } from './pages/page-edite-abonnement/page-edite-abonnement.component';
import { PageEditeCarsComponent } from './pages/page-edite-cars/page-edite-cars.component';
import { PageEditeBornComponent } from './pages/page-edite-born/page-edite-born.component';
import { PageAddAbonnementsComponent } from './pages/page-add-abonnements/page-add-abonnements.component';
import { PageAddCarComponent } from './pages/page-add-car/page-add-car.component';
import {PageConfBorneComponent} from "./pages/page-conf-borne/page-conf-borne.component";



const routes: Routes = [

  { path: '', pathMatch:'full', redirectTo: 'pages-login'},
  { path: 'dashboard', component: DashboardComponent },
  { path: 'alerts', component: AlertsComponent },
  { path: 'accordion', component: AccordionComponent },
  { path: 'badges', component: BadgesComponent },
  { path: 'breadcrumbs', component: BreadcrumbsComponent },
  { path: 'buttons', component: ButtonsComponent },
  { path: 'cards', component: CardsComponent },
  { path: 'carousel', component: CarouselComponent },
  { path: 'charts-apexcharts', component: ChartsApexchartsComponent },
  { path: 'charts-chartjs', component: ChartsChartjsComponent },
  { path: 'form-editors', component: FormsEditorsComponent },
  { path: 'form-elements', component: FormsElementsComponent },
  { path: 'form-layouts', component: FormsLayoutsComponent },
  { path: 'icons-bootstrap', component: IconsBootstrapComponent },
  { path: 'icons-boxicons', component: IconsBoxiconsComponent },
  { path: 'icons-remix', component: IconsRemixComponent },
  { path: 'list-group', component: ListGroupComponent },
  { path: 'modal', component: ModalComponent },
  { path: 'pagination', component: PaginationComponent },
  { path: 'progress', component: ProgressComponent },
  { path: 'spinners', component: SpinnersComponent },
  { path: 'tables-data', component: TablesDataComponent },
  { path: 'tables-general', component: TablesGeneralComponent },
  { path: 'tabs', component: TabsComponent },
  { path: 'tooltips', component: TooltipsComponent },
  { path: 'pages-blank', component: PagesBlankComponent },
  { path: 'pages-contact', component: PagesContactComponent },
  { path: 'pages-faq', component: PagesFaqComponent },
  { path: 'pages-login', component: PagesLoginComponent },
  { path: 'pages-register', component: PagesRegisterComponent },
  { path: 'user-profile', component: UsersProfileComponent },
  { path: 'pages-consommation', component: PagesConsommationComponent},
  { path: 'pages-abonnement', component: PagesAbonnementComponent},
  { path: 'pages-address', component: PagesAddressComponent},
  { path: 'pages-validation', component: PagesValidationComponent},
  { path: 'pages-subscription', component: PagesSubscriptionComponent},
  { path: 'pages-car', component: PagesCarComponent},
  { path: 'pages-parc', component: PagesParcComponent},
  { path: 'pages-facture', component: PagesFactureComponent},
  { path: 'pages-allBornes', component: PagesAllBornsComponent},
  { path: 'pages-add-borns', component: PagesAddBornsComponent},
  { path: 'pages-edit-abonnement/:id', component: PageEditeAbonnementComponent},
  { path: 'pages-edit-car/:id', component: PageEditeCarsComponent},
  { path: 'pages-edit-born/:id', component: PageEditeBornComponent},
  { path: 'pages-add-abonnement', component: PageAddAbonnementsComponent },
  { path: 'pages-add-car', component: PageAddCarComponent},
  { path:'page-conf-borne', component: PageConfBorneComponent},
  { path: '**', component: PagesError404Component},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
