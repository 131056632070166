import { Component, OnInit } from '@angular/core';
import {PaymentService} from '../../services/payment.service'
import { Stripe, StripeCardElement } from '@stripe/stripe-js';


@Component({
  selector: 'app-form-payment',
  templateUrl: './form-payment.component.html',
  styleUrls: ['./form-payment.component.css']
})
export class FormPaymentComponent implements OnInit {

  private stripe: Stripe | null = null;
  private cardElement: StripeCardElement | null = null;
  clientSecret: string = 'your_client_secret'; 


  constructor(private stripeServices: PaymentService) { 
  }

  async ngOnInit(): Promise<void> {
    // Initialize Stripe
    try {
      await this.stripeServices.initializeStripe('pk_live_51LrQUzLhiiUl9tyJ6aATNYMnCfbTugsERV2eWFLTIUFkENIFSfXRkVUFqm6YO1jwmq5fNv5tFojqJNjVis08cxLw00ILxxD1yS');
      this.stripe = this.stripeServices.getStripeInstance();
      // Create a card element
      if (this.stripe) {
        const elements = this.stripe.elements();
        this.cardElement = elements.create('card');
        this.cardElement.mount('#card-element'); // Mount to the HTML element with id 'card-element'
      }
    } catch (error) {
      console.error('Error initializing Stripe:', error);
    }
  }


  async handlePayment() {
    if (this.stripe && this.cardElement) {
      const result = await this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.cardElement
        }
      });

      if (result) {
        if (result.error) {
          console.error('Payment failed:', result.error);
        } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          console.log('Payment succeeded:', result.paymentIntent);
        }
      } else {
        console.error('Payment result is undefined.');
      }
    }
  }
}
