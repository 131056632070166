import { Injectable, OnInit } from '@angular/core';
import { Stripe, StripeElements, StripeCardElement, StripeCardElementOptions,loadStripe } from '@stripe/stripe-js';




@Injectable({
  providedIn: 'root'
})
export class PaymentService  {

  private stripe: Stripe | null = null;
  private elements: StripeElements | null = null;


  constructor() {
    
  }

  // Initialize Stripe with your public key
  async initializeStripe(publicKey: string): Promise<void> {
    try {
      this.stripe = await loadStripe(publicKey);
      if (this.stripe) {
        // Initialize Stripe elements
        this.elements = this.stripe.elements();
      } else {
        console.error('Stripe is null.');
      }
    } catch (error) {
      console.error('Error initializing Stripe:', error);
    }
  }

  getStripeInstance(): Stripe | null {
    return this.stripe;
  }

  getStripeElements(): StripeElements | null {
    return this.elements;
  }

}
