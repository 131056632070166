<main id="main" class="main">
  <app-log-out-component></app-log-out-component>

  <section class="section profile">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body pt-3">
            <div class="tab-content pt-2">
              <div
                class="tab-pane fade show active profile-overview"
                id="profile-overview"
              >
                <h5 class="card-title">Ajouter une borne</h5>
                <div class="text-center">
                  <div
                    id=""
                    *ngIf="isAlert"
                    [ngClass]="{
                      'alert alert-success text-center py-3': isAlertSuccess,
                      'alert alert-danger text-center': !isAlertSuccess
                    }"
                    role="alert"
                  >
                    {{ alertMSG }}
                    <button
                      (click)="closeAlert()"
                      type="button"
                      class="btn-close"
                      data-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>

                <div class="container mt-4">
                  <form [formGroup]="addBornForm" (ngSubmit)="newBorn()">
                    <h5 class="card-title mt-3">Borne</h5>
                    <div class="row">
                      <div class="col-lg-4 col-md-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-4"
                            id="basic-addon3"
                            >Lieu</span
                          >
                          <input
                            formControlName="address"
                            name="address"
                            type="text"
                            class="form-control col-md-8"
                            id="basic-url"
                            placeholder="231 rue Pierre et Marie"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-4"
                            id="basic-addon3"
                            >Ville</span
                          >
                          <input
                            formControlName="city"
                            name="city"
                            type="text"
                            class="form-control col-md-8"
                            id="basic-url"
                            placeholder="Toulouse"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-4"
                            id="basic-addon3"
                            >Country</span
                          >
                          <input
                            formControlName="country"
                            name="country"
                            type="text"
                            class="form-control col-md-8"
                            id="basic-url"
                            placeholder="France"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 mt-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-4"
                            id="basic-addon3"
                            >Code postal</span
                          >
                          <input
                            formControlName="postalCode"
                            name="postalCode"
                            type="text"
                            class="form-control col-md-8"
                            id="basic-url"
                            placeholder="31670"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 mt-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-4"
                            id="basic-addon3"
                            >Evse Id</span
                          >
                          <input
                            formControlName="evseId"
                            name="evseId"
                            type="text"
                            class="form-control col-md-8"
                            id="basic-url"
                            placeholder="62E783BA"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 mt-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-4"
                            id="basic-addon3"
                            >Nom</span
                          >
                          <input
                            formControlName="name"
                            name="name"
                            type="text"
                            class="form-control col-md-8"
                            id="basic-url"
                            placeholder="EFUSION"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 mt-4">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-white col-lg-7"
                            id="basic-addon3"
                            >Prix du kW/h (en €)</span
                          >
                          <input
                            formControlName="price"
                            name="price"
                            type="number"
                            class="form-control col-md-5"
                            id="basic-url"
                            placeholder="0,35"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <!-- routerLink="/pages-allBornes" -->
                      <button
                        type="submit"
                        id="btnValid2"
                        class="btn text-light buttonGradient"
                      >
                        Ajouter la borne
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
