<main id="main" class="main">
  <app-log-out-component></app-log-out-component>

  <section class="section profile">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body pt-3">
            <h5 class="card-title">Ajouter une voiture</h5>
            <div class="text-center">
              <!-- <div *ngIf="isAlert" class="alert alert-danger" role="alert">
                {{ alertMSG }}
                <button
                  (click)="closeAlert()"
                  type="button"
                  class="btn-close"
                  data-dismiss="alert"
                  aria-label="Close"
                  ></button>
              </div> -->
              <div
                *ngIf="isAlert"
                [ngClass]="{
                  'alert alert-success alert-dismissible text-center py-3':
                    isAlertSuccess,
                  'alert alert-danger alert-dismissible': !isAlertSuccess
                }"
                role="alert"
              >
                {{ alertMSG }}
                <button
                  (click)="closeAlert()"
                  type="button"
                  class="btn-close"
                  data-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div class="container mt-4">
              <form [formGroup]="addCarForm" (ngSubmit)="newCar()">
                <h5 class="card-title mt-3">Voiture</h5>
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon"
                        >Titulaire</span
                      >
                      <select
                        class="form-select form-select-lg"
                        formControlName="formUserId"
                        aria-label=".form-select-lg example"
                        id="basic-addon3"
                      >
                        <option
                          *ngFor="let user of listUsers"
                          [value]="user.id"
                        >
                          {{ user.lastName }} {{ user.firstName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >Marque</span
                      >
                      <select
                        class="form-select form-select-lg"
                        formControlName="formBrandName"
                        aria-label=".form-select-lg example"
                        id="basic-addon3"
                      >
                        <option
                          *ngFor="let brand of listCarBrands"
                          [value]="brand"
                        >
                          {{ brand }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-4"
                        id="basic-addon3"
                        >Modèle</span
                      >
                      <input
                        type="text"
                        formControlName="formModel"
                        class="form-control col-md-8"
                        id="basic-url"
                        placeholder="Modèle S"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 mt-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-8"
                        id="basic-addon3"
                        >Puissance en Kw</span
                      >
                      <input
                        type="number"
                        formControlName="formPower"
                        class="form-control col-md-4"
                        id="basic-url"
                        placeholder="200"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 mt-4">
                    <div class="input-group">
                      <span
                        class="input-group-text bg-white col-lg-6"
                        id="basic-addon3"
                        >Num. d'immatrs</span
                      >
                      <input
                        type="text"
                        formControlName="formRegistrationNumber"
                        class="form-control col-md-6"
                        id="basic-url"
                        placeholder="ABC123DEF"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 mt-4">
                  <button
                    type="submit"
                    id="btnValid2"
                    class="buttonNormal btn text-light buttonGradient"
                  >
                    Ajouter la voiture
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
