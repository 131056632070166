import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Bornes, ChargeStatus, contries, enterprise } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-pages-all-borns',
  templateUrl: './pages-all-borns.component.html',
  styleUrls: ['./pages-all-borns.component.css']
})
export class PagesAllBornsComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;
  deleteOrEdite: boolean = false;
  counter = 0;

  isAlert: boolean = false;
  isAlertSuccess: boolean = false;
  alertMSG: string = '';


  dataBorns : Bornes[] = [];
  id :number | any;

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};
  public ChargeStatus = ChargeStatus;
  selectedStatus: ChargeStatus | null = null;
  filteredDataBorns: Bornes[] = [];

  listBornEditions: { idBorne: any; isEdition: boolean; isChecked: boolean }[];
  bornToDeleteQuickId!: number;

  constructor(private userService: UserService, private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router, element: ElementRef) {
    this.listBornEditions = [];
    this.closeModal = element;
  }

  filterChargingPointsByStatus(status: ChargeStatus | null): Bornes[] {
    if (status === null) {
      return this.dataBorns; 
    } else {
      return this.dataBorns.filter((item) => item.status === status);
    }
  }

  applyStatusFilter() {
    if (this.selectedStatus === null) {
      this.filteredDataBorns = this.dataBorns; 
    } else {
      this.filteredDataBorns = this.filterChargingPointsByStatus(this.selectedStatus);
    }
  }
  
  

  getAllUsers(id: number){
    this.userService.getEnterpriseChargingPoints(id)
    .subscribe((data: Bornes[]) => {
      console.log(data, "all bornes");

      this.dataBorns = data;
      this.dataBorns.forEach(borne => {
        const itemBorneEdition = { idBorne: borne.id, isEdition: false, isChecked: false};
        this.listBornEditions.push(itemBorneEdition);
      })
    })
  }
  getStatusClass(status: ChargeStatus): string {
    switch (status) {
      case ChargeStatus.Available:
        return 'Disponible';
      case ChargeStatus.Preparing:
        return 'Preparation';
      case ChargeStatus.Charging:
        return 'Charge';
        case ChargeStatus.Unavailable:
        return 'Indisponible';
      default:
        return 'Défaut';
    }
  }

  toggleStatus(item: Bornes) {
    if (item.status === ChargeStatus.Available) {
      item.status = ChargeStatus.Unavailable;
    } else {
      item.status = ChargeStatus.Available;
    }
    this.userService.updateEnterpriseChargingPoint(this.enterprise.id,item.id,item).subscribe(() => {
      console.log(`Status for charging point ${item.id} updated to ${item.status}`);
    });
  }
  
  ngOnInit(): void {
      //  this.selectedStatus = ChargeStatus.Available;
      this.selectedStatus = null;
    this.dataSharingService.currentEnterpriseData.subscribe(data=> {
      if(data && data.username !== "") {
        this.enterprise = data;
        this.getAllUsers(data.id);
      } else {
        this.congitoService.getCurrentUser().then(result => {
          result.subscribe( user => {
            this.enterprise = user;
            this.getAllUsers(user.id);
          })
        });
      }

    })
  }

  goToAddBorne() {
    this.dataSharingService.updateCurrentEnterpriseData(this.enterprise);
    this.router.navigate(['/pages-add-borns']);
  }

  showBorneEditions(idBorne: number) {
    this.listBornEditions.map(borne => {if(borne.idBorne === idBorne) borne.isEdition = true});
  }
  hideBorneEditions(idBorne: number) {
    this.listBornEditions.map(borne => {if(borne.idBorne === idBorne) borne.isEdition = false});
  }

  getBorneEditions(idBorne: number) {
    return this.listBornEditions.find(borne => borne.idBorne === idBorne)?.isEdition;
  }

    deleteAndEdite() {
    this.deleteOrEdite = true
      /*this.alertMSGf('supprimé prise en compte');
      this.isAlertSuccess = true;*/
    console.log("true")
  }

    levedeleteAndEdite() {
    this.deleteOrEdite = false
    console.log("false")

  }

  gotoEditBorne() {
    const bornesChecked = this.listBornEditions.filter(borne =>  borne.isChecked == true);
    if(bornesChecked.length  === 1)
      this.router.navigate(['/pages-edit-born', bornesChecked[0].idBorne]);
  }

  gotoConfBorne(){
    const bornesChecked = this.listBornEditions.filter(borne =>  borne.isChecked == true);
    if(bornesChecked.length  === 1) {
      let idBorne = bornesChecked[0].idBorne
      localStorage.setItem("bor",String(idBorne));
      localStorage.setItem("ent",String(this.enterprise.id));
      this.router.navigate(['/page-conf-borne']);
    }
  }

  gotoQuickEditBorne(idBorne: number) {
      this.router.navigate(['/pages-edit-born', idBorne]);
  }

  gotoQuickConfBorne(idBorne: number) {
    localStorage.setItem("bor",String(idBorne));
    localStorage.setItem("ent",String(this.enterprise.id));
    this.router.navigate(['/page-conf-borne']);
  }
  onCheckboxChange(idBorne: number, e: MouseEvent) {
    e.stopPropagation();
    this.counter++;

    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
      this.levedeleteAndEdite();
    }
    this.listBornEditions.map(borne => {if(borne.idBorne === idBorne) borne.isChecked = !borne.isChecked});
  }

  onCheckBoxChangeOnRow(idBorne: number) {
    this.counter++;

    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
      this.levedeleteAndEdite();
    }
    this.listBornEditions.map(borne => {if(borne.idBorne === idBorne) borne.isChecked = !borne.isChecked});
  }
  getBorneChecked(idBorne: number) {
    return this.listBornEditions.find(borne => borne.idBorne === idBorne)?.isChecked;
  }

  onCheckDeleteBorne(idBorne: number, event: MouseEvent) {
    event.stopPropagation();
    this.bornToDeleteQuickId = idBorne;
    this.listBornEditions.map(borne => {if(borne.idBorne === idBorne) borne.isChecked = true});
    this.counter = this.listBornEditions.filter(borne =>  borne.isChecked == true).length;
    if (this.counter % 2 === 1) {
      this.deleteAndEdite();
    } else {
      this.levedeleteAndEdite();
    }
  }

  deleteBorne() {
    if(this.bornToDeleteQuickId >= 0) {
      this.userService.deleteEnterpriseChargingPoint(this.enterprise.id, this.bornToDeleteQuickId).subscribe(()=> {
        console.log("borne %d supprimé", this.bornToDeleteQuickId);
        this.getAllUsers(this.enterprise.id);
        this.counter = this.listBornEditions.filter(borne =>  borne.isChecked == true).length;
        this.levedeleteAndEdite();
        this.closeModal.nativeElement.click();
      });
    } else {
      const bornesChecked = this.listBornEditions.filter(borne =>  borne.isChecked == true);
      if(bornesChecked.length > 0) {
        this.userService.deleteEnterpriseChargingPoint(this.enterprise.id, bornesChecked[0].idBorne).subscribe(()=> {
          console.log("borne %d supprimé", bornesChecked[0].idBorne);
          this.getAllUsers(this.enterprise.id);
          this.counter = this.listBornEditions.filter(borne =>  borne.isChecked == true).length;
          this.levedeleteAndEdite();
          this.closeModal.nativeElement.click();
        });
      }
    }
  }



  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }
}
