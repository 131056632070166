import { Component, OnInit } from '@angular/core';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { contries, enterprise, User, Vehicle } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-page-edite-cars',
  templateUrl: './page-edite-cars.component.html',
  styleUrls: ['./page-edite-cars.component.css']
})
export class PageEditeCarsComponent implements OnInit {
  listCarBrands : string[] = ["PORSCHE", "VW", "HYUNDAI", "PEUGEOT", "SEAT", "NISSAN", "CUPRA", "DS", "CITROEN", "JAGUAR", "MINI", "MG", "LEXUS", "KIA", "HONDA", "VOLVO", "SKODA", "FIAT", "RENAULT", "AUDI", "FORD", "DACIA", "MAZDA", "OPEL", "BMW", "TESLA", "TOYOTA"];
  idCar :number | any;
  listUsers: User[] = [];
  carToEdit: Vehicle = {
    "id": 0,
    "userId": 0,
    "brandName": "",
    "image": "",
    "model": "",
    "power": 0,
    "offer": null,
    "registrationNumber": "",
  }

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }
  userOriginal: User = {
    "id": 0,
    "firstName": "",
    "lastName": "",
    "username": "",
    "email":"",
    "birthDate": new Date(),
    "phoneNumber":"",
    "code":"",
    "userPoolId":"",
    "currentChargeSessionSessionId": "",
    "stripeCustomerId": "",
    "hasPaymentMethod": "",
    "subscriptions": [],
    "addresses": [],
    "vehicules": [],
    "chargeSessions": [],
    "enabled": false,
    "clientNumber": "",
    "rfididentification": null,
  };
  enterprise: enterprise = {...this.enterpriseOriginal};

  constructor(private userService: UserService, private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router, private route: ActivatedRoute) {

  }


  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idCar = Number(params.get('id'));
      if(this.idCar != null) {
        this.dataSharingService.currentEnterpriseData.subscribe(data=> {
          if(data && data.username !== "") {
            this.enterprise = data;
            this.getCarDetails(this.enterprise.id);
          } else {
            this.congitoService.getCurrentUser().then(result => {
              result.subscribe( enterprise => {
                this.enterprise = enterprise;
                this.getCarDetails(this.enterprise.id);
              })
            });
          }
        })
      }
    })
  }

  getCarDetails(enterpriseId: number) {
    this.userService.getAllUsersFromEnterprise(enterpriseId).subscribe((users: User[])=> {
      users.forEach(user => {
        this.listUsers = users;
        user.vehicules.forEach((car: Vehicle) => {
          if(car.id === this.idCar) {
            this.carToEdit = car;
          }
        })
      })
    })
  }

  getFullNameProprietary() {
    const user = this.listUsers.find(user => user.id === this.carToEdit.userId);
    return user?.firstName + " " + user?.lastName;
  }

  getChargeSessionStatus(){
    const user = this.listUsers.find(user => user.id === this.carToEdit.userId);
    if(user?.currentChargeSessionSessionId !== null && user?.currentChargeSessionSessionId !== undefined){
      return "En charge";
    } else {
      return "Sur batterie";
    }
  }

  updateCar(){
    this.userService.updateVehicle(this.carToEdit).subscribe((vehicleUpdated: Vehicle) => {
      if(vehicleUpdated){
        let userToUpdate = this.userOriginal;
        this.listUsers.forEach(user => {
          if(user.id === this.carToEdit.userId){
            userToUpdate = user;
          }
        });
        userToUpdate.vehicules.map(vehicule => {
          if(vehicule.id === this.idCar) {
            vehicule = this.carToEdit;
          }
          return vehicule;
        });
        this.userService.updateUserForEnterprise(this.enterprise.id, userToUpdate.id, userToUpdate ).subscribe((userUpdated:User)=> {
            if(userUpdated){
              this.router.navigate(['/pages-car'])
            }
        })
      }
    })
  }
}
