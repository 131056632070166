import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icons-boxicons',
  templateUrl: './icons-boxicons.component.html',
  styleUrls: ['./icons-boxicons.component.css']
})
export class IconsBoxiconsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
