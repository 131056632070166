import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BrandName, contries, enterprise, User, Vehicle, VehicleToCreate } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-page-add-car',
  templateUrl: './page-add-car.component.html',
  styleUrls: ['./page-add-car.component.css']
})
export class PageAddCarComponent implements OnInit {

  isAlertSuccess: boolean = false;
  isAlert: boolean = false;
  alertMSG: string = '';

  listUsers: User[] = [];
  listCarBrands : string[] = ["PORSCHE", "VW", "HYUNDAI", "PEUGEOT", "SEAT", "NISSAN", "CUPRA", "DS", "CITROEN", "JAGUAR", "MINI", "MG", "LEXUS", "KIA", "HONDA", "VOLVO", "SKODA", "FIAT", "RENAULT", "AUDI", "FORD", "DACIA", "MAZDA", "OPEL", "BMW", "TESLA", "TOYOTA"];
  addCarForm : FormGroup = new FormGroup({
    formUserId: new FormControl(0, []),
    formBrandName: new FormControl('', []),
    formModel: new FormControl('', []),
    formPower: new FormControl(0, []),
    formRegistrationNumber: new FormControl('', [])
  });

  carOrigin: VehicleToCreate = {
    "userId": 0,
    "brandName": "",
    "image": "",
    "model": "",
    "power": 0,
    "offer": null,
    "registrationNumber": "",
  }

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  userOriginal: User = {
    "id": 0,
    "firstName": "",
    "lastName": "",
    "username": "",
    "email":"",
    "birthDate": new Date(),
    "phoneNumber":"",
    "code":"",
    "userPoolId":"",
    "currentChargeSessionSessionId": "",
    "stripeCustomerId": "",
    "hasPaymentMethod": "",
    "subscriptions": [],
    "addresses": [],
    "vehicules": [],
    "chargeSessions": [],
    "enabled": false,
    "clientNumber": "",
    "rfididentification": null,
  };
  enterprise: enterprise = {...this.enterpriseOriginal};
  carToCreate: VehicleToCreate = {...this.carOrigin};
  constructor(private userService: UserService, private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router) {

  }

  ngOnInit(): void {
    this.dataSharingService.currentEnterpriseData.subscribe(data=> {
      if(data && data.username !== "") {
        this.enterprise = data;
        this.getUsers(this.enterprise.id);
      } else {
        this.congitoService.getCurrentUser().then(result => {
          result.subscribe( enterprise => {
            this.enterprise = enterprise;
            this.getUsers(this.enterprise.id);
          })
        });
      }

    })

  }

  getUsers (enterpriseId: number) {
    this.userService.getAllUsersFromEnterprise(enterpriseId).subscribe((users: User[])=> {
      this.listUsers = users;
    })
  }

  newCar() {
    this.carToCreate.userId = +this.addCarForm.get('formUserId')?.value;
    this.carToCreate.brandName = this.addCarForm.get('formBrandName')?.value;
    this.carToCreate.model = this.addCarForm.get('formModel')?.value;
    this.carToCreate.power = this.addCarForm.get('formPower')?.value;
    this.carToCreate.registrationNumber = this.addCarForm.get('formRegistrationNumber')?.value;
    this.userService.createVehicleForUser(this.carToCreate).subscribe((data: Vehicle)=> {
      if (data) {
        let userToUpdate = this.userOriginal;
        this.listUsers.forEach(user => {
          if(user.id === data.userId){
            userToUpdate = user;
          }
          });
        userToUpdate.vehicules.push(data);
        this.userService.updateUserForEnterprise(this.enterprise.id, userToUpdate?.id, userToUpdate).subscribe((userUpdated) => {
          if(userUpdated) {
            this.alertMSGf('Voiture ajoutée avec succès');
            this.isAlertSuccess = true;
            setTimeout(() => {
              this.router.navigate(['/pages-car']);
            }, 3000); 
          }
             },
             (error) => {
              console.error(error);
              this.alertMSGf('Essayez plus tard'); 
              this.isAlertSuccess = false;

            }
            );
         }
             },
    (error) => {
      console.error(error);
      this.alertMSGf('Remplissez les champs vides'); 
      this.isAlertSuccess = false;

      
    }) 
  }



  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }
}
