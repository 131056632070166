<main id="main" class="main">
    <div class="pagetitle">
       <h1>Carousel</h1>
       <nav>
          <ol class="breadcrumb">
             <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
             <li class="breadcrumb-item">Components</li>
             <li class="breadcrumb-item active">Carousel</li>
          </ol>
       </nav>
    </div>
    <section class="section">
       <div class="row">
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Slides only</h5>
                   <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-inner">
                         <div class="carousel-item active"> <img src="assets/img/slides-1.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-2.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-3.jpg" class="d-block w-100" alt="..."></div>
                      </div>
                   </div>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With indicators</h5>
                   <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-indicators"> <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button> <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button> <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button></div>
                      <div class="carousel-inner">
                         <div class="carousel-item active"> <img src="assets/img/slides-1.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-2.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-3.jpg" class="d-block w-100" alt="..."></div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="visually-hidden">Previous</span> </button> <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="visually-hidden">Next</span> </button>
                   </div>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Carousel with fade transition</h5>
                   <p>Add <code>.carousel-fade</code> to your carousel to animate slides with a fade transition instead of a slide.</p>
                   <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                      <div class="carousel-inner">
                         <div class="carousel-item active"> <img src="assets/img/slides-1.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-2.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-3.jpg" class="d-block w-100" alt="..."></div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="visually-hidden">Previous</span> </button> <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="visually-hidden">Next</span> </button>
                   </div>
                </div>
             </div>
          </div>
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With controls</h5>
                   <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-inner">
                         <div class="carousel-item active"> <img src="assets/img/slides-1.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-2.jpg" class="d-block w-100" alt="..."></div>
                         <div class="carousel-item"> <img src="assets/img/slides-3.jpg" class="d-block w-100" alt="..."></div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="visually-hidden">Previous</span> </button> <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="visually-hidden">Next</span> </button>
                   </div>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">With captions</h5>
                   <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-indicators"> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button></div>
                      <div class="carousel-inner">
                         <div class="carousel-item active">
                            <img src="assets/img/slides-1.jpg" class="d-block w-100" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                               <h5>First slide label</h5>
                               <p>Some representative placeholder content for the first slide.</p>
                            </div>
                         </div>
                         <div class="carousel-item">
                            <img src="assets/img/slides-2.jpg" class="d-block w-100" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                               <h5>Second slide label</h5>
                               <p>Some representative placeholder content for the second slide.</p>
                            </div>
                         </div>
                         <div class="carousel-item">
                            <img src="assets/img/slides-3.jpg" class="d-block w-100" alt="...">
                            <div class="carousel-caption d-none d-md-block">
                               <h5>Third slide label</h5>
                               <p>Some representative placeholder content for the third slide.</p>
                            </div>
                         </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="visually-hidden">Previous</span> </button> <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="visually-hidden">Next</span> </button>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>