import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  isUserLoggedIn ?: boolean;
    
  constructor(private router:Router, private cognitoService: CognitoService, private dataSharingService : DataSharingService){
      this.dataSharingService.isUserLoggedIn.subscribe( value => {
          this.isUserLoggedIn = value;
      }); 
  }



  singOutWithCognito() {
    this.cognitoService.signOut().then(() => {
      this.dataSharingService.isUserLoggedIn.next(false);
      this.router.navigate(['/pages-login']);
      //location.reload()
    });
  }


  // constructor() { }

  ngOnInit(): void {
  }

}
