<main id="main" class="main">
    <app-log-out-component></app-log-out-component>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Parc automobile</h3>
                        <!-- <p>This is an examle page with no contrnt.</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Bornes</h3>
                        <div class="d-flex flex-row">
                            <h3 class="cartFacture">{{ bornsList.length }}</h3>
                            <div class="ps-3">
                                <p class="textBornText">Enregistrée(s)</p>
                                <p class="card-title-two">{{getBornesAvailable()}} en cours d'utilisation</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <button type="submit" id="btnValid2" class="buttonNormal btn text-light buttonGradient" routerLink="/pages-allBornes">
                  Voir toutes les bornes
                </button>
                            </div>
                            <div class="col-lg-6 col-md-4 d-flex justify-content-center d-flex align-items-center">
                                <a type="submit" id="newBorne" class="voirePlus btn btn-link" routerLink="/pages-add-borns">Ajouter une nouvelle borne</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Voitures</h3>
            <div class="d-flex flex-row">
              <h3 class="cartFacture">{{ carsList.length }}</h3>
              <div class="ps-3">
                <p class="textBornText">Enregistrée(s)</p>
                <p class="card-title-two">0 voiture(s) en charge</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <button
                  type="submit"
                  id="btnValid2"
                  class="buttonNormal btn text-light buttonGradient"
                  routerLink="/pages-car"
                >
                  Voir toutes les voitures
                </button>
              </div>
              <div
                class="col-lg-6 col-md-4 d-flex justify-content-center d-flex align-items-center"
              >
                <a
                  type="submit"
                  id="newBorne"
                  class="voirePlus btn btn-link"
                  routerLink="/pages-add-car"
                  >Ajouter une nouvelle voiture</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
