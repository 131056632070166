<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section profile">
    <form (ngSubmit)="updateUser()">
      <div class="row">
        <div class="col-xl-12">
          <!-- card tab -->
          <div class="card">
            <div class="card-body">
              <!-- <h5 class="card-title">Default Tabs</h5> -->
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Compte
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Paiement
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Changer mot de passe
                  </button>
                </li>
              </ul>
              <div class="tab-content pt-2" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="card border bordre-0">
                    <div class="card-body pt-3">
                      <h5 class="card-title">Compte</h5>

                      <div
                        *ngIf="isAlert"
                        [ngClass]="{
                          'alert alert-success text-center py-3':
                            isAlertSuccess,
                          'alert alert-danger': !isAlertSuccess
                        }"
                        role="alert"
                      >
                        {{ alertMSG }}
                        <button
                          (click)="closeAlert()"
                          type="button"
                          class="btn-close"
                          data-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="container">
                        <h5 class="card-title-tree mt-3">Identifiant</h5>
                        <div class="row">
                          <div class="col-lg-4 col-md-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Nom</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="commercialName"
                                [(ngModel)]="enterprise.commercialName"
                                name="commercialName"
                                placeholder="User"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Email</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="email"
                                [(ngModel)]="enterprise.email"
                                name="email"
                                placeholder="efusion@efusion.com"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container mt-4">
                        <h5 class="card-title-tree mt-3">Information</h5>
                        <div class="row">
                          <div class="col-lg-4 col-md-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Adresse</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="street"
                                [(ngModel)]="enterprise.street"
                                name="street"
                                placeholder="231 rue Pierre et Marie"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >N°Rue</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="streetNumber"
                                [(ngModel)]="enterprise.streetNumber"
                                name="streetNumber"
                                placeholder="231 "
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Ville</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="city"
                                [(ngModel)]="enterprise.city"
                                name="city"
                                placeholder="Toulouse"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 mt-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Code postal</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="zipCode"
                                [(ngModel)]="enterprise.zipCode"
                                name="zipCode"
                                placeholder="31670"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 mt-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Téléphone</span
                              >
                              <input
                                type="number"
                                class="form-control basic-url col-md-8"
                                id="phoneNumber"
                                [(ngModel)]="enterprise.phoneNumber"
                                name="phoneNumber"
                                placeholder="+33.6.01.23.45.67"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 mt-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Siret</span
                              >
                              <input
                                type="text"
                                class="form-control basic-url col-md-8"
                                id="siret"
                                [(ngModel)]="enterprise.siret"
                                name="siret"
                                placeholder="XXXXXXX-XXXXXX"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 mt-4">
                            <div class="input-group">
                              <span
                                class="input-group-text bg-white col-lg-4"
                                id="basic-addon3"
                                >Prix kW/h</span
                              >
                              <input
                                type="text"
                                class="form-control col-md-8"
                                id="basic-url"
                                placeholder="0,35 € "
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-4 col-md-4">
                              <div class="input-group">
                                <span
                                  class="input-group-text bg-white col-lg-4"
                                  id="basic-addon3"
                                  >Pays</span
                                >
                                <select
                                  id="country"
                                  [(ngModel)]="enterprise.country"
                                  name="country"
                                  class="form-select form-select-lg"
                                  aria-label=".form-select-lg example"
                                >
                                  <option
                                    *ngFor="
                                      let country of contrys;
                                      let i = index
                                    "
                                    [value]="i"
                                  >
                                    {{ country }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="container mt-4">
                        <div class="row mt-3">
                          <div class="col-lg-3 col-md-4">
                            <button
                              type="submit"
                              id="btnValid2"
                              class="buttonNormal btn btn-secondary"
                            >
                              Enregistrer les modifications
                            </button>
                          </div>
                          <div
                            class="col-lg-2 col-md-2 d-flex justify-content-center d-flex align-items-center"
                          >
                            <a
                              type="submit"
                              id=""
                              class="voirePlus btn btn-link"
                              >Annuler</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border border-0">
                    <div class="card-body">
                      <div class="container mt-4">
                        <h5 class="card-title-tree mt-3">
                          Fournisseur d'énergie
                        </h5>

                        <div class="row">
                          <!-- <form (ngSubmit)="updateEnergyProvider()">    -->
                          <form (ngSubmit)="checkBeforeUpdate()">
                            <div class="col-lg-4 col-md-4">
                              <div class="input-group">
                                <span
                                  class="input-group-text bg-white col-lg-4"
                                  id="basic-addon3"
                                  >Nom</span
                                >
                                <input
                                  type="text"
                                  class="form-control col-md-8"
                                  id="basic-url"
                                  [(ngModel)]="energyProviderData.name"
                                  name="name"
                                />
                              </div>
                            </div>
                            <!-- tow row -->
                            <div class="row mt-3">
                              <div class="col-lg-4 col-md-4">
                                <div class="input-group">
                                  <span
                                    class="input-group-text bg-white col-lg-4"
                                    id="basic-addon3"
                                    >N° contrat</span
                                  >
                                  <input
                                    type="text"
                                    class="form-control col-md-8"
                                    id="basic-url"
                                    [(ngModel)]="
                                      energyProviderData.contractNumber
                                    "
                                    name="contractNumber"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                <div class="input-group">
                                  <span
                                    class="input-group-text bg-white col-lg-4"
                                    id="basic-addon3"
                                    >PDL</span
                                  >
                                  <input
                                    type="text"
                                    class="form-control col-md-8"
                                    id="basic-url"
                                    [(ngModel)]="energyProviderData.meterPoint"
                                    name="metroPoint"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4">
                                <div class="input-group">
                                  <span
                                    class="input-group-text bg-white col-lg-4"
                                    id="basic-addon3"
                                    >Prix max</span
                                  >
                                  <input
                                    type="text"
                                    class="form-control col-md-8"
                                    id="basic-url"
                                    [(ngModel)]="energyProviderData.price"
                                    name="price"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="container mt-4">
                              <div class="row mt-3">
                                <div class="col-lg-3 col-md-4">
                                  <button
                                    type="submit"
                                    id="btnValid22"
                                    class="buttonNormal btn btn-secondary"
                                  >
                                    Enregisrter les modifications fournisseur
                                  </button>
                                </div>
                              </div>
                            </div>
                            <!-- <button type="submit">
                            Update Energy Provider
                          </button> -->
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <!-- Paiement -->
                  <div class="card border bordre-0">
                    <div class="card-body pt-3">
                      <h5 class="card-title">Paiement</h5>
                      <div
                        *ngIf="isAlert"
                        [ngClass]="{
                          'alert alert-success text-center py-3':
                            isAlertSuccess,
                          'alert alert-danger': !isAlertSuccess
                        }"
                        role="alert"
                      >
                        {{ alertMSG }}
                        <button
                          (click)="closeAlert()"
                          type="button"
                          class="btn-close"
                          data-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                      <app-form-payment></app-form-payment>
                    </div>
                  </div>
                  <!-- end Paiement -->
                </div>
                <div
                  class="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <!-- change password -->
                  <div class="card border bordre-0">
                    <div class="card-body pt-3">
                      <h5 class="card-title">Changer mot de passe</h5>
                      <div class="container">
                        <div
                          *ngIf="isAlert"
                          [ngClass]="{
                            'alert alert-success text-center py-3':
                              isAlertSuccess,
                            'alert alert-danger': !isAlertSuccess
                          }"
                          role="alert"
                        >
                          {{ alertMSG }}
                          <button
                            (click)="closeAlert()"
                            type="button"
                            class="btn-close"
                            data-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>

                        <form (ngSubmit)="changePassword()">
                          <div class="form-group">
                            <label for="oldPassword"
                              >Ancien mot de passe:</label
                            >
                            <input
                              class="form-control p-3"
                              placeholder="ancien mot de passe*"
                              [type]="showPassword ? 'text' : 'password'"
                              id="oldPassword"
                              name="oldPassword"
                              [(ngModel)]="oldPassword"
                              required
                            />
                          </div>

                          <div class="form-group" *ngIf="showNewPassword">
                            <label for="newPassword"
                              >Nouveau mot de passe:</label
                            >
                            <input
                              class="form-control p-3"
                              placeholder="nouveau mot de passe*"
                              [type]="showPassword ? 'text' : 'password'"
                              id="newPassword"
                              name="newPassword"
                              [(ngModel)]="newPassword"
                              required
                            />
                          </div>
                          <div class="col-sm-9 form-check py-2">
                            <input
                              class="form-check-input ms-3"
                              type="checkbox"
                              [checked]="showPassword"
                              (change)="showPassword = !showPassword"
                            />
                            Afficher mot de passe
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-primary"
                              (click)="showNewPasswordInput()"
                              [disabled]="!oldPassword || !showNextButton"
                            >
                              suivant
                            </button>

                            <button
                              type="submit"
                              class="btn btn-primary"
                              *ngIf="showNewPassword"
                            >
                              Changer le mot de passe
                            </button>
                          </div>
                        </form>

                        <!-- <div class="row d-flex justify-content-center">
                          <div>
                            <label for="oldPassword"
                              >Ancien mot de passe:</label
                            >
                            <input
                              class="form-control p-3"
                              placeholder="ancien mot de passe*"
                              [type]="showPassword ? 'text' : 'password'"
                              id="oldPassword"
                              [(ngModel)]="oldPassword"
                            />
                          </div>
                          <div>
                            <label for="newPassword"
                              >Nouveau mot de passe:</label
                            >
                            <input
                              class="form-control p-3"
                              placeholder="nouveau mot de passe*"
                              [type]="showPassword ? 'text' : 'password'"
                              id="newPassword"
                              [(ngModel)]="newPassword"
                            />
                          </div>
                          <div class="col-sm-9 form-check py-2">
                            <input
                              class="form-check-input ms-3"
                              type="checkbox"
                              [checked]="showPassword"
                              (change)="showPassword = !showPassword"
                            />
                            Afficher mot de passe
                          </div>
                          <div>
                            <button
                              (click)="changePassword(oldPassword, newPassword)"
                              type="submit"
                              id="btnValid2"
                              class="buttonNormal btn btn-secondary"
                            >
                              Changer le mot de passe
                            </button>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <!-- end change password -->
                </div>
              </div>
            </div>
          </div>
          <!-- end card tab -->
        </div>
      </div>
    </form>
  </section>
</main>
