<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section profile">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body pt-3">
            <h5 class="card-title">Modification du salarié</h5>
            <a
              class="button-Back d-flex justify-content-start"
              routerLink="/pages-abonnement"
            >
              <i id="iconBack" class="ri-arrow-drop-left-line"></i>
              <p class="btn-link button-Back">Revenir aux salariés</p></a
            >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>Identifiant client</a>
              </li>
              <li class="breadcrumb-item active text-primary fw-bold">
                ABO-000001
              </li>
            </ol>
            <div class="container mt-4">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Lieu</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="231 rue Pierre et Marie"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Ville</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="Toulouse"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Code postal</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="31670"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Carte RFID</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="62E783BA"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Type</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="EFUSION"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mt-4">
                  <div class="input-group">
                    <span
                      class="input-group-text bg-white col-lg-4"
                      id="basic-addon3"
                      >Prix kW/h</span
                    >
                    <input
                      type="text"
                      class="form-control col-md-8"
                      id="basic-url"
                      placeholder="0,35 € "
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 mt-4">
                <button
                  type="submit"
                  id="btnValid2"
                  class="buttonNormal btn text-light buttonGradient"
                  routerLink="/pages-abonnement"
                >
                  Enregister les modifications
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
