<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Facture</h5>
            <div class="mt-3 ps-2">
              <h5 class="text-dark fw-bold fst-italic">
                Facture du mois en cours
              </h5>
              <h6 class="card-title-two">
                Facture disponible entre le 10 et le 12 du mois suivant
              </h6>
              <h6 class="cartFacture">
                {{ moisEnCoursFacture }} €<span class="spanTtcFacture"
                  >TTC</span
                >
              </h6>
            </div>
          </div>
          <div class="card-body">
            <div id="cardDown" class="container">
              <h5 class="card-title">Historique des factures</h5>
              <table class="table table-striped mt-5">
                <thead>
                  <tr class="">
                    <th scope="col col-lg-5">
                      <p class="tableNormal">
                        <i class="bi bi-calendar2-check-fill"></i> Mois
                      </p>
                    </th>
                    <th scope="col col-lg-5">
                      <p class="tableNormal">
                        <i class="ri-price-tag-2-line"></i> Montant € TTC
                      </p>
                    </th>
                    <th scope="col col-lg-5">
                      <p class="tableNormal">
                        <i class="bi bi-power"></i> Power
                      </p>
                    </th>

                    <th scope="col col-lg-2">
                      <p class="tableNormal">
                        <i class="bi bi-cloud-download-fill"></i> Télécharger
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngIf="datatablePdf.length > 0; else noDataMessage"
                  >
                    <tr *ngFor="let item of datatablePdf">
                      <td class="tableNormalTd">{{ item.yearAndMonth }}</td>
                      <td class="tableNormalTd">{{ item.amount }} €</td>
                      <td class="tableNormalTd">{{ item.power }} kw</td>
                      <td class="tableNormalTdConso">
                        <a
                          class="btn-link"
                          (click)="getMonthlyPdf(this.pdfAll, item.pdfLink)"
                        >
                          <p>
                            Télecharger
                            <i
                              id="iconDowanload"
                              class="bi bi-download ps-2"
                            ></i>
                          </p>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template class="historyConso" #noDataMessage>
                    <tr class="messageDisponibles">
                      <td colspan="6" class="text-center">
                        <p class="mt-4">
                          <i class="bx bxs-error ms-3"></i>
                          Il n'y a pas des factures disponibles
                        </p>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
