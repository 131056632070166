// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//import 'zone.js/plugins/zone-error';
export const environment = {
  production: false,
  congito : {
    userPoolId : 'eu-west-3_7RRotegEb',
    userPoolWebClientId : '3mlqfdnaeksbonl869r8o2ik0u'
  },
    API_URL: "https://api-qua.efusion-ve.com/"
    // API_URL: "https://217.114.203.196:8000/"
  //API_URL: 'http://localhost:8000/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
