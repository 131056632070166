<app-header
  *ngIf="
    !(
      _router.url === '/pages-register' ||
      _router.url === '/pages-login' ||
      _router.url === '/' ||
      _router.url === '/pages-address' ||
      _router.url === '/pages-validation' ||
      _router.url === '**'
    )
  "
></app-header>
<app-sidebar
  *ngIf="
    !(
      _router.url === '/pages-register' ||
      _router.url === '/pages-login' ||
      _router.url === '/' ||
      _router.url === '/pages-address' ||
      _router.url === '/pages-validation' ||
      _router.url === '**'
    )
  "
></app-sidebar>
<router-outlet></router-outlet>
<app-footer
  *ngIf="
    !(
      _router.url === '/pages-register' ||
      _router.url === '/pages-login' ||
      _router.url === '/' ||
      _router.url === '/pages-address' ||
      _router.url === '/pages-validation' ||
      _router.url === '**'
    )
  "
></app-footer>
