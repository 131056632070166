import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { contries, enterprise, User } from 'src/app/models/user';
// import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { CognitoService } from 'src/app/services/cognito.service';
import { UserAlreadyExistsError,IncorrectUsernameOrPasswordAWSError,InvalidParameterException, InvalidVerificationCodeError } from 'src/app/models/authentication-errors';
import { DataSharingService } from 'src/app/services/DataSharingService.service';



@Component({
  selector: 'app-pages-register',
  templateUrl: './pages-register.component.html',
  styleUrls: ['./pages-register.component.css']
})
export class PagesRegisterComponent implements OnInit {
  form!: FormGroup
  submitted = false;
  isConfirm: boolean = false;
  isAlert: boolean = false;
  showPassword: boolean = false;
  alertMSG: string = '';
  formSiret: string| any;
  formSiren: string| any;
  formCommercialName: string| any;
  formUsername: string | any;
  formEmail : string | any;
  formPassword : string | any;
  autoSignup : boolean = false;
  formConfirmPassword: string | any;
  formCountry : number | any;
  formVerificationCode: string | any;
  countrys = Object.values(contries);

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  constructor(private router: Router, private congitoService: CognitoService, private fb: FormBuilder, private dataSharingService: DataSharingService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      // commercialName: this.fb.control('', [Validators.required]),
      // siren: this.fb.control('', [Validators.required]),
      // siret: this.fb.control('', [Validators.required]),
      // country: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
      ]),
      confirmPassword: this.fb.control('', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
      ]),
    }, { updateOn: 'submit' })

    this.isConfirm = false;
  }

  // sinUp

  public singUpWithCognito() {
    if (this.formEmail && this.formPassword) {
      this.congitoService.signUpWithCognito(this.formEmail, this.formPassword, this.autoSignup).then( (signedUpUser) => {
          const body = JSON.stringify(
          {"email": this.formEmail,
          "userPoolId": signedUpUser.user.pool.userPoolId,
          "username": signedUpUser.userSub
          })
          this.congitoService.signUp(body).subscribe({
            next: (result) => {
              this.enterprise = result;
              if(this.autoSignup === false) {
                this.isConfirm = true;
              } else {
                this.congitoService.confirmSignUp(this.enterprise).subscribe({
                  next: (result) => {
                    this.enterprise = result;
                    this.dataSharingService.updateCurrentEnterpriseData(this.enterprise);
                    this.alertMSGf("connecté avec succès");
                    this.router.navigate(['/pages-address']);
                  },
                  error: (e) => console.error(e)
                })
              }
            },
            error: (e) => console.error(e)

          });
      }).catch((err:any) => {
         if (err.code == 'InvalidParameterException') {
           this.alertMSGf("Email ou mot de passe incorrect");
          //  this.form.reset();
          this.formConfirmPassword = '';
          this.formEmail = '';
          this.formEmail = '';
          // this.username = '';
         } else if (err.code == 'UsernameExistsException') {
           this.alertMSGf("L'utilisateur existe déjà");
            //  this.form.reset();
         }
        else {
          this.alertMSGf("Erreur lors de la connexion à votre compte. veuillez vérifier vos informations de connexions et rééssayez");
        }
      });
    }
  }

  public confirmSingUp() {

    if (this.formVerificationCode) {
      this.congitoService.cognitoConfirmSignUp(this.formEmail, this.formVerificationCode).then(() => {
        this.congitoService.confirmSignUp(this.enterprise).subscribe({
          next: (result) => {
            this.enterprise = result;
            this.dataSharingService.updateCurrentEnterpriseData(this.enterprise);
            this.alertMSGf("connecté avec succès");
            this.router.navigate(['/pages-address']);
          },
          error: (e) => console.error(e)
        })
      }).catch((err) => {
        console.error('erreur à la confirmation du code', err)
        this.alertMSGf("erreur à la confirmation du code. Veuillez réessayer.");
      });
    }
  }

  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }

}
