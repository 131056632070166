<main id="main" class="main">
    <div class="pagetitle">
       <h1>Form Editors</h1>
       <nav>
          <ol class="breadcrumb">
             <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
             <li class="breadcrumb-item">Forms</li>
             <li class="breadcrumb-item active">Editors</li>
          </ol>
       </nav>
    </div>
    <section class="section">
       <div class="row">
          <div class="col-lg-6">
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Quill Editor Default</h5>
                   <div class="quill-editor-default">
                      <p>Hello World!</p>
                      <p>This is Quill <strong>default</strong> editor</p>
                   </div>
                </div>
             </div>
             <div class="card">
                <div class="card-body">
                   <h5 class="card-title">Quill Editor Full</h5>
                   <p>Quill editor with full toolset</p>
                   <div class="quill-editor-full">
                      <p>Hello World!</p>
                      <p>This is Quill <strong>full</strong> editor</p>
                   </div>
                </div>
             </div>
          </div>
          <div class="col-lg-6">
           <div class="card">
               <div class="card-body">
                  <h5 class="card-title">Quill Editor Bubble</h5>
                  <p>Select some text to display options in poppovers</p>
                  <div class="quill-editor-bubble">
                     <p>Hello World!</p>
                     <p>This is Quill <strong>bubble</strong> editor</p>
                  </div>
               </div>
            </div>
          </div>
       </div>
    </section>
 </main>