<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section id="mainConsommation">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="exploitation-tab"
          data-bs-toggle="tab"
          data-bs-target="#exploitation"
          type="button"
          role="tab"
          aria-controls="exploitation"
          aria-selected="true"
        >
          Exploitation
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="productions-tab"
          data-bs-toggle="tab"
          data-bs-target="#productions"
          type="button"
          role="tab"
          aria-controls="productions"
          aria-selected="false"
        >
          Productions
        </button>
      </li>
    </ul>

    <!-- Exploitation -->

    <div class="tab-content pt-2" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="exploitation"
        role="tabpanel"
        aria-labelledby="exploitation-tab"
      >
        <div class="card border bordre-0">
          <div class="card-body pt-3">
            <h5 class="card-title">Exploitation</h5>
            <div class="row">
              <div class="container bg-white p-4" id="backgroundConsommation">
                <div class="row d-flex justify-content-center">
                  <div class="col-sm-12">
                    <div class="pagetitle">
                      <h1>Consommation</h1>
                      <nav>
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"></li>
                        </ol>
                      </nav>
                    </div>
                    <section class="section mt-5">
                      <div
                        id="carouselExampleDark"
                        class="carousel carousel-dark slide"
                        data-bs-ride="carousel"
                      >
                        <div class="container">
                          <div class="row d-flex justify-content-sm-center">
                            <div class="col-sm-12">
                              <div class="carousel-inner">
                                <!-- test scroll -->
                                <!-- <div
                                  class="carousel-item active text-center"
                                  data-bs-interval="10000"
                                  *ngIf="totalDataForMonths"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <h2 class="Slider-Mois d-block">
                                        {{ totalDataForMonths[0]?.monthName }}
                                      </h2>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonths[0]?.totalAmount }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonths[0]?.totalPower }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                                <ng-container
                                  *ngFor="let monthData of dataForAllMonths"
                                >
                                  <div class="carousel-item text-center">
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <h2 class="Slider-Mois d-block">
                                          {{ monthData.monthName }}
                                        </h2>
                                      </div>
                                    </div>
                                    <div class="container mt-3">
                                      <div
                                        class="row d-flex justify-content-sm-center"
                                      >
                                        <div
                                          class="col-md-4 d-flex justify-content-center"
                                        >
                                          <div class="col-md-12 text-center">
                                            <i
                                              class="bi bi-cast text-primary iconConso"
                                            ></i>
                                            <h6 class="kwhs">
                                              {{ monthData.totalCost }}
                                              <span class="kwh">kW/h</span>
                                            </h6>
                                            <p class="consoInfoElectro">
                                              Électricité consommée
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-4 centerDiv">
                                          <div class="col-md-12 text-center">
                                            <i
                                              class="bx bx-target-lock text-primary iconConsoMid"
                                            ></i>
                                            <h6 class="kwhs">
                                              {{ monthData.totalEnergy }}
                                              <span class="kwh">kW/h</span>
                                            </h6>
                                            <p class="consoInfoElectro">
                                              Électricité prévue
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="col-md-12 text-center">
                                            <i
                                              class="bi bi-file-break text-primary iconConso"
                                            ></i>
                                            <h6 class="kwhs">
                                              50 <span class="kwh">%</span>
                                            </h6>
                                            <p class="consoInfoElectro">
                                              Consommation abonnement
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                                <!-- end tset scroll -->
                                <!--    <div
                                  class="carousel-item active text-center"
                                  data-bs-interval="10000"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <h2 class="Slider-Mois d-block">Janvier</h2>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Février
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Mars
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                               <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Avril
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost
                                            }}<span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">Mai</h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Juin
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Juillet
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Août
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Septembre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Octobre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Novembre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Décembre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                              </div>
                              <button
                                class="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleDark"
                                data-bs-slide="prev"
                              >
                                <span
                                  class="carousel-control-prev-icon"
                                  aria-hidden="true"
                                  id="prevIcon"
                                ></span>
                                <span class="visually-hidden">Précédent</span>
                              </button>
                              <button
                                class="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleDark"
                                data-bs-slide="next"
                              >
                                <span
                                  class="carousel-control-next-icon"
                                  aria-hidden="true"
                                  id="nextIcon"
                                ></span>
                                <span class="visually-hidden">Suivant</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container">
                        <div class="row">
                          <div class="col-ms-12">
                            <canvas
                              style="background-color: rgb(255, 255, 255)"
                              id="myChartMois"
                            >
                            </canvas>
                          </div>
                        </div>
                      </div>

                      <div
                        class="container border-bottom border-dark mt-5 contFiltter"
                      >
                        <div class="row">
                          <div class="col-lg-12 col-md-7 ps-3">
                            <div class="d-flex justify-content-around">
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  (click)="filterByDay()"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  Jour
                                </button>
                                <button
                                  (click)="filterByMonth()"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  Mois
                                </button>
                                <button
                                  (click)="filterByYear()"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  Année
                                </button>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12"></div>
                        </div>
                      </div>
                      <div> <!--exploitation-->
                        <h5 class="card-title">Historique de consommation</h5>
                        <div class="table-responsive">
                          <table class="table table-striped mt-5">
                            <thead>
                              <tr>
                                <th scope="col ">
                                  <div class="d-flex justify-content-start">
                                    <p class="tableNormal">UID</p>
                                    <i
                                      class="bx bxs-credit-card-front iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-start">
                                    <p class="tableNormal">Borne de recharge</p>
                                    <i
                                      class="ri-charging-pile-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-start">
                                    <p class="tableNormal">
                                      Commencer à charger
                                    </p>
                                    <i
                                      class="ri-restart-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-center">
                                    <p class="tableNormal">
                                      Arrêter de charger
                                    </p>
                                    <i
                                      class="ri-stop-circle-fill iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-center">
                                    <p class="tableNormal">Prix</p>
                                    <i
                                      class="ri-price-tag-2-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-center">
                                    <p class="tableNormal">Énergie totale</p>
                                    <i
                                      class="ri-price-tag-2-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngIf="
                                  filteredData.length > 0;
                                  else noDataMessage
                                "
                              >
                                <tr *ngFor="let item of filteredData">
                                  <td class="tableNormalTd">{{ item.uid }}</td>
                                  <td class="tableNormalTdConso">
                                    {{ item.chargingPoint }}
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.startDateTime }}
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.stopDateTime }}
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.totalCost }}€
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.totalEnergy }} KW
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-template class="historyConso" #noDataMessage>
                                <tr class="messageDisponibles">
                                  <td colspan="6" class="text-center">
                                    <p class="mt-4">
                                      <i class="bx bxs-error ms-3"></i>
                                      Il n'y a pas de données disponible
                                      {{ filterType }}.
                                    </p>
                                  </td>
                                </tr>
                              </ng-template>
                            </tbody>
                            <div>
                              <button
                                (click)="goToPreviousPage()"
                                class="btn btn-primary me-md-2 mt-2"
                                type="button"
                              >
                                Page précédente
                              </button>
                              <button
                                (click)="goToNextPage()"
                                class="btn btn-primary me-md-2 mt-2"
                                type="button"
                              >
                                Page suivante
                              </button>
                            </div>
                          </table>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="productions"
        role="tabpanel"
        aria-labelledby="productions-tab"
      >
        <div class="card border bordre-0">
          <div class="card-body pt-3">
            <h5 class="card-title">Productions</h5>
            <div class="row">
              <div class="container bg-white p-4" id="backgroundConsommation">
                <div class="row d-flex justify-content-center">
                  <div class="col-sm-12">
                    <div class="pagetitle">
                      <h1>Consommation</h1>
                      <nav>
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"></li>
                        </ol>
                      </nav>
                    </div>
                    <section class="section mt-5">
                      <div
                        id="carouselExampleDark"
                        class="carousel carousel-dark slide"
                        data-bs-ride="carousel"
                      >
                        <div class="container">
                          <div class="row d-flex justify-content-sm-center">
                            <div class="col-sm-12">
                              <div class="carousel-inner">
                                <!-- test scroll -->
                                <!-- <div
                                  class="carousel-item active text-center"
                                  data-bs-interval="10000"
                                  *ngIf="totalDataForMonths"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <h2 class="Slider-Mois d-block">
                                        {{ totalDataForMonths[0]?.monthName }}
                                      </h2>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonths[0]?.totalAmount }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonths[0]?.totalPower }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                                <ng-container
                                  *ngFor="let monthData of dataForAllMonths"
                                >
                                  <div class="carousel-item text-center">
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <h2 class="Slider-Mois d-block">
                                          {{ monthData.monthName }}
                                        </h2>
                                      </div>
                                    </div>
                                    <div class="container mt-3">
                                      <div
                                        class="row d-flex justify-content-sm-center"
                                      >
                                        <div
                                          class="col-md-4 d-flex justify-content-center"
                                        >
                                          <div class="col-md-12 text-center">
                                            <i
                                              class="bi bi-cast text-primary iconConso"
                                            ></i>
                                            <h6 class="kwhs">
                                              {{ monthData.totalCost }}
                                              <span class="kwh">kW/h</span>
                                            </h6>
                                            <p class="consoInfoElectro">
                                              Électricité consommée
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-4 centerDiv">
                                          <div class="col-md-12 text-center">
                                            <i
                                              class="bx bx-target-lock text-primary iconConsoMid"
                                            ></i>
                                            <h6 class="kwhs">
                                              {{ monthData.totalEnergy }}
                                              <span class="kwh">kW/h</span>
                                            </h6>
                                            <p class="consoInfoElectro">
                                              Électricité prévue
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="col-md-12 text-center">
                                            <i
                                              class="bi bi-file-break text-primary iconConso"
                                            ></i>
                                            <h6 class="kwhs">
                                              50 <span class="kwh">%</span>
                                            </h6>
                                            <p class="consoInfoElectro">
                                              Consommation abonnement
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                                <!-- end tset scroll -->
                                <!--    <div
                                  class="carousel-item active text-center"
                                  data-bs-interval="10000"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <h2 class="Slider-Mois d-block">Janvier</h2>
                                    </div>
                                  </div>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Février
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Mars
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                               <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Avril
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost
                                            }}<span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">Mai</h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Juin
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Juillet
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Août
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Septembre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Octobre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Novembre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="carousel-item">
                                  <h2 id="sliderText" class="Slider-Mois d-block">
                                    Décembre
                                  </h2>
                                  <div class="container mt-3">
                                    <div class="row d-flex justify-content-sm-center">
                                      <div class="col-md-4 d-flex justify-content-center">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-cast text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalCost }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Électricité consommée
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4 centerDiv">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bx bx-target-lock text-primary iconConsoMid"
                                          ></i>
                                          <h6 class="kwhs">
                                            {{ totalDataForMonth?.totalEnergy }}
                                            <span class="kwh">kW/h</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation prévue
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="col-md-12 text-center">
                                          <i
                                            class="bi bi-file-break text-primary iconConso"
                                          ></i>
                                          <h6 class="kwhs">
                                            50 <span class="kwh">%</span>
                                          </h6>
                                          <p class="consoInfoElectro">
                                            Consommation abonnement
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                              </div>
                              <button
                                (click)="goToPreviousYear()"
                                class="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleDark"
                                data-bs-slide="prev"

                              >
                                <span
                                  (click)="goToPreviousYear()"
                                  class="carousel-control-prev-icon"
                                  aria-hidden="true"
                                  id="prevIcon"

                                ></span>
                                <span class="visually-hidden">Précédent</span>
                              </button>
                              <button
                                (click)="goToNextYear()"
                                class="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleDark"
                                data-bs-slide="next"

                              >
                                <span
                                  (click)="goToNextYear()"
                                  class="carousel-control-next-icon"
                                  aria-hidden="true"
                                  id="nextIcon"

                                ></span>
                                <span class="visually-hidden">Suivant</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12">
                            <canvas
                              style="background-color: rgb(255, 255, 255)"
                              id="myChartConsoElectricite"
                            >
                            </canvas>
                          </div>
                        </div>
                      </div>

                      <div
                        class="container border-bottom border-dark mt-5 contFiltter"
                      >
                        <!-- <div class="row">
                          <div class="col-lg-12 col-md-7 ps-3">
                            <div class="d-flex justify-content-around">
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  (click)="filterByDay()"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  Jour
                                </button>
                                <button
                                  (click)="filterByMonth()"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  Mois
                                </button>
                                <button
                                  (click)="filterByYear()"
                                  type="button"
                                  class="btn btn-primary"
                                >
                                  Année
                                </button>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12"></div>
                        </div>
                      </div>
                      <div><!--production-->
                        <h5 class="card-title">Historique de consommation</h5>
                        <div class="table-responsive">
                          <table class="table table-striped mt-5">
                            <thead>
                              <tr>
                                <th scope="col ">
                                  <div class="d-flex justify-content-start">
                                    <p class="tableNormal">UID</p>
                                    <i
                                      class="bx bxs-credit-card-front iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-start">
                                    <p class="tableNormal">Borne de recharge</p>
                                    <i
                                      class="ri-charging-pile-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-start">
                                    <p class="tableNormal">
                                      Commencer à charger
                                    </p>
                                    <i
                                      class="ri-restart-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-center">
                                    <p class="tableNormal">
                                      Arrêter de charger
                                    </p>
                                    <i
                                      class="ri-stop-circle-fill iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-center">
                                    <p class="tableNormal">Prix</p>
                                    <i
                                      class="ri-price-tag-2-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                                <th scope="col ">
                                  <div class="d-flex justify-content-center">
                                    <p class="tableNormal">Énergie totale</p>
                                    <i
                                      class="ri-price-tag-2-line iconConsomation"
                                    ></i>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngIf="
                                  filteredDataProduction.length > 0;
                                  else noDataMessage
                                "
                              >
                                <tr *ngFor="let item of filteredDataProduction">
                                  <td class="tableNormalTd">{{ item.uid }}</td>
                                  <td class="tableNormalTdConso">
                                    {{ item.chargingPoint }}
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.startDateTime }}
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.stopDateTime }}
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.totalCost }}€
                                  </td>
                                  <td class="tableNormalTd">
                                    {{ item.totalEnergy }} KW
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-template class="historyConso" #noDataMessage>
                                <tr class="messageDisponibles">
                                  <td colspan="6" class="text-center">
                                    <p class="mt-4">
                                      <i class="bx bxs-error ms-3"></i>
                                      Il n'y a pas de données disponible
                                      {{ filterType }}.
                                    </p>
                                  </td>
                                </tr>
                              </ng-template>
                            </tbody>
                            <div>
                              <button
                                (click)="goToPreviousPageProduction()"
                                class="btn btn-primary me-md-2 mt-2"
                                type="button"
                              >
                                Page précédente
                              </button>
                              <button
                                (click)="goToNextPageProduction()"
                                class="btn btn-primary me-md-2 mt-2"
                                type="button"
                              >
                                Page suivante
                              </button>
                            </div>
                          </table>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end Paiement -->
      </div>
    </div>

    <!-- End Exploitation -->

    <!-- end card down -->
  </section>
</main>
