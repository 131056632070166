<aside id="sidebar" class="sidebar">
  <ul class="sidebar-nav" id="sidebar-nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
        <i class="bi bi-grid-1x2-fill"></i> <span>Accueil</span>
      </a>
    </li>

    <!-- <li class="nav-item">
      <a
        class="nav-link collapsed"
        data-bs-target="#components-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="bi bi-menu-button-wide"></i><span>Components</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="components-nav"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a routerLink="/alerts">
            <i class="bi bi-circle"></i><span>Alerts</span>
          </a>
        </li>
        <li>
          <a routerLink="/accordion">
            <i class="bi bi-circle"></i><span>Accordion</span>
          </a>
        </li>
        <li>
          <a routerLink="/badges">
            <i class="bi bi-circle"></i><span>Badges</span>
          </a>
        </li>
        <li>
          <a routerLink="/breadcrumbs">
            <i class="bi bi-circle"></i><span>Breadcrumbs</span>
          </a>
        </li>
        <li>
          <a routerLink="/buttons">
            <i class="bi bi-circle"></i><span>Buttons</span>
          </a>
        </li>
        <li>
          <a routerLink="/cards">
            <i class="bi bi-circle"></i><span>Cards</span>
          </a>
        </li>
        <li>
          <a routerLink="/carousel">
            <i class="bi bi-circle"></i><span>Carousel</span>
          </a>
        </li>
        <li>
          <a routerLink="/list-group">
            <i class="bi bi-circle"></i><span>List group</span>
          </a>
        </li>
        <li>
          <a routerLink="/modal">
            <i class="bi bi-circle"></i><span>Modal</span>
          </a>
        </li>
        <li>
          <a routerLink="/tabs">
            <i class="bi bi-circle"></i><span>Tabs</span>
          </a>
        </li>
        <li>
          <a routerLink="/pagination">
            <i class="bi bi-circle"></i><span>Pagination</span>
          </a>
        </li>
        <li>
          <a routerLink="/progress">
            <i class="bi bi-circle"></i><span>Progress</span>
          </a>
        </li>
        <li>
          <a routerLink="/spinners">
            <i class="bi bi-circle"></i><span>Spinners</span>
          </a>
        </li>
        <li>
          <a routerLink="/tooltips">
            <i class="bi bi-circle"></i><span>Tooltips</span>
          </a>
        </li>
      </ul>
    </li> -->
    <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/pages-consommation">
        <i class="bi bi-graph-up"></i><span>Ma consommation</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/pages-abonnement">
        <i class="bi bi-credit-card-2-back-fill"></i
        ><span>Mes utilisateurs</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/pages-facture">
        <i class="bi bi-file-bar-graph-fill"></i><span>Mes factures</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        data-bs-target="#automobile"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="bi bi-cast"></i><span>Mon parc automobile</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="automobile"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a class="nav-link" routerLink="/pages-parc">
            <i class="bi bi-circle"></i><span>Généralité</span>
          </a>
        </li>
        <li>
          <a class="nav-link" routerLink="/pages-allBornes">
            <i class="bi bi-circle"></i><span>Mes bornes</span>
          </a>
        </li>
        <li>
          <a class="nav-link" routerLink="/pages-car">
            <i class="bi bi-circle"></i><span>Mes voitures</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- <li class="nav-item">
      <a
        class="nav-link collapsed"
        data-bs-target="#forms-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="bi bi-journal-text"></i><span>Forms</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="forms-nav"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a routerLink="/form-elements">
            <i class="bi bi-circle"></i><span>Form Elements</span>
          </a>
        </li>
        <li>
          <a routerLink="/form-layouts">
            <i class="bi bi-circle"></i><span>Form Layouts</span>
          </a>
        </li>
        <li>
          <a routerLink="/form-editors">
            <i class="bi bi-circle"></i><span>Form Editors</span>
          </a>
        </li>
      </ul>
    </li> -->
    <!-- <li class="nav-item">
         <a class="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#"> <i class="bi bi-layout-text-window-reverse"></i><span>Tables</span><i class="bi bi-chevron-down ms-auto"></i> </a>
         <ul id="tables-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li> <a routerLink="/tables-general"> <i class="bi bi-circle"></i><span>General Tables</span> </a></li>
            <li> <a routerLink="/tables-data"> <i class="bi bi-circle"></i><span>Data Tables</span> </a></li>
         </ul>
      </li> -->

    <!-- <li class="nav-item">
      <a
        class="nav-link collapsed"
        data-bs-target="#charts-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="bi bi-bar-chart"></i><span>Charts</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="charts-nav"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a routerLink="/charts-chartjs">
            <i class="bi bi-circle"></i><span>Chart.js</span>
          </a>
        </li>
        <li>
          <a routerLink="/charts-apexcharts">
            <i class="bi bi-circle"></i><span>ApexCharts</span>
          </a>
        </li>
      </ul>
    </li> -->

    <!-- <li class="nav-item">
      <a
        class="nav-link collapsed"
        data-bs-target="#icons-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="bi bi-gem"></i><span>Icons</span
        ><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul
        id="icons-nav"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        <li>
          <a routerLink="/icons-bootstrap">
            <i class="bi bi-circle"></i><span>Bootstrap Icons</span>
          </a>
        </li>
        <li>
          <a routerLink="/icons-remix">
            <i class="bi bi-circle"></i><span>Remix Icons</span>
          </a>
        </li>
        <li>
          <a routerLink="/icons-boxicons">
            <i class="bi bi-circle"></i><span>Boxicons</span>
          </a>
        </li>
      </ul>
    </li> -->
    <!-- <li class="nav-heading">Pages</li> -->
    <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/user-profile">
        <i class="bi bi-person"></i> <span>Mon compte</span>
      </a>
    </li>

    <!-- <li class="nav-item">
      <a
        class="nav-link collapsed"
        (click)="singOutWithCognito()"
        role="button"
      >
        <i class="bi bi-box-arrow-right"></i> <span>Logout</span>
      </a>
    </li> -->

    <!-- <li class="nav-item"> <a class="nav-link collapsed" routerLink="/pages-faq"> <i class="bi bi-question-circle"></i> <span>F.A.Q</span> </a></li> -->
    <!-- <li class="nav-item"> <a class="nav-link collapsed" routerLink="/pages-contact"> <i class="bi bi-envelope"></i> <span>Contact</span> </a></li> -->
    <!-- <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/pages-register">
        <i class="bi bi-card-list"></i> <span>Register</span>
      </a>
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/pages-login">
        <i class="bi bi-box-arrow-in-right"></i> <span>Login</span>
      </a>
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link collapsed" routerLink="/pages-error404">
        <i class="bi bi-dash-circle"></i> <span>Error 404</span>
      </a>
    </li> -->
    <!-- <li class="nav-item"> <a class="nav-link collapsed" routerLink="/pages-blank"> <i class="bi bi-file-earmark"></i> <span>Blank</span> </a></li> -->
  </ul>
</aside>
