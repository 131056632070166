import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { contries, enterprise, User } from 'src/app/models/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { DataSharingService } from 'src/app/services/DataSharingService.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-page-add-abonnements',
  templateUrl: './page-add-abonnements.component.html',
  styleUrls: ['./page-add-abonnements.component.css']
})
export class PageAddAbonnementsComponent implements OnInit {

  isAlert: boolean = false;
  alertMSG: string = '';
  isAlertSuccess: boolean = false;
  autoSignup : boolean = false;

  userOriginal: User = {
    "id": 0,
    firstName: "",
    lastName: "",
    username: "",
    email:"",
    birthDate: new Date(),
    phoneNumber:"",
    code:"",
    userPoolId:"",
    currentChargeSessionSessionId: "",
    stripeCustomerId: "",
    hasPaymentMethod: "",
    subscriptions: [],
    addresses: [],
    vehicules: [],
    chargeSessions: [],
    enabled: false,
    clientNumber: "",
    rfididentification : null,
  };
  newUser: User = {...this.userOriginal};

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};


  addUserForm : FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required,
      Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]),
    confirmPassword: new FormControl('', [Validators.required,
      Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]),
    confirmationCode: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    telephone: new FormControl('', [Validators.required]),
    streetNumber: new FormControl(0, [Validators.required]),
    street: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
  });

  isCurrentStep: number = 1;
  constructor(private userService: UserService, private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router) { }

  ngOnInit(): void {
    this.dataSharingService.currentEnterpriseData.subscribe(data=> {
      if(data && data.username !== "") {
        this.enterprise = data;
      } else {
        this.congitoService.getCurrentUser().then(result => {
          result.subscribe( enterprise => {
            this.enterprise = enterprise;
          })
        });
      }

    })
  }

  addUser() {

  }

  gotoNextStep() {
    if(this.isCurrentStep === 1) {
      if(this.newUser && this.addUserForm.get('email')?.value && this.addUserForm.get('password')?.value) {
        this.congitoService.signUpWithCognito(this.addUserForm.get('email')?.value, this.addUserForm.get('password')?.value, this.autoSignup).then( (signedUpUser) => {
          const body = JSON.stringify(
          {"email": this.addUserForm.get('email')?.value,
          "firstName": this.addUserForm.get('firstName')?.value,
          "lastName": this.addUserForm.get('lastName')?.value,
          "userPoolId": signedUpUser.user.pool.userPoolId,
          "username": signedUpUser.userSub
          })
          this.userService.createUserForEnterprise(this.enterprise.userPoolId, this.enterprise.username, body).subscribe({
            next: (result) => {
              this.newUser = result;
              if(this.autoSignup === false) {
                this.isCurrentStep++;
              } else {
                this.userService.confirmUserForEnterprise(this.enterprise.userPoolId, this.enterprise.username, this.newUser.username).subscribe({
                  next: (result) => {
                    this.newUser = result;
                    this.isCurrentStep = 3;
                  },
                  error: (e) => console.error(e)
                })
              }

            },
            error: (e) => console.error(e)
          });
      }).catch((err:any) => {
         if (err.code == 'InvalidParameterException') {
           this.alertMSGf("Email ou mot de passe incorrect");
           this.isAlertSuccess = false;

          //  this.form.reset();
          // this.username = '';
         } else if (err.code == 'UsernameExistsException') {
           this.alertMSGf("L'utilisateur existe déjà");
           this.isAlertSuccess = false;

            //  this.form.reset();
         }
        else {
          this.alertMSGf("Erreur lors de la connexion à votre compte. veuillez vérifier vos informations de connexions et rééssayez");
          this.isAlertSuccess = false;

        }
      });
      }

    } else if(this.isCurrentStep === 2) {
      if(this.newUser && this.addUserForm.get('confirmationCode')?.value){

        this.congitoService.cognitoConfirmSignUp(this.newUser.email, this.addUserForm.get('confirmationCode')?.value).then(() => {
          this.userService.confirmUserForEnterprise(this.enterprise.userPoolId, this.enterprise.username, this.newUser.username).subscribe({
            next: (result) => {
              this.newUser = result;
              this.isCurrentStep++;
            },
            error: (e) => console.error(e)
          })
        }).catch((err) => {
          console.error('erreur à la confirmation du code', err)
          this.alertMSGf("erreur à la confirmation du code. Veuillez réessayer.");
          this.isAlertSuccess = false;

        });
      }


    } else if(this.isCurrentStep === 3) {
      if(this.newUser) {
        this.newUser.phoneNumber = this.addUserForm.get('telephone')?.value;
        const newAddress = {
          "streetNumber": this.addUserForm.get('streetNumber')?.value,
          "street": this.addUserForm.get('street')?.value,
          "zipCode": this.addUserForm.get('postalCode')?.value,
          "city": this.addUserForm.get('city')?.value,
          "country": "FRANCE",
          "userId": this.newUser.id,
          "type": "CONTACT",
        }
        this.newUser.addresses.push(newAddress);
        this.userService.updateUserForEnterprise(this.enterprise.id, this.newUser.id, this.newUser).subscribe(
          {
            next: (result) => {
              this.newUser = result;
              this.alertMSGf('Utilisateur ajouté avec succès.');
              this.isAlertSuccess = true;
              setTimeout(() => {
                this.router.navigate(['/pages-abonnement']);
              }, 3000);
            },
            error: (e) => {
              console.error(e);
              this.alertMSGf("Une erreur s'est produite lors de l'ajout de l'utilisateur.");
              this.isAlertSuccess = false;

            }

          })
      }

    }
  }

  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }
}
