<main>
    <!-- End Backgrounds -->

    <div class="container container-font-validation">
        <div class="row d-flex justify-content-center" id="onetwotree">
            <div class="col-sm-8 rounded-4 mt-5 py-5">
                <div class="d-flex justify-content-center">
                    <img class="divimg border border-0 rounded p-2" src="../../../../../assets/img/2.png" id="logoInscreption" alt="" />
                </div>
                <div class="container d-flex justify-content-center mb-3" id="div-container">
                    <div class="d-flex flex-column mb-3">
                        <div class="p-2 d-flex justify-content-center">
                            <i class="bi bi-person-plus d-flex justify-content-center d-flex align-items-center" id="icon-person-plus"></i>
                        </div>
                        <div class="p-2 text-center">
                            <h1>Bonjour {{ enterprise?.commercialName }} !</h1>
                        </div>
                        <div class="p-2 text-center">
                            <p class="text-secondary">
                                Votre connexion est réussie. Vous allez être redirigé dans {{ counter }} secondes.
                            </p>
                        </div>
                        <div class="p-2 text-center">
                            <p class="text-secondary">
                                Si ce n'est pas le cas, <span><a class="btn-link voirePlus" routerLink="/dashboard">cliquez ici</a></span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- <div *ngIf="isConfirm">
          <div class="">
            <h2>Entrez code </h2>
          <h6 style="color: green;">verification code à {{user.email}}</h6>
          </div>
          <div class="form">
            <input type="text" class="form-control px-3 py-2 rounded-4" name="verificationCode" [(ngModel)]="user.code"  placeholder="Enterz verification code">
            <button (click)="confirmSingUp()">CONFIRM</button>

          </div>
        </div> -->
            </div>
        </div>
    </div>

    <!-- </div> -->
</main>
